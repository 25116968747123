import { Builder } from '@builder.io/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectCurrency from "../../../../../components/general/selectCurrency";
import ReactSelect from 'react-select';
import ConvertUSD from "../../../../../utils/convertUSD";
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import { selectProducts } from "../../../../../store/products";
import getSymbolFromCurrency from 'currency-symbol-map';
import { selectSelectedCurrency } from "../../../../../store/user";
import useCart from '../../../../../hooks/cart/useCart';
import { openSheet } from '../../../../../store/sheetToggler';
const styles = {
  control: base => ({
    ...base,
    height: '48px',
    border: 'none',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)',
  }),
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }),
  shadow: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
  },
};

const CrisisWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    mode = 'one-off',
    floating = false,
    lang = 'en',
    showSuggestedPrices = true,
    ramadanMobileWidget = false,
    defaultName = 'Sadaqah Jariyah',
    defaultCreator = 'MKD-GEN-MSD-2023-01-121',
    addToCart = true,
  } = props;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productAmount, setProductAmount] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState();
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [addProduct] = useHandleAddProduct();
  const [selectedProduct, setSelectedProduct] = useState({
    creator: defaultCreator,
    label: defaultName,
    value: 106,
  });
  const [currentProduct, setCurrentProduct] = useState({
    creator: defaultCreator,
    label: defaultName,
    value: '',
  });
  const products = useSelector(state => selectProducts(state));
  const _lang = lang.toLowerCase();
  /* -------------------------------------------------------------------------- */
  const {
    handleAddToGiving: handleAddToGivingCart,
  } = useCart();
  const dispatch = useDispatch();

  /* ------------------------------ Start methods ----------------------------- */
  const handleConvert = (currency, value) => {
    return Math.round(ConvertUSD(currency, value, false));
  };
  const handleSelectedProduct = product => {
    allProducts.map(item => item.creator === product.creator && setSelectedProduct(item));
    setCurrentProduct(product);
  };
  const handleSelectedItem = (currency, index) => {
    let tempSelectedPrice = {};
    tempSelectedPrice.index = index;
    tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
    setSelectedPrice(tempSelectedPrice);
  };
  const handleProductAmount = (currency, value) => {
    setProductAmount(handleConvert(currency, value));
  };

  const suggestedPrices = [
    {
      aud: 100,
      usd: 70,
      cad: 90,
      sgd: 90,
      gbp: 60,
      eur: 60,
      myr: 300,
      aed: 240,
      idr: 1002020,
    },
    {
      aud: 250,
      usd: 160,
      cad: 220,
      sgd: 220,
      gbp: 130,
      eur: 150,
      myr: 760,
      aed: 580,
      idr: 2505050,
    },
    {
      aud: 500,
      usd: 320,
      cad: 430,
      sgd: 440,
      gbp: 260,
      eur: 300,
      myr: 1510,
      aed: 1160,
      idr: 5010090,
    },
  ];
  const [selectedPrice, setSelectedPrice] = useState({
    value: suggestedPrices[1][currentCurrency ? currentCurrency.toLowerCase() : 'aud'],
    index: 1,
  });
  const suggestedRecurringModes = [
    { value: 'one-off', label: _lang === 'fr' ? `Don unique` : _lang === 'ar' ? `فردي` : 'Single' },
    { value: 'week', label: _lang === 'fr' ? `Don hebdomadaire` : _lang === 'ar' ? `أسبوعي` : 'Weekly' },
    { value: 'month', label: _lang === 'fr' ? `Don mensuel` : _lang === 'ar' ? `شهري` : 'Monthly' },
  ];
  // Function to get the recurring mode based on the mode value
  const getRecurringMode = (mode) => {
    return suggestedRecurringModes.find((recurringMode) => recurringMode.value === mode) || suggestedRecurringModes[0];
  };

  const [selectedRecurring, setSelectedRecurring] = useState(getRecurringMode(mode));
  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;
    tempProduct = Object.assign({}, tempProduct, {
      quantity: customAmount,
      recurring: selectedRecurring.value,
    });

    if (addToCart) {
      handleAddToGivingCart(tempProduct, 'custom');
      dispatch(openSheet());
    } else {
      addProduct({ product: tempProduct, currency: currentCurrency });
    }
  };

  const ShowCurrencies = (currency, _price, showAbbr = true) => {
    let tempCurrency = currency && currency.toUpperCase();
    let price = Number(_price);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    if (price && (Number(price) % 1 !== 0)) {
      price = Number(price).toLocaleString('en', options);
    }
    let _final = ''
    price = price ? price.toLocaleString() : 0;
    switch (tempCurrency) {
      case 'GBP':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`
        break;
      case 'EUR':
        _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`
        break;
      case 'MYR':
        _final = `${price}`
        break;
      case 'IDR':
        _final = `Rp${price}`
        break;
      case 'AED':
        _final = `Dh${price}`
        break;
      default:
        _final = `$${price}`
        break;
    }
    return _final
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (selectedCurrencyStore) {
      setCurrentCurrency(selectedCurrencyStore);
      selectedPrice ? handleSelectedItem(selectedCurrencyStore, selectedPrice.index) : handleProductAmount(selectedCurrencyStore, productAmount);
    } else {
      setCurrentCurrency('AUD');
      handleSelectedItem('AUD', selectedPrice.index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);
  const getAllProducts = products => {
    setIsLoaded(true);
    let tempProductsList = [];
    let crisisWidgetProducts = [];
    let tempDefaultProduct;
    let tempAlternativeProduct;
    if (_lang === 'ar') {
      products.forEach((item) => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            if (inner_item_product.creator === defaultCreator) {
              setSelectedProduct(inner_item_product);
              setCurrentProduct({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
            }
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
              // Morocco Appeal
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'فلسطين أكثر ما يحتاج', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
              // Afghanistan Earthquake Appeal
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'الإغاثة الطبية في فلسطين', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GD-MP001') {
              // Libya Floods
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'حيث يحتاج الأكثر', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'نداء المغرب', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'فيضانات ليبيا', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'EMR-GEN-MER-2023-01-117') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'الإغاثة الطارئة', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'مساعدة غذائية', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'مساعدة المياه', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'الأيتام حول العالم', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MSD-2023-01-121') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'الصدقة الجارية', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MED-2023-01-122') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'التعليم', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'مشروع النور', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'MKD-GEN-MPB-2023-01-125') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'امشروع البناء', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'SST-GEN-IGN-2023-01-069') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'توليد الدخل', creator: inner_item_product.creator });
            }
            // if (inner_item_product.creator === 'MKD-EM-TUR-EQ-01-2023') {
            //   // Keep Orphans Warm Where Most Needed
            //   crisisWidgetProducts.push(inner_item_product);
            //   tempProductsList.push({ value: inner_item_product.id, label: 'حالة طوارئ في تركيا', creator: inner_item_product.creator });
            // }
            if (inner_item_product.creator === 'MKD-EM-SYR-EQ-01-2023') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'حالة طوارئ في سوريا', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'RM24-ZM-013') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'زكاة المال', creator: inner_item_product.creator });
            }
            if (inner_item_product.creator === 'INT-001') {
              // Keep Orphans Warm Where Most Needed
              crisisWidgetProducts.push(inner_item_product);
              tempProductsList.push({ value: inner_item_product.id, label: 'تنقية الربا', creator: inner_item_product.creator });
            }
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
      tempProductsList = tempProductsList.filter(function ({ creator }) {
        return !this.has(creator) && this.add(creator);
      }, new Set());
    } else {
      products.forEach((item) => {
        item.sub_categories.map(inner_item => {
          inner_item.products.map(inner_item_product => {
            if (defaultCreator) {
              if (inner_item_product.creator === defaultCreator) {
                tempDefaultProduct = inner_item_product;
              } else {
                if (inner_item_product.creator === 'EMR-LEB-24-000') {
                  // Lebanon Emergency Appeal
                  crisisWidgetProducts.push(inner_item_product);
                  tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                }
                if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
                  crisisWidgetProducts.unshift(inner_item_product);
                  tempProductsList.unshift({ value: inner_item_product.id, label: _lang === 'ar' ? 'فلسطين أكثر ما يحتاج' : inner_item_product.name, creator: inner_item_product.creator });
                }
                if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') {
                  crisisWidgetProducts.push(inner_item_product);
                  tempProductsList.push({ value: inner_item_product.id, label: _lang === 'ar' ? 'الإغاثة الطبية في فلسطين' : inner_item_product.name, creator: inner_item_product.creator });
                }
                if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
                  crisisWidgetProducts.push(inner_item_product); // Water well
                  tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                }
                if (inner_item_product.creator === 'MKD-SDQ-OAW-2023-02-119') {
                  crisisWidgetProducts.push(inner_item_product); // Support an Orphan Centre
                  tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                }
                if (inner_item_product.creator === 'EM-SDQ-EQ1-02-2023') {
                  crisisWidgetProducts.push(inner_item_product); // Emergency Caravan Long Term Shelter
                  tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                }
                if (inner_item.name === 'Make A Donation') {
                  if (inner_item_product.creator !== 'MKD-EM-TUR-EQ-01-2023') {
                    crisisWidgetProducts.push(inner_item_product);
                    tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                  }
                  tempAlternativeProduct = inner_item.products[0];
                }
                if (inner_item_product.creator === 'MKD-EMR-WNT-2023-01') {
                  crisisWidgetProducts.push(inner_item_product); // Keep Orphans Warm Where Most Needed
                  tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator });
                }
              }
            }
            return inner_item_product;
          });
          return inner_item;
        });
        return item;
      });
    }
    if (tempDefaultProduct) {
      // console.log("tempDefault=",tempDefaultProduct)
      crisisWidgetProducts.push(tempDefaultProduct);
      tempProductsList.unshift({ value: tempDefaultProduct.id, label: tempDefaultProduct.name, creator: tempDefaultProduct.creator });
      setSelectedProduct(tempDefaultProduct);
      setCurrentProduct({ value: tempDefaultProduct.id, label: tempDefaultProduct.name, creator: tempDefaultProduct.creator })
    } else {
      if (tempAlternativeProduct) {
        setSelectedProduct(tempAlternativeProduct);
        setCurrentProduct({ value: tempAlternativeProduct.id, label: tempAlternativeProduct.name, creator: tempAlternativeProduct.creator })
      }
    }
    const uniqueItems = tempProductsList.filter((item, index, self) =>
      index === self.findIndex((t) => t.creator === item.creator)
    );
    setProductsList(uniqueItems);
    setAllProducts(crisisWidgetProducts);
  };
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts(products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return floating ? (
    <section className="hidden md:block sticky top-16 lg:top-24 z-30">
      <div className="max-w-[1160px] px-3 mx-auto">
        <div className={`w-full rounded-lg py-2 md:py-4 flex flex-wrap items-center`}>
          <div className="w-full grid md:grid-cols-2 xl:grid-flow-col auto-cols-max gap-2 ">
            <div className="flex gap-2 notranslate">
              <div className="min-w-[100px]" dir='ltr'>
                <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" hideFlag />
              </div>
              <ReactSelect
                options={productsList}
                className="max-h-[48px] w-full min-w-[260px]"
                defaultValue={currentProduct}
                isSearchable={false}
                styles={styles}
                placeholder="Select Product"
                onChange={e => handleSelectedProduct(e)}
              />
            </div>
            <div className="flex gap-2 notranslate">
              <ReactSelect
                options={suggestedRecurringModes}
                className="[&>*]:!cursor-pointer w-full"
                value={selectedRecurring}
                isSearchable={false}
                styles={styles}
                onChange={e => {
                  setSelectedRecurring(e);
                }}
              />
              {suggestedPrices.map((item, index) => {
                return (
                  <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                    <div
                      className={`h-12 transition-all duration-200 cursor-pointer flex justify-center items-center min-w-[64px] rounded-md py-2 md:py-4 ${_lang === 'ar' ? 'px-1 text-xs md:text-sm' : 'px-1 md:px-2 md:text-lg'
                        } ${selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud']
                          ? 'bg-[#F60362] text-white'
                          : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                        }`}
                      onClick={() => {
                        handleSelectedItem(currentCurrency, index);
                        setProductAmount('');
                      }}
                    >
                      {ShowCurrencies(currentCurrency, item[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], false)}
                    </div>
                  </div>
                );
              })}
            </div>
            <input
              value={productAmount}
              className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent min-w-[156px]"
              type="number"
              onChange={e => {
                setProductAmount(e.target.value);
                setSelectedPrice(null);
              }}
              placeholder={'Autre montant'}
            />
            <button
              disabled={!selectedProduct.id}
              className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
                }`}
              onClick={handleAddNewProduct}
            >
              Don rapide
            </button>
          </div>
        </div>
      </div>
    </section>
  ) : ramadanMobileWidget ? (
    <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
      <div
        className={`w-full rounded-lg py-2 md:py-4 flex flex-wrap items-center`}>
        <div className="w-full flex justify-center flex-wrap min-[625px]:flex-nowrap">
          <div className="basis-1/2 p-1">
            <ReactSelect
              options={productsList}
              className="max-h-[48px] w-full"
              defaultValue={currentProduct}
              isSearchable={false}
              styles={styles}
              placeholder="Select Product"
              onChange={e => handleSelectedProduct(e)}
            />
          </div>
          <div className="basis-1/2 p-1">
            <div className="flex bg-white rounded-md [&>div]:sm:min-w-[100px]" dir='ltr'>
              <SelectCurrency
                onChangeCurrency={e => setCurrentCurrency(e.value)}
                classNamePrefix="orphan_select"
                hideFlag
                className={`${lang === 'ar' ? '[&_span]:pr-1 [&_span]:justify-end [&_div]:' : ''} !text-sm`}
              />
              <input
                value={productAmount}
                className="p-1 md:p-2 rounded-lg text-sm md:text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent w-[70px]"
                type="number"
                onChange={e => {
                  setProductAmount(e.target.value);
                  setSelectedPrice(null);
                }}
                placeholder='Montante'
              />
            </div>
          </div>
          <div className="flex basis-1/2 p-1">
            <ReactSelect
              options={suggestedRecurringModes}
              className="[&>*]:!cursor-pointer w-full text-sm md:text-base"
              value={selectedRecurring}
              isSearchable={false}
              styles={styles}
              onChange={e => {
                setSelectedRecurring(e);
              }}
            />
          </div>
          <div className="basis-1/2 p-1">
            <button
              disabled={!selectedProduct.id}
              className={`hidden text-white text-sm md:text-lg transition-all duration-200 sm:flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
                }`}
              onClick={handleAddNewProduct}
            >
              Don rapide
            </button>
            <button
              disabled={!selectedProduct.id}
              className={`w-full text-white text-sm md:text-lg transition-all duration-200 flex sm:hidden items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
                }`}
              onClick={handleAddNewProduct}
            >
              FAIRE UN DON
            </button>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div
      dir={_lang === 'ar' ? 'rtl' : 'ltr'}
      className={`${_lang === 'ar' ? 'font-[AlmariMedium]' : ''
        } w-full rounded-lg py-2 md:py-4 flex flex-wrap items-center`}>
      <div className="basis-full grid grid-cols-12 gap-2">
        <div className="col-span-12 min-[500px]:col-span-6 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <ReactSelect
            options={productsList}
            className="max-h-[48px] w-full"
            defaultValue={selectedProduct}
            isSearchable={false}
            styles={styles}
            placeholder="Select Product"
            onChange={e => handleSelectedProduct(e)}
          />
        </div>
        <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <ReactSelect
            options={suggestedRecurringModes}
            className="[&>*]:!cursor-pointer w-full"
            value={selectedRecurring}
            isSearchable={false}
            styles={styles}
            onChange={e => {
              setSelectedRecurring(e);
            }}
          />
        </div>
        <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
          <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
        </div>
        <div className="col-span-12 min-[500px]:col-span-12 lg:col-span-4 md:grid grid-cols-12 gap-2 mb-2 sm:mb-0">
          {showSuggestedPrices &&
            suggestedPrices.map((item, index) => {
              return (
                <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                  <div
                    className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[80px] border border-[#F60362] ${selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud']
                      ? 'bg-[#F60362] text-white'
                      : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                      }`}
                    onClick={() => {
                      handleSelectedItem(currentCurrency, index);
                      setProductAmount('');
                    }}
                  >
                    {ShowCurrencies(currentCurrency, item[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], true)}
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className="col-span-12 min-[500px]:col-span-12 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2 mb-2 min-[400px]:mb-0"
          style={styles.shadow}
        >
          <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
          <input
            value={productAmount}
            className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
            type="number"
            onChange={e => {
              setProductAmount(e.target.value);
              setSelectedPrice(null);
            }}
            placeholder="Entrez le montant"
          />
        </div>
        <div className="col-span-12 lg:col-span-4 flex justify-center items-center-">
          <button
            disabled={!selectedProduct.id}
            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
              }`}
            onClick={handleAddNewProduct}
          >
            Don rapide
          </button>
        </div>
      </div>
    </div>
  );
};
Builder.registerComponent(CrisisWidget, {
  name: 'CrisisWidget',
  inputs: [
    {
      name: 'defaultName',
      type: 'string',
      // Optionally, you can add a default value
      defaultValue: 'Sadaqah Jariyah',
    },
    {
      name: 'defaultCreator',
      type: 'string',
      // Optionally, you can add a default value
      defaultValue: 'MKD-GEN-MSD-2023-01-121',
    },
    {
      name: 'mode',
      type: 'enum', // Use 'enum' to define a dropdown with specific options
      enum: ['one-off', 'week', 'month'], // Define the available options
      defaultValue: 'one-off', // Set a default value if desired
      enumLabels: ['Single', 'Weekly', 'Monthly'],
    },
    {
      name: 'lang',
      type: 'enum', // Use 'enum' to define a dropdown with specific options
      enum: ['EN', 'AR', 'FR'], // Define the available options
      defaultValue: 'EN', // Set a default value if desired
      enumLabels: ['EN', 'AR', 'FR'],
    },
    {
      name: 'floating',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Floating',
    },
    {
      name: 'ramadanMobileWidget',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Ramadan Mobile',
    },
    {
      name: 'showSuggestedPrices',
      type: 'boolean',
      defaultValue: true,
      friendlyName: 'Show Suggested Prices',
    },
  ],
});

export default CrisisWidget;