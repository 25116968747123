import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../button';
import { generateLinkWithLang } from '../../../utils/linkGenerator';
import useHandleAddProduct from '../../../utils/handleAddProduct';

const SadaqahJariyahTabProductBox = props => {
    /* -------------------------------- Variables ------------------------------- */
    const {
        product,
        currency,
        anyAmount,
        appealsLink
    } = props;
    const navigate = useNavigate();
    const [addProduct] = useHandleAddProduct();
    const [customStyle, setCustomStyle] = useState({});
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const dynamicStyleHandler = name => props.customStyle && customStyle[name] && customStyle[name];

    useEffect(() => {
        setCustomStyle(prevState => {
            return {
                ...prevState,
                ...props.customStyle,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`bg-white min-h-[350px] rounded-md flex flex-wrap md:flex-nowrap justify-center items-center ${dynamicStyleHandler('body')}`}>
            <div className={`basis-full md:flex-1 md:px-2 lg:px-4`}>
                <div className={`text-base my-1 sm:my-2 text-center sm:text-left lg:text-[18px] font-bold leading-9 sm:leading-10 text-[#093484] ${dynamicStyleHandler('name')}`}>
                    {product.name}
                </div>
                <div className={`mb-4 text-sm text-center sm:text-left lg:text-[14px] text-[#78716C] font-['Montserrat'] font-medium ${dynamicStyleHandler('description')}`}>
                    {product.description}
                </div>
                {anyAmount && <div className="text-center md:text-left md:text-[18px] text-[#F60362] uppercase font-brandingBold">$ N'importe quel montant</div>}
                <div className={`md:flex flex-wrap mt-2 justify-between ${dynamicStyleHandler('desktopButtonGroup')}`}>
                    <CustomButton
                        onClick={() => { addProduct({ product: product, currency: currency }) }}
                        title='Faire un don maintenant'
                        className={`${dynamicStyleHandler('button')} sm:w-full w-[150px] md:w-full xl:w-[220px] mb-5`} />
                    {appealsLink && <CustomButton
                        onClick={() => { navigate(generateLinkWithLang(appealsLink, lang)) }}
                        title='Voir tous les appels'
                        className={`!bg-[#093484] ${dynamicStyleHandler('button')} sm:w-full w-[150px] md:w-full xl:w-[220px] mb-5`} />}
                </div>
            </div>
            <div className={`basis-full md:flex-1 md:flex md:justify-left ${dynamicStyleHandler('photoFrame')}`}>
                <img className={`rounded-lg ${dynamicStyleHandler('photo')}`} alt="" src={product.image_link} />
                <div className={`md:hidden mt-5 ${dynamicStyleHandler('mobileButtonGroup')}`}>
                    <CustomButton
                        onClick={() => { addProduct({ product: product, currency: currency }) }}
                        title='Faire un don maintenant'
                        className={`${dynamicStyleHandler('button')} sm:w-full md:w-full lg:w-[220px] mb-5`} />
                    {appealsLink && <CustomButton
                        onClick={() => { navigate(generateLinkWithLang(product.navigationLink, lang)) }}
                        title='Voir tous les appels'
                        className={`!bg-[#093484] ${dynamicStyleHandler('button')} sm:w-full md:w-full lg:w-[220px] mb-5`} />}
                </div>
            </div>
        </div>
    );
};

export default SadaqahJariyahTabProductBox;