// dynamicComponentLoader.js
import {Builder, builder} from '@builder.io/react';

export const loadDynamicComponents = () => {
    const componentsContext = require.context('./componentsRepo', true, /\.(jsx|js)$/);

    componentsContext.keys().forEach((componentPath) => {
        const componentModule = componentsContext(componentPath);
        const componentName = componentPath
            .replace('./', '')
            .replace(/\.(jsx|js)$/, '')
            .replace(/\//g, '_'); // Replace slashes if necessary

        const Component = componentModule.default || componentModule[componentName];

        if (Component) {
            // Register the component with Builder.io
            //Builder.registerComponent(Component, {
            //    name: componentName,
                // Define inputs and other options as needed
            //});
        }
    });
};