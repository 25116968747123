import { Builder } from '@builder.io/react';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TabContent from "../../../../../general/laylatulQadrTabs/tab-content";
import { selectProducts } from '../../../../../../store/products';
const LaylatulQadrTabs = props => {
  /* -------------------------------- Variables ------------------------------- */
  const {
    tabs
  } = props;
  const products = useSelector(state => selectProducts(state));
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const [tabProducts, setTabProducts] = useState([]);
  /* -------------------------------------------------------------------------- */

  /* --------------------------------- Methods -------------------------------- */
  const getSelectedProducts = products => {
    // setIsLoaded(true);
    let tempSelectedProduct = [];
    // Initialize tempSelectedProducts with an empty products array for each tab
    tabs.forEach(() => {
      tempSelectedProduct.push({
        product: {}
      });
    });
    products.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          tabs.map((tabItem, tabIndex) => {
            if (tabItem.product && tabItem.product.includes(innerProduct.creator)) {
              tempSelectedProduct[tabIndex].product = innerProduct;
            }
          });
        });
      });
    });
    // console.log("prodc tabs=",tempSelectedProduct)
    setTabProducts(tempSelectedProduct);
  };
  /* -------------------------------------------------------------------------- */

  /* ---------------------------------- Hooks --------------------------------- */
  useEffect(() => {
    if (tabs.length > 0) {
      products && Object.keys(products).length && getSelectedProducts(products);
    }
  }, [tabs, products]);
  /* -------------------------------------------------------------------------- */
  return <section className='md:container mx-auto px-4 md:px-0 mt-4 md:mt-6'>
    <div className="flex flex-wrap justify-center basis-full md:container md:mx-auto  py-2 md:py-4">
      <div className="w-full flex flex-wrap justify-center md:container md:mx-auto md:hidden bg-[#01A0D8] rounded-t-[20px] px-3 pt-3">
        <div className="w-full grid grid-cols-4 gap-1">
          {tabs.map((item, index) => {
            return <div key={`tabs4_${index}`} className={`col-span-1 flex justify-center items-center`}>
              <div className={`max-[500px]:py-2 py-3 max-[500px]:px-1 max-[500px]:text-center max-[500px]:text-xs text-base md:text-lg cursor-pointer w-full flex justify-center items-center ${index === activeTabProducts ? 'rounded-full bg-white text-[#00a3da]' : '  bg-transparent text-white border-[#00a3da]'}`} onClick={() => setActiveTabProducts(index)}>
                {item.title}
              </div>
            </div>;
          })}
        </div>
      </div>
      <div className="w-full flex flex-wrap justify-center md:container md:mx-auto bg-[#01A0D8] rounded-b-[20px] md:rounded-2xl p-3 sm:p-6 md:p-8">
        <div className="w-full hidden md:grid grid-cols-4 gap-4 pb-4">
          {tabs.map((item, index) => {
            return <div key={`tabs2-${index}`} className={`py-4 text-lg md:text-[24px] cursor-pointer col-span-1 border-2 flex justify-center items-center rounded-2xl ${index === activeTabProducts ? 'rounded-xl bg-white text-[#00a3da]' : ' text-white bg-transparent'}`} onClick={() => setActiveTabProducts(index)}>
              {item.title}
            </div>;
          })}
        </div>
        <div className='flex w-full'>
          {tabs.map((item, index) => index === activeTabProducts && <React.Fragment key={`description_${index}`}>
            <TabContent product={tabProducts[index] ? tabProducts[index].product : ''} />
          </React.Fragment>)}
        </div>
      </div>
    </div>
  </section>;
};
Builder.registerComponent(LaylatulQadrTabs, {
  name: 'LaylatulQadrTabs',
  inputs: [
    {
      name: 'tabs',
      type: 'list', // Defines the input as a list (array)
      defaultValue: [], // Initializes with an empty array
      minRows: 0,       // Optional: Minimum number of items
      maxRows: 6,     // Optional: Maximum number of items
      defaultItem: { title: '', product: '' }, // Defines the structure of each item
      subFields: [
        {
          name: 'title',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Title.', // Provides guidance to users
        },
        {
          name: 'product',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Product.', // Provides guidance to users
        },
      ],
    },
  ]
});
export default LaylatulQadrTabs;