import { Builder } from '@builder.io/react';
import React, { useEffect, useState } from 'react';
const YoutubeBox = props => {
  const {
    video,
    title = '',
    description = ''
  } = props;
  /* ----------------------------- Start variables ---------------------------- */
  const [embedURL, setEmbedURL] = useState(null);
  const [error, setError] = useState('');
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getYouTubeID = url => {
    const regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };
  const createEmbedURL = videoID => {
    if (!videoID) return null;
    const baseURL = `https://www.youtube.com/embed/${videoID}`;
    const params = new URLSearchParams({
      controls: 0,
      autoplay: 1,
      loop: 1,
      mute: 1,
      playlist: videoID // Required for looping
    });
    return `${baseURL}?${params.toString()}`;
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    if (video) {
      const videoID = getYouTubeID(video.trim());
      if (videoID) {
        const url = createEmbedURL(videoID);
        setEmbedURL(url);
        setError('');
      } else {
        setEmbedURL(null);
        setError('Invalid YouTube URL. Please enter a valid link.');
      }
    }
  }, [video]);
  /* -------------------------------------------------------------------------- */

  return <div className='relative overflow-hidden flex flex-wrap'>
    <div className='order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[315px] min-h-[300px]
  md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
  right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute'>
      <div className='leading-[50px] pt-8 w-full text-[30px] sm:text-[50px] md:text-[30px] lg:text-[50px] px-8 text-white text-center font-gotcha'>{title}</div>
      <p className='text-[18px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center'>
        {description}
      </p>
    </div>
    <div className='hidden sm:block absolute w-full h-full bg-[#00000085]' />
    <div className=" basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[720px]">
      {error && <p >{error}</p>}
      <iframe className="video pointer-events-none" width={'100%'} height={'100%'} title={'youtube'} frameBorder={0} sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation" src={embedURL}></iframe>
    </div>

  </div>;
};
Builder.registerComponent(YoutubeBox, {
  name: 'YoutubeBox',
  inputs: [{
    "name": "video",
    "type": "string"
  }, {
    "name": "title",
    "type": "string"
  }, {
    "name": "description",
    "type": "string"
  }]
});
export default YoutubeBox;