import { Builder } from '@builder.io/react';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import ReportModal from "../../../../general/reportModal";
import { RamadanImpactIcon } from "../../../../general/customIcons";
import CustomButton from "../../../../general/button";
const RamadanImpact = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    count = 7147494,
    readMoreBtn = true,
    description = 'Total des bénéficiaires en Ramadan 2024',
    pdfFileName = 'MATW_Ramadan_2023_Report - FINAL.pdf'
  } = props;
  const [showModal, setShowModal] = useState(false);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const closeModal = () => {
    setShowModal(false);
  };
  /* -------------------------------------------------------------------------- */

  return <section className="px-4 xl:px-20 my-8 sm:my-16 flex flex-col items-center">
            {showModal && createPortal(<ReportModal closeModal={closeModal} />, document.body)}
            <div className={'w-full lg:w-2/3 xl:w-1/2'}>
                <h2 className="w-full text-[#253B7E] font-medium text-2xl md:text-[40px] text-center mb-4 md:mb-6">
                <span className="text-[#F60362]">Impact</span> de la charité en Ramadan
                </h2>
                <div className="w-full sm:w-auto flex justify-center items-center text-white bg-[#0D1943] rounded-xl px-4 md:px-12 py-2 md:py-4 pb-5">
                    <span className="hidden sm:block">
                        {' '}
                        <RamadanImpactIcon />
                    </span>
                    <div className="flex flex-col w-full pl-2- md:pl-6">
                        <h1 className="text-[#00A3DA] text-center text-4xl sm:text-3xl md:text-6xl mb-2">{Number(count).toLocaleString()}</h1>
                        <p className="text-[14px] text-center sm:text-[16px] font-brandingMedium sm:font-bold text-white  mb-2">
                            {description}
                        </p>
                        <div className="flex justify-center">
                            {readMoreBtn && <CustomButton onClick={() => setShowModal(true)} title="Lire la suite" className="mr-2 text-xs !bg-[#00A3DA] md:min-w-[120px]" />}
                            <CustomButton link={`../pdf/${pdfFileName}`} title="Télécharger le rapport" target="_blank" className="md:min-w-[150px] basis-8/12 !bg-[#253B7E]" />
                        </div>
                    </div>
                </div>
            </div>
        </section>;
};
Builder.registerComponent(RamadanImpact, {
  name: 'RamadanImpact',
  inputs: [
    {
    name: "count",
    defaultValue: 22950000,
    type: "number",
    friendlyName: 'Comptez',
  },
  {
    name: "description",
    type: "string",
    defaultValue: 'Total des bénéficiaires en Ramadan 2024',
    friendlyName: 'Description',
  },
  {
    name: "pdfFileName",
    type: "string",
    defaultValue: 'MATW_Ramadan_2023_Report - FINAL.pdf',
    friendlyName: 'Nom du fichier PDF',
  },
  {
    name: 'readMoreBtn',
    type: 'boolean',
    defaultValue: 'true',
    friendlyName: 'Lire la suite',
  },
]
});
export default RamadanImpact;