import foodPacksPhoto from '../../../images/landings/palestine/food-packs.png';
import hotMealsPhoto from '../../../images/landings/palestine/hot-meals.png';
import ricebags from '../../../images/eidFitr2024/ricebags.png';
import iftarMeals from '../../../images/eidFitr2024/iftar-meals.png';
import litres from '../../../images/eidFitr2024/litres.png';
import tonsOfSugar from '../../../images/eidFitr2024/tons-of-sugar.png';
import orphanIftar from '../../../images/eidFitr2024/orphan-iftar.png';
import blankets from '../../../images/eidFitr2024/blanket.png';
import water from '../../../images/eidFitr2024/water.png';
import flourBags from '../../../images/eidFitr2024/flour-bags.png';

// export const impactSliderData = [
//   {
//     img: { src: foodPacksPhoto, alt: 'Palestine Appeal provided food packs' },
//     value: '782,300',
//     title: 'Food Packs',
//   },
//   {
//     img: { src: hotMealsPhoto, alt: 'Palestine Charity, provided medical kits' },
//     value: '25,372',
//     title: 'Hot Meals',
//   },
//   {
//     img: { src: ricebags, alt: 'Donations to palestine, provided fuel' },
//     value: '1,460,000',
//     title: 'Rice Bags',
//   },
//   {
//     img: { src: iftarMeals, alt: 'Donate, Palestine. Mattresses provided' },
//     value: '1,210,357',
//     title: 'Iftar Meals',
//   },

//   {
//     img: { src: babyMilk, alt: 'Charity for Palestine provided medical relief' },
//     value: '20,300',
//     title: 'Baby Milk',
//   },
//   {
//     img: { src: litres, alt: 'Donate to Palestine with Hot meals' },
//     value: '42,500',
//     title: 'Litres of oil',
//   },
//   {
//     img: { src: tonsOfSugar, alt: 'Palestine Donation with medical beds and stretchers' },
//     value: '275,000',
//     title: 'Tons of sugar',
//   },
// ];
export const impactSliderData = [
  {
    img: { src: ricebags, alt: 'Dons pour la Palestine, carburant fourni' },
    value: '45 Tonnes',
    title: 'Riz',
},
{
    img: { src: iftarMeals, alt: 'Faites un don, Palestine. Matelas fournis' },
    value: '4,700',
    title: 'Iftar de la Mosquée',
},
{
    img: { src: orphanIftar, alt: 'Faites un don, Palestine. Matelas fournis' },
    value: '14,000',
    title: 'Iftar pour orphelins',
},
{
    img: { src: blankets, alt: 'Faites un don, Palestine. Matelas fournis' },
    value: '1,200',
    title: 'Couvertures',
},
{
    img: { src: tonsOfSugar, alt: 'Dons pour la Palestine avec des lits médicaux et des brancards' },
    value: '55 Tonnes',
    title: 'Sucre',
},
{
    img: { src: water, alt: 'Appel à la Palestine, packs alimentaires fournis' },
    value: '4,000,000 L',
    title: 'Eau',
},
{
    img: { src: flourBags, alt: 'Appel à la Palestine, packs alimentaires fournis' },
    value: '6,500',
    title: 'Sacs de farine',
},
{
    img: { src: litres, alt: 'Faites un don à la Palestine avec des repas chauds' },
    value: '8,500',
    title: 'Litres d\'huile',
},
{
    img: { src: foodPacksPhoto, alt: 'Appel à la Palestine, packs alimentaires fournis' },
    value: '26,000',
    title: 'Packs alimentaires',
},
{
    img: { src: hotMealsPhoto, alt: 'Œuvre caritative palestinienne, kits médicaux fournis' },
    value: '241,000',
    title: 'Repas d\'Iftar',
},

];
