import buildMasjidBannerMob from '../images/sadaqah/Masjid-Generic-Hero-Banner-Mobile.jpg';

export default {
  bannerImage: {
    src: '/images/banners/donate-palestine-hero-image.jpg',
    alt: 'Donate to Palestine today with MATW Project',
  },
  supportingPayment: {
    src: '/images/general/supporting-payment-sm.png',
    alt: 'Payment Image',
  },
  supportingPaymentBig: {
    src: '/images/general/supporting-payment.png',
    alt: 'Payment Image',
  },
  donateNowIcon: {
    src: '/images/general/donta-now-icon.png',
    alt: 'Arrow',
    width: 24,
  },
  northernGhaza: {
    src: '/images/home/northern-ghaza.png',
    alt: 'Northern Ghaza Image',
  },
  homeDonationPolicy: {
    src: '/images/home/home-donation-policy.jpg',
    alt: 'Northern Ghaza Image',
  },
  palestinianInPain: {
    src: '/images/home/palestinian-in-pain.png',
    alt: 'Palestinian In Pain Image',
  },
  homeImpactImage: {
    src: '/images/home/home-impact.jpg',
    alt: 'Impact Image',
  },
  boyWithBread: {
    src: '/images/home/boy-with-bread.png',
    alt: 'Boy With Bread Image',
  },
  emergencyResponseMain: {
    src: '/images/home/emergency-response-main.png',
    alt: 'Boy With Bread Image',
  },
  emergencyResponsePartners: {
    src: '/images/home/emergency-response-partners.png',
    alt: 'Boy With Bread Image',
  },
  emergencyResponseBody: {
    src: '/images/home/emergency-response-body.jpg',
    alt: 'Boy With Bread Image',
  },
  buttonArrows: {
    src: '/images/home/button-arrows.png',
    alt: 'Arrows Image',
  },
  childrenSuffering: {
    src: '/images/home/children-suffering.png',
    alt: 'Arrows Image',
  },
  emergencyResponseDonation: {
    src: '/images/home/emergency-response-donation.jpg',
    alt: 'Arrows Image',
  },
  whyDonate: {
    src: '/images/home/why-donate.png',
    alt: 'Why Donate Image',
  },
  imageRewards: {
    src: '/images/home/image-rewards.jpg',
    alt: 'Rewards Image',
  },
  imageRelief: {
    src: '/images/home/image-relief.jpg',
    alt: 'Relief Image',
  },
  imageDuas: {
    src: '/images/home/image-duas.jpg',
    alt: 'Duas Image',
  },
  ninetyMillions: {
    src: '/images/home/90-million.jpg',
    alt: '90 Million Image',
  },
  prayImage: {
    src: '/images/home/pray-image.jpg',
    alt: 'Pray Image',
  },
  matwLogoBlue: {
    src: '/images/home/MATW-LOGO-BLUE.png',
    alt: 'Pray Image',
    width: 150,
  },
  policyDonationLogo: {
    src: '/images/home/logo-donation-policy.png',
    alt: '100% Policy Image',
    width: 80,
  },
  // Ramadan 2025
  dailyRamadanBanner: {
    src: '/images/banners/daily-ramadan.jpeg',
    alt: 'Donate to Palestine today with MATW Project',
  },
  winterBanner: {
    src: 'https://cdn.builder.io/api/v1/image/assets%2F32b8c354d4bc455dbf10fed3923dfae1%2F7ad36ce2727142f98beb01ebd96f4d50?width=500&height=500',
    alt: 'Donate to Palestine today with MATW Project',
  },
  winterBannerDesktop: {
    src: '/images/landings/winter/Winter-Landing-Page-Image-4.jpg',
    alt: 'Donate to Palestine today with MATW Project',
  },
  masjidrBannerDesktop: {
    src: 'https://cdn.builder.io/api/v1/image/assets%2F32b8c354d4bc455dbf10fed3923dfae1%2F0c337c3e9a244e629f47b68c972dffe9?format=webp&width=2000',
    alt: 'Build a Mosque Sadaqah Jariyah',
  },
};