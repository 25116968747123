import { Builder } from '@builder.io/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency } from "../../../../../../store/user";
import ReactSelect from 'react-select';
import SelectCurrency from "../../../../../general/selectCurrency";
import useHandleAddProduct from "../../../../../../utils/handleAddProduct";
import CustomButton from "../../../../../general/button";
import ShowCurrencies from "../../../../../../utils/showCurrencies";
import staticImages from "../../../../../../constants/staticImages";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CartSheet } from "../../../../../general/ramadanDailyWidget/cartSheet";
import PersistentTimerMain from "../../../../../general/persistTimerMain";
import { openSheet } from "../../../../../../store/sheetToggler";
import useCart from "../../../../../../hooks/cart/useCart";
import { selectProducts } from '../../../../../../store/products';
const styles = {
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px'
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  })
};
const RamadanDailyWidget = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    defaultName = 'Save Lives in Gaza - Where Needed',
    defaultCreator = 'DD-EMR-PAL-24-11-01',
    heading = 'Faire un don d’urgence',
    suggestedPrices,
    defaultPrice = 250,
    mode = 0, // 0 for 'Single', 1 for 'Monthly' if needed
    isDailyRamadan,
    isTimer = true,
    isWaterWell = false,
  } = props;
  const dispatch = useDispatch();
  const _allProducts = useSelector(state => selectProducts(state));
  // const selectedWidgetProducts = useSelector(selectWidgetProducts);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [productsList, setProductsList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [addProduct] = useHandleAddProduct();
  const [currentCurrency, setCurrentCurrency] = useState();
  const [selectedProduct, setSelectedProduct] = useState({
    creator: defaultCreator,
    label: defaultName,
    value: 751
  });
  const [selectedPrice, setSelectedPrice] = useState(Number(defaultPrice) || 250);
  const [productAmount, setProductAmount] = useState(selectedPrice || 250);
  const [currentProduct, setCurrentProduct] = useState({
    creator: defaultCreator,
    label: defaultName,
    value: ''
  });
  // console.log("default price=",defaultPrice,selectedPrice,productAmount)
  // Added for recurring modes
  const suggestedRecurringModes = isDailyRamadan ? [{
    value: 'month',
    label: 'Tous les 30 jours'
  }, {
    value: 'allowed',
    label: 'Les 10 dernières nuits'
  }] : [{
    value: 'one-off',
    label: 'Unique'
  }, {
    value: 'month',
    label: 'Mensuel'
  }];
  const [selectedRecurring, setSelectedRecurring] = useState(mode ? suggestedRecurringModes[mode] : suggestedRecurringModes[0]);
  const {
    i18n
  } = useTranslation();
  const lang = i18n.language;
  const {
    handleAddToGiving,
  } = useCart();
  const suggestedPricesArray = Array.isArray(suggestedPrices) ? suggestedPrices : [];
  /* -------------------------------------------------------------------------- */
  /* ------------------------------ Start methods ----------------------------- */
  const handleCurrency = currency => {
    setCurrentCurrency(currency.value);
  };
  const handleProduct = value => {
    allProducts.map(item => item.id === value.value && setSelectedProduct(item));
    setCurrentProduct(value);
  };
  const handleAddNewProduct = () => {
    let tempProduct = selectedProduct;
    let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 250;
    tempProduct = {
      ...tempProduct,
      quantity: customAmount,
      ...(selectedRecurring.value === "allowed" ? {
        recurring: 'day',
        start_date: moment().format('DD-MM-YYYY'),
        end_date: moment().add(10, 'days').format('DD-MM-YYYY')
      } : {
        recurring: selectedRecurring.value
      })
    };
    if (handleAddToGiving) {
      handleAddToGiving(tempProduct, 'custom');
      dispatch(openSheet());
    } else {
      addProduct({
        product: tempProduct,
        currency: currentCurrency
      });
    }
  };

  /* ------------------------------- Start hooks ------------------------------ */
  const getAllProducts = _allProducts => {
    setIsLoaded(true);
    const newProducts = ['DD-EMR-PAL-24-11-01', 'DD-EMR-PAL-24-11-02', 'DD-EMR-PAL-24-11-03', 'DD-EMR-PAL-24-11-04', 'DD-EMR-PAL-24-11-05', 'DD-EMR-PAL-24-11-06', 'DD-EMR-PAL-24-11-07'];
    let tempMakeDonationProducts = [];
    let tempProductsList = [];
    let tempImportantProducts = [];
    let tempDefaultProduct;
    let tempAlternativeProduct;
    _allProducts.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === defaultCreator) {
            tempDefaultProduct = inner_item_product;
          }
          if (inner_item.name === 'Palestine' && newProducts.includes(inner_item_product.creator)) {
            tempMakeDonationProducts.push(inner_item_product);
            tempProductsList.push({
              value: inner_item_product.id,
              label: inner_item_product.name,
              creator: inner_item_product.creator
            });
          }
          if (inner_item.name === 'Make A Donation') {
            tempMakeDonationProducts.push(inner_item_product);
            tempProductsList.push({
              value: inner_item_product.id,
              label: inner_item_product.name,
              creator: inner_item_product.creator
            });
            tempAlternativeProduct = inner_item.products[0];
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempImportantProducts = tempImportantProducts.concat(tempProductsList);

    // Ensure no empty values
    tempImportantProducts = tempImportantProducts.filter(n => n);

    // Move product with 'MKD-MN-001' creator to 8th position
    const mdkProductIndex = tempImportantProducts.findIndex(product => product.creator === 'MKD-MN-001');
    if (mdkProductIndex !== -1) {
      const [mdkProduct] = tempImportantProducts.splice(mdkProductIndex, 1); // Remove it
      tempImportantProducts.splice(7, 0, mdkProduct); // Insert it at the 8th position (index 7)
    }
    if (isWaterWell) {
      const waterWellIndex = tempImportantProducts.findIndex(product => product.creator === 'MD-AA-2024-08');
      if (waterWellIndex !== -1) {
        const [waterWellProduct] = tempImportantProducts.splice(waterWellIndex, 1); // Remove it
        tempImportantProducts.splice(0, 0, waterWellProduct); // Insert it at the start (index 0)
      }
    }
    if (tempDefaultProduct) {
      tempImportantProducts.push(tempDefaultProduct);
      setSelectedProduct(tempDefaultProduct);
      setCurrentProduct({
        value: tempDefaultProduct.id,
        label: tempDefaultProduct.name,
        creator: tempDefaultProduct.creator
      });
    } else {
      if (tempAlternativeProduct) {
        setSelectedProduct(tempAlternativeProduct);
        setCurrentProduct({
          value: tempAlternativeProduct.id,
          label: tempAlternativeProduct.name,
          creator: tempAlternativeProduct.creator
        });
      }
    }
    tempImportantProducts = tempImportantProducts.filter(n => n);
    const uniqueProducts = tempImportantProducts.filter((product, index, self) => index === self.findIndex(p => p.creator === product.creator));
    setProductsList(uniqueProducts);
    setAllProducts(tempMakeDonationProducts);
  };
  useEffect(() => {
    _allProducts && Object.keys(_allProducts).length && !isLoaded && getAllProducts(_allProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_allProducts]);
  useEffect(() => {
    selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD');
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */

  return <>
    <div className={`w-full bg-[#eaf7ff] px-3 p-6 md:p-6 sm:px-6 flex flex-col gap-[18px] items-center`}>
      {/* Donate Now Header */}
      <div className="mb-2 md:mb-5 flex justify-between gap-3 items-center w-full">
        <div className="flex flex-col">
          <h2 className={`text-[#00a3da] text-[32px] sm:text-[38px] font-signika font-bold ${lang === 'fr' ? '!text-[28px] sm:!text-[30px]' : ''}`}>
            {heading ? heading : "Faire un don d’urgence"}
          </h2>
          {isTimer && <PersistentTimerMain styleMain="!text-[#312e2b] !font-signika" />}
        </div>
        <img {...staticImages.policyDonationLogo} className="w-[80px] sm:w-[80px]" alt="palestine" />
      </div>
      {/* Recurring Modes */}
      <div className="w-full flex gap-5 justify-center">
        {suggestedRecurringModes.map((modeOption, index) => <button key={index} className={`flex-1 text-center h-[60px] font-signika text-xl text-[#b2a79d] lg:hover:bg-[#F60362] lg:hover:text-white border border-[#cccac5] ${selectedRecurring.value === modeOption.value ? 'bg-[#F60362] !text-white' : 'bg-white text-[#F60362]'}`} onClick={() => setSelectedRecurring(modeOption)}>
          {modeOption.label}
        </button>)}
      </div>

      {/* Product Select */}
      <div className="w-full">
        <ReactSelect options={productsList}
          // className="max-h-[50px] [&>div]:!rounded-none"
          classNamePrefix="donate-select donate-select--main" value={currentProduct} defaultValue={currentProduct} isSearchable={false} styles={styles} onChange={e => handleProduct(e)} />
      </div>

      {/* Suggested Prices */}
      {suggestedPricesArray && <div className="w-full grid grid-cols-3 gap-[18px]">
        {suggestedPricesArray.map((item, index) => <div key={`prices${index}`} className="col-span-1">
          {/* {console.log("price=",selectedPrice,Number(item.price))} */}
          <div className={`font-signika text-xl text-[#b1a79d] transition-all duration-200 cursor-pointer flex justify-center items-center h-[50px] lg:hover:bg-[#F60362] lg:hover:text-white border border-[#cccac5] ${selectedPrice === Number(item.price) ? 'bg-[#F60362] !text-white' : 'bg-white text-[#333333]'}`} onClick={() => {
            setSelectedPrice(Number(item.price));
            setProductAmount('');
          }} style={{
            width: '100%'
          }}>
            {ShowCurrencies(currentCurrency, Number(item.price), false)}
            {/* <span className={`uppercase ml-1 text-xs`}>{currentCurrency}</span> */}
          </div>
        </div>)}
      </div>}

      {/* Currency Select and Amount Input */}
      <div className="w-full items-center rounded-none h-[60px] flex">
        <div className="flex-[0_0_calc(33%-10px)] !h-[60px] [&>div]:!h-full currency-select-new-container">
          <SelectCurrency onChangeCurrency={e => handleCurrency(e)} classNamePrefix="currency-select-new currency-select-new--main" className="[&>div]:!rounded-none !max-h-[60px]" />
        </div>
        <input value={productAmount} name="amount" className="h-[60px] text-[#cccac6] w-full truncate p-2 text-xl pl-4 focus:outline-none !rounded-none border border-[#cccac6] border-l-0 !font-signika" type="number" min={'0'} onChange={e => {
          const regex = /^(?:[1-9]\d*)?$/;
          if (regex.test(e.target.value)) {
            setProductAmount(e.target.value);
            setSelectedPrice(null);
          }
        }} placeholder="Other Amount" />
      </div>
      {isDailyRamadan && (
        <div className="w-full">
          <div className={`overflow-x-hidden bg-[#d1e8f7] border border-[#00a3da] text-[#00a3da] p-2 text-center`}>
            {selectedRecurring.value === "month"
              ? <div>Faire un don de <span className='inline-block text-[#F60362]'>{selectedPrice ? ShowCurrencies(currentCurrency, selectedPrice, false) : ShowCurrencies(currentCurrency, productAmount, false)}</span> chaque jour pendant 30 jours</div>
              : <div>Faire un don de <span className='inline-block text-[#F60362]'>{selectedPrice ? ShowCurrencies(currentCurrency, selectedPrice, false) : ShowCurrencies(currentCurrency, productAmount, false)}</span> chaque jour pendant les 10 dernières nuits</div>
            }
          </div>
        </div>
      )}
      {/* Donate Now Button */}
      <div className="w-full">
        <CustomButton onClick={() => {
          handleAddNewProduct();
        }} icon={<img {...staticImages.donateNowIcon} />} title={isDailyRamadan ? 'Ajouter un don' : 'Faire un don maintenant'} disabled={!isLoaded} className={`w-full hover:!bg-[#F60362] !h-[60px] !rounded-none !text-[20px] !capitalize !font-signika ${isLoaded ? 'blob red' : ''} ${lang === 'fr' ? '!text-base sm:!text-lg' : ''}`} />
      </div>

      {/* Payment Methods Image */}
      <div className="w-full mt-1 bg-[#80CEFF] h-[60px] flex justify-center items-center">
        <img {...staticImages.supportingPaymentBig} className="h-[38px] object-contain" />
      </div>
    </div>
  </>;
};

Builder.registerComponent(RamadanDailyWidget, {
  name: 'RamadanDailyWidget',
  inputs: [{
    "name": "defaultName",
    "type": "string"
  }, {
    "name": "defaultCreator",
    "type": "string"
  }, {
    "name": "defaultPrice",
    "type": "string"
  }, {
    "name": "mode",
    "type": "string"
  }, {
    "name": "heading",
    "type": "string"
  },
  {
    name: 'suggestedPrices',
    friendlyName: 'Suggested Prices',
    type: 'list', // Defines the input as a list (array)
    defaultValue: [], // Initializes with an empty array
    minRows: 0,       // Optional: Minimum number of items
    maxRows: 3,     // Optional: Maximum number of items
    defaultItem: { price: '100' }, // Defines the structure of each item
    subFields: [
      {
        name: 'price',
        type: 'string', // Each item is a string
        required: true, // Makes this field mandatory
        helpText: 'Enter the Price.', // Provides guidance to users
      },
    ],
  },
  {
    name: 'isDailyRamadan',
    type: 'boolean',
    defaultValue: true,
    friendlyName: 'Is Daily Ramadan?',
  },
  {
    name: 'isTimer',
    type: 'boolean',
    defaultValue: true,
    friendlyName: 'Is Timer?',
  },
  {
    name: 'isWaterWell',
    type: 'boolean',
    defaultValue: false,
    friendlyName: 'Is Water Well?',
  }]
});
export default RamadanDailyWidget;