import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import HomePageTabContent from './tab-content';
import CustomButton from '../../../components/general/button';

const HomePageTabs = (props) => {
  /* ----------------------------- Début des variables ---------------------------- */
  const { urgentAppeals, topAppealsProducts, palestineEmergencyProducts, zakatProducts, waterWellsProducts } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  /* -------------------------------------------------------------------------- */
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const tabButtonsProducts = [
    {
      title: 'APPELS URGENTS',
      description: '',
      products: urgentAppeals
    },
    {
      title: 'URGENCE EN PALESTINE',
      description: '',
      products: palestineEmergencyProducts
    },
    {
      title: 'principaux appels',
      products: topAppealsProducts,
      description: 'Lors du dernier Ramadan, vous avez contribué à un impact de 7 147 494. Aidez-nous à en atteindre davantage maintenant.',
    },
    {
      title: 'Zakat',
      products: zakatProducts,
      description: 'Fulfill your Islamic obligation. Calculate and give your Zakat today.',
    },
    {
      title: 'PUITS D\'EAU',
      products: waterWellsProducts,
      description: 'Donnez la Sadaqah Jariyah ultime en construisant un puits d\'eau.',
    },
  ];
  let _lang = document.getElementById('main_layout') ? document.getElementById('main_layout').lang : 'en';
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Début des méthodes ----------------------------- */

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Début des hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
      <section className="bg-[#253B7E]" dir={_lang === 'ar' ? 'rtl' : 'ltr'}>
        <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
          <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
            {tabButtonsProducts.map(({ title }, index) => (
                <CustomButton
                    key={`btn_${index}`}
                    title={title}
                    type='button'
                    roundedFull
                    onClick={() => {
                      window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 })
                      setActiveTabProducts(index);
                    }}
                    className={`${index === activeTabProducts ? '!bg-[#F60362] text-white' : '!bg-[#F8F8F8] !text-[#78716C]'} text-[14px] p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-bold`}
                />
            ))}
          </div>
          <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
            <div className="flex flex-col w-full justify-center">
              {tabButtonsProducts.map((item, index) =>
                      index === activeTabProducts && <React.Fragment key={`description_${index}`}>
                        <div className="flex w-full justify-center mb-6">
                          <p className='text-center text-white text-[18px] md:text-3xl md:w-2/3'>
                            {item.description}
                          </p>
                        </div>
                        <div ref={contentRef}>
                          {item.title === 'APPELS URGENTS'
                              ? <>{item.products}</>
                              : <HomePageTabContent
                                  showArrows={false}
                                  products={item.products}
                                  currency={selectedCurrency} />
                          }
                        </div>
                      </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </section>

  );
};
export default HomePageTabs;