import React from 'react';
import Carousel from '../../components/Carousel';

export default function AppealCarouselSection(props) {
    /* ----------------------------- Start variables ---------------------------- */
    // const [banners, setBanners] = useState([])
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    // const handleClick = () => {
    //   console.log('clicked');
    // };
    // const getBanners = async () => {
    //   try {
    //     const response = await services.getBanners();
    //     let temp = response.data.sort((a, b) => {
    //       return a.order - b.order;
    //     });
    //     // console.log('response get getBanners', response);
    //     // console.log('response get getBanners 2', temp);
    //     let temp2 = [];
    //     temp.map((item, index) => {
    //       if (item.status !== 0 && !item.is_page_banner) {
    //         temp2.push(item)
    //       }
    //       return item;
    //     })
    //     setBanners(temp2)
    //   } catch (error) {
    //     console.error('error========', error);
    //   }
    // };
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    // useEffect(() => {
    //   getBanners();
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full max-h-[500px] overflow-hidden md:pb-8- relative bg-[#253B7E]">
            {props.banners.length ? <Carousel
                    withArrow={false}
                    autoSwitch={true}
                    floatDots
                    className={'h-full [&>div]:h-full'}
                    data={props.banners}
                    renderItem={({ item, index, currentSlide }) => {
                        return (
                            <div className="md:p-2- h-full" key={`carousel${index}`}>
                                <div className={`w-full xl:px-[200px] h-auto- h-full transition-all transform md:rounded-xl- overflow-hidden`}>
                                    <img
                                        className={`w-full  h-full max-h-[500px] object-center cursor-pointer object-cover md:rounded-xl- relative`}
                                        alt={item.alt}
                                        src={`${item.image}`}
                                    />
                                </div>
                            </div>
                        );
                    }}
                    keyExtractor={item => item.image}
                />
                : <></>}
        </div>
    );
}