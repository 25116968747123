import { Builder } from '@builder.io/react';
import impactSliderRightIcon from "../../../../../images/landings/palestine/slider-icon-right.png";
import impactSliderLeftIcon from "../../../../../images/landings/palestine/slider-icon-left.png";
import matwImpactPhoto from "../../../../../images/landings/palestine/matw-impact.png";
import { Arrow } from "../../../../ramadan/arrow";
import { impactSliderData } from "../../../../../pages/eidAlFitr2024/data/impactSlider";
import { useKeenSlider } from 'keen-slider/react';
const RamadanImpactItems = props => {
  const {
    title = 'Impact total de Ramadan 2025:',
    count = 15840000
  } = props;
  const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
    slides: {
      perView: 3,
      spacing: 10
    },
    spacing: 16,
    loop: true,
    controls: true,
    slideChanged() { }
  });
  return <section className="bg-[#E1EFF2] py-6 md:py-8">
    <div className="flex items-center justify-center mb-4 md:mb-5">
      <img src={matwImpactPhoto} alt="Donate to Palestine to make your MATW impact" />
    </div>
    <div className="flex flex-col justify-center mb-8 sm:mb-4 md:mb-10 px-1 md:px-2 ">
      <h2 className="md:text-4xl font-['montserrat'] text-[#253B7E] text-center font-bold text-[25px]">
        <span className="text-[18px] font-['montserrat'] font-bold md:text-[25px] text-[#1b4194] mr-1">{title}</span>
        &nbsp; <span className="text-[25px] text-[#F60362] font-bold">{Number(count).toLocaleString()}</span>
      </h2>
    </div>
    <div className="hidden sm:grid sm:grid-cols-4 xl:grid-cols-10 gap-2 md:gap-4 justify-between items-center w-full md:w-11/12 mx-auto">
      {impactSliderData.map(({
        img,
        value,
        title
      }, index) => <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
          <img src={img.src} alt={img.alt} />
          <div className="flex flex-col items-center text-center">
            <h4 className="md:text-2xl font-montserratBold text-[#253B7E]">{value}</h4>
            <small className="text-xs md:text-base font-montserratMedium uppercase text-[#78716C]">{title}</small>
          </div>
        </div>)}
    </div>
    <div className="block sm:hidden px-4 relative">
      <div ref={sliderRefImpactSlider} className="keen-slider px-1 py-2">
        {impactSliderData.length ? impactSliderData.map(({
          img,
          value,
          title
        }, index) => <div key={index} className={`keen-slider__slide flex w-full basis-36 flex-col items-center gap-1`}>
            <img src={img.src} alt={img.alt} />
            <div className="flex flex-col items-center text-center">
              <h4 className="md:text-2xl font-montserratBold text-[#253B7E]">{value}</h4>
              <small className="text-xs md:text-base font-montserratMedium uppercase text-[#78716C]">{title}</small>
            </div>
          </div>) : <span className='text-center'>There is no Item</span>}
      </div>
      <Arrow left onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.prev()} className="bg-transparent" icon={impactSliderLeftIcon} />

      <Arrow onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.next()} className="bg-transparent" icon={impactSliderRightIcon} rightMargin />
    </div>
  </section>;
};
Builder.registerComponent(RamadanImpactItems, {
  name: 'RamadanImpactItems',
  inputs: [
    {
      name: "title",
      type: "string",
      friendlyName: 'Titre',
      defaultValue: 'Impact total de Ramadan 2025:'
    },
    {
      name: "count",
      type: "number",
      friendlyName: 'Nombre',
      defaultValue: 15840000
    }
  ]
});
export default RamadanImpactItems;