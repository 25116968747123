import React from 'react';
import staticImages from '../../../constants/staticImages';
import { ButtonDonateRounded } from './buttonDonateRounded';

export const EmergencyAppeal = ({onClick}) => {
  return (
    <div className="bg-[#253a7e] text-white py-12 px-4 sm:px-8">
      <div className="max-w-[790px] mx-auto">
        <div className="flex flex-col gap-4">
          <img {...staticImages.emergencyResponseMain} className="max-w-[780px] mx-auto w-full" />
          <img {...staticImages.emergencyResponsePartners} className="max-w-[730px] mx-auto w-full" />
        </div>

        {/* Text */}
        <div className="flex flex-col gap-6 text-center mt-8">
          <p className="font-montserrat text-lg md:text-2xl">
          <span className="font-bold">MATW's</span> specialist teams responded and continue to respond to this emergency. Our work on the ground has saved countless lives, thanks to
            your priceless donations.
          </p>
          <div className="flex flex-col">
            <p className="font-montserrat text-lg md:text-2xl font-bold">MEDICAL KITS</p>
            <p className="font-montserrat text-lg md:text-2xl font-bold">MEDICAL KITS</p>
            <p className="font-montserrat text-lg md:text-2xl font-bold">MATTRESSES</p>
            <p className="font-montserrat text-lg md:text-2xl font-bold">FOOD PACKS</p>
            <p className="font-montserrat text-lg md:text-2xl font-bold">HOT MEALS</p>
            <p className="font-montserrat text-lg md:text-2xl font-bold">BLANKETS</p>
            <p className="font-montserrat text-lg md:text-2xl font-bold">MEDICAL BEDS</p>
            <p className="font-montserrat text-lg md:text-2xl font-bold">STRETCHERS</p>
            <p className="font-montserrat text-lg md:text-2xl font-bold">FUEL</p>
          </div>
          <p className="font-montserrat text-lg md:text-2xl">
            There are just some forms of the many forms of humanitarian relief and essential supplies we've delivered to <span className="font-bold">Palestine</span>. Not only do we
            operate from the ground, we also send assistance from <span className="font-bold">Jordan and Egypt</span>, with teams travelling out regularly to assist in these lifesaving
            efforts.
          </p>
          <p className="font-montserrat text-lg md:text-2xl">
            Our team is primarily distributing with the Jordanian <span className="font-bold">Hashemite Charity Organisation</span> (JHCO). We’re also partnered with the <span className="font-bold">Egyptian Youth
            Council</span>, the Egyptian Red Crescent to ensure relief is reaching those in need in Palestine.
          </p>
          <p className="font-montserrat text-lg md:text-2xl">
            We are also working to provide <span className="font-bold">urgent essentials and family relocation</span> support to Palestinian families seeking refuge in Egypt. Through
            these projects, donors will be able to help us provide family sponsorship and settlement support to ensure a new start for families with
            all that they need.
          </p>
          <p className="font-montserrat text-lg md:text-2xl">
            We are determined as ever to never give up. Every life is <span className="font-bold">valuable</span>, and your support allows us to continue doing the work that we do.
          </p>
          <p className="font-montserrat text-lg md:text-2xl">
            Sadly, with <span className="font-bold">2.3 million</span> currently requiring humanitarian support, the need on the ground is truly <span className="font-bold">unimaginable</span>. We will continue <i>our
            efforts but we require your attention</i> urgently.
          </p>
          <p className="font-montserrat text-lg md:text-2xl">
            Without constant deliveries of relief in the form of <span className="font-bold">food, water and medication</span>, many people will continue to experience pain, hardships
            and unbearable trauma.
          </p>
          <p className="font-montserrat text-lg md:text-2xl">
            Please make a generous donation today for <span className="font-bold">Emergency Relief</span> which can save lives and <i>relieve the distress</i> of the Palestinian people.
          </p>
          <p className="font-montserrat text-lg md:text-2xl">
            By coming together, we can provide comfort and hope to those who have been affected by this tragedy.
          </p>
          <p className="font-montserrat text-lg md:text-2xl">£250 can save a family in Palestine.</p>
          <p className="font-montserrat text-lg md:text-2xl">Your <span className="font-bold">support</span> is truly priceless.</p>
        </div>

        <div className="flex justify-center items-center mt-8 md:mt-12">
          <ButtonDonateRounded title="donate now" subTitle="save lives in palestine" onClick={onClick}/>
        </div>

        <div className="mt-12">
          <img
            {...staticImages.emergencyResponseBody}
            className="border border-b-2 border-[rgba(0,0,0,0.15)] shadow-[0_4px_3px_rgba(0,0,0,0.05),_0_0_2px_rgba(0,0,0,0.15)] p-1 rounded bg-[rgba(0,0,0,0.05)] max-w-[730px] w-full mx-auto"
          />
        </div>

        <div className="mt-12">
          <p className="font-montserrat text-2xl md:text-4xl font-medium text-center">Invest With Allah For Priceless Returns</p>
          <div className="flex flex-col gap-6 text-center mt-8">
            <p className="font-montserrat text-lg md:text-2xl">
              Allah (s.w.t.) says in the Quran:
              <br />
              <span className="font-bold">“Who will lend Allah a good loan so that he can increase it many times?”</span>
            </p>
            <p className="font-montserrat-lg md:text-2xl italic">(Qur'an Chapter 2 | Verse 245)</p>
            <p className="font-montserrat text-lg md:text-2xl">
              Take that step to generously loan your Creator, the one who blessed you with all that you own, a <span className="font-bold">goodly loan</span> which will be returned back
              to you manifold, in this life and the hereafter.
            </p>
            <p className="font-montserrat text-lg md:text-2xl">
              Your generosity can make a <span className="font-bold">massive difference</span> in the lives of many Palestinian children and orphans who rely heavily on humanitarian
              support, to lift them out of the burdens and agonies of this devastating trial.
            </p>
            <p className="font-montserrat text-lg md:text-2xl">
              They're hungry, cold, thirsty, scared, displaced and wondering what hope they have for a future of safety, happiness and security, like
              every child deserves.
            </p>
          </div>
          <div className="mt-12 flex flex-col gap-12 justify-center items-center">
            <div className="max-w-[500px] mx-auto">
              <img
                {...staticImages.childrenSuffering}
                className="w-full border border-b-2 border-[rgba(0,0,0,0.15)] shadow-[0_4px_3px_rgba(0,0,0,0.05),_0_0_2px_rgba(0,0,0,0.15)] p-1 bg-[rgba(0,0,0,0.05)] rounded-full max-w-[500px] mx-auto'"
              />
            </div>
            <div className="relative">
              <img {...staticImages.buttonArrows} className="hidden md:block" />
              <div className="flex flex-col items-center justify-center md:absolute w-full md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2">
                <p className="uppercase text-lg md:text-2xl text-white text-center font-montserrat mb-5">save your children</p>
                <ButtonDonateRounded title="donate now" subTitle="save lives in palestine" onClick={onClick}/>
              </div>
            </div>
            <div>
              <img
                {...staticImages.emergencyResponseDonation}
                className="border border-b-2 border-[rgba(0,0,0,0.15)] shadow-[0_4px_3px_rgba(0,0,0,0.05),_0_0_2px_rgba(0,0,0,0.15)] p-1 rounded bg-[rgba(0,0,0,0.05)] max-w-[700px] w-full mx-auto"
              />
              <div className="mt-5">
                <p className="font-montserrat text-lg md:text-2xl text-center font-semibold">
                  We operate on a 100% Donation Policy so rest assured, 100% of your contributions will reach the Palestinian people directly. This
                  project is also Zakat Applicable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};