import { useEffect, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { LiaAngleDoubleLeftSolid, LiaAngleDoubleRightSolid, LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import SadaqahJariyahTabProductBox from './tab-product-box';
import Arrow from '../../../pages/home/components/tab-arrow';

const SadaqahJariyahTabContent = props => {
    /* -------------------------------- Variables ------------------------------- */
    const {
        products = [],
        currency,
        anyAmount,
        subTitle,
        appealsLink
    } = props;
    const [customStyle, setCustomStyle] = useState({});
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const MutationPlugin = slider => {
        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                slider.update();
            });
        });
        const config = {
            childList: true
        };
        slider.on("created", () => {
            observer.observe(slider.container, config);
        });
        slider.on("destroyed", () => {
            observer.disconnect();
        });
    };
    const [sliderRef, instanceRef] = useKeenSlider({
        mode: 'snap',
        loop: true,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
        slides: {
            origin: 'auto',
            perView: 1,
            spacing: 10
        }
        // eslint-disable-next-line no-use-before-define
    }, [MutationPlugin]);
    const dynamicStyleHandler = name => props.customStyle && customStyle[name] && customStyle[name];

    useEffect(() => {
        setCustomStyle(prevState => {
            return {
                ...prevState,
                ...props.customStyle,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* -------------------------------------------------------------------------- */
    return (
        <div className="border-[#00A3DA] border-b-[12px]">
            <div className={`flex bg-[#01A0D8] text-sm sm:text-lg font-brandingMedium py-4 text-white px-6 sm:px-[100px] xl:px-16 text-center ${dynamicStyleHandler('subTitle')}`}>
                {subTitle}
            </div>
            <div className={`bg-white min-h-[350px] rounded-md shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] flex flex-col justify-center items-center`}>
                <div ref={sliderRef} className="keen-slider w-full flex-col-reverse- md:flex-row-reverse-">
                    {products.length > 0 ? (
                        products.map((item, index) => {
                            return (
                                <div key={`active_carousel_${index}`} className="keen-slider__slide w-full flex justify-center p-4">
                                    <div className="w-full max-w-[300px]-">
                                        <SadaqahJariyahTabProductBox
                                            product={item}
                                            appealsLink={appealsLink || ''}
                                            currency={currency}
                                            customStyle={props.customStyle}
                                            anyAmount={anyAmount}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    ) : <>Aucun produit</>}
                </div>
                <div className="w-full flex bg-white py-3 px-2 justify-center md:justify-end items-center mt-2">
                    {products.length > 1
                        ? loaded && instanceRef.current && <div className="flex justify-center md:justify-end items-center relative w-full">
                            <div
                                className="bg-[#F60362] w-6 h-6 rounded-full cursor-pointer flex items-center justify-center arrow z-10"
                                onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                            >
                                <LiaAngleLeftSolid color="white" size={30} />
                            </div>
                            {instanceRef.current.track.details && [...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                                return <button key={`q${idx}`} onClick={() => {
                                    instanceRef.current?.moveToIdx(idx);
                                }} className={'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' + (currentSlide === idx ? ' bg-[#F60362] hover:opacity-60' : 'bg-[#C3C3C3] hover:bg-[#F60362]')}></button>;
                            })}
                            <div
                                className="bg-[#F60362] w-6 h-6 rounded-full cursor-pointer flex items-center justify-center arrow z-10"
                                onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                            >
                                <LiaAngleRightSolid color="white" size={30} />
                            </div>
                        </div>
                        : <></>
                    }
                </div>
                {/* <div className="w-full flex-wrap justify-center items-center relative">
                </div> */}
            </div>
        </div>
    );
};

export default SadaqahJariyahTabContent;
