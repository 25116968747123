import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
import { Helmet } from 'react-helmet-new';
import { useKeenSlider } from 'keen-slider/react';
import services from '../../services';
import ProductBox from '../../components/general/productBoxCMS';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Skeleton from 'react-loading-skeleton';
import AppealCarouselSection from './carouselSection';
import Arrow from '../home/components/tab-arrow';
import WidgetHome from '../../components/widgets/widgetHome';
import { useNavigate } from 'react-router-dom';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
import { builder, BuilderComponent, useIsPreviewing } from "@builder.io/react";
import NotFound from "../other/notFound";

const AppealLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Initialize as true to block rendering
  const [imageHasError, setImageHasError] = useState(false);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [page, setPage] = useState();
  const [pageProducts, setPageProducts] = useState([0, 0, 0, 0]);
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const isPreviewingInBuilder = useIsPreviewing();
  const [builderPageNotFound, setBuilderPageNotFound] = useState(false);
  const [cmsPageNotFound, setCmsPageNotFound] = useState(false);
  const [content, setContent] = useState(null);
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: "snap",
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: "auto",
      perView: 1,
      spacing: 8,
    },
  });
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [seo, setSeo] = useState({ title: '', description: '' });
  const [showDonateButton, setShowDonateButton] = useState(true);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getPage = async () => {
    // let page_url = window.location.pathname.toLowerCase().replace('/fr/', '/').split('/')[1].toLowerCase();
    let page_url = window.location.pathname.split('/')[1].toLowerCase();
    try {
      const response = await services.getUserPage(page_url);
      const _page = response.data.data;
      setPage(_page);
      setPageProducts(_page.products);
    } catch (error) {
      setCmsPageNotFound(true);
    }
  };
  const RawHTML = ({ children, className = '' }) => (
    <div className={className} dangerouslySetInnerHTML={{ __html: children }} />
  );
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  useEffect(() => {
    async function fetchContent() {
      try {
        const content = await builder
          .get(
            window.location.host === 'fr-test.matwproject.org' ? 'production-matwprojectfr-org' :
              window.location.host === 'localhost:3000' ? 'page' :
                'production-matwprojectfr-org', // Optional: Replace with your default identifier if needed
            { url: window.location.pathname.replace('/fr/', '/') }
          )
          .promise();

        console.log('window.location.pathname', window.location.pathname)
        setContent(content);
        setBuilderPageNotFound(!content);

        // Extract SEO fields from Builder.io content
        if (content?.data) {
          setSeo({
            title: content.data.title || 'Default Title',
            description: content.data.description || 'Default description.',
          });
          // Optionally, set the document title directly
          document.title = content.data.title || 'Default Title';
          // Set Show Donate Button
          console.log('showDonateButton=', content.data.showDonateButton)
          setShowDonateButton(content.data.showDonateButton)
        }
      } catch (error) {
        console.error("Error fetching Builder.io content:", error);
        setBuilderPageNotFound(true);
      }

      // Fetch additional page data
      await getPage();

      // After all data is fetched, set loading to false
      setIsLoading(false);
    }

    fetchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  /* -------------------------------------------------------------------------- */
  if (isLoading) {
    // Render a loading indicator while fetching data
    return (
      <div className="flex justify-center items-center min-h-screen bg-white">
        <Skeleton count={5} />
      </div>
    );
  }
  console.log('builderPageNotFound', builderPageNotFound, showDonateButton)
  if (builderPageNotFound && !isPreviewingInBuilder) {
    if (cmsPageNotFound) {
      navigate(generateLinkWithLang('/', lang));
      return null; // Optionally, render a redirecting message
    } else
      return (
        <NewHelmetLayout className='bg-white' hideFooter hideHighlights showRegularImg={false}>
          {page &&
            <Helmet>
              <title>{page.meta || 'Default Page Title'}</title>
              <link rel="canonical"
                href={`${window.location.protocol}//matwprojectfr.org${window.location.pathname}`} />
              <meta name="description" content={page.description || 'Default page description.'} />
            </Helmet>
          }
          {page && page.home_widget === 1 &&
            <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />}
          {/* /* --------------------------------- Banner ---------------------------------  */}
          <section className="flex justify-center bg-[#253B7E]">
            {page
              ? page.banners.length > 1
                ? <AppealCarouselSection banners={page.banners} />
                : page.banners.length === 0
                  ? null
                  : <img
                    className={`${imageHasError ? 'w-auto max-h-[300px]' : 'w-auto max-h-[400px] mt-5'}`}
                    src={page.banners[0] ? page.banners[0].image : '/images/general/logo-replace.png'}
                    onError={({ currentTarget }) => {
                      setImageHasError(true);
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/images/general/logo-replace.png";
                    }}
                    alt=""
                  />
              : null
            }
          </section>
          {pageProducts.length > 0 && <section className="bg-[#253B7E]">
            <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:pb-12">
              <div className='hidden sm:flex w-full flex-wrap justify-center items-start gap-2 md:gap-4'>
                {pageProducts.map((item, index) => (
                  <div key={`pageProducts${index}`}
                    className="basis-1/2 sm:basis-1/3 md:basis-1/3 lg:basis-1/4 max-w-[300px]">
                    <ProductBox product={item}
                      currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                  </div>
                ))}
              </div>
              <div className="w-full sm:hidden flex-wrap justify-center items-center relative">
                <div ref={sliderRef} className="keen-slider">
                  {pageProducts.map((item, index) => (
                    <div key={`pageProducts${index}`}
                      className="keen-slider__slide basis-full flex justify-center">
                      <div className="w-full max-w-[300px]">
                        <ProductBox product={item}
                          currency={selectedCurrencyStore ? selectedCurrencyStore : 'AUD'} />
                      </div>
                    </div>
                  ))}
                </div>
                {pageProducts[0] && pageProducts.length > 1 && <div
                  className="flex bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
                  {loaded && instanceRef.current && (
                    <div className="flex justify-center items-center relative w-full">
                      <Arrow
                        left
                        onClick={(e) =>
                          e.stopPropagation() || instanceRef.current?.prev()
                        }
                        disabled={currentSlide === 0}
                      />
                      {[...Array(pageProducts.length).keys()].map(idx => (
                        <button
                          key={`q${idx}`}
                          onClick={() => {
                            instanceRef.current?.moveToIdx(idx);
                          }}
                          className={
                            'md:w-4 md:mx-2 mx-1 md:h-4 h-3 w-3 rounded-full ' +
                            (currentSlide === idx ? ' bg-[#F60362] hover:opacity-60' : 'bg-[#C3C3C3] hover:bg-[#F60362]')
                          }
                        ></button>
                      ))}
                      <Arrow
                        onClick={(e) =>
                          e.stopPropagation() || instanceRef.current?.next()
                        }
                        disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
                      />
                    </div>
                  )}
                </div>}
              </div>
            </div>
          </section>}
          <section className='px-4 md:px-0 py-4 md:container md:mx-auto'>
            <div className='flex justify-center flex-col items-center'>
              <h1 className='sm:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-4xl px-4 w-full sm:w-10/12'>
                {isLoading ? <Skeleton/> : page ? page.title : 'Products'}
              </h1>
              <div className="flex justify-center flex-col items-center">

                {isLoading ? <Skeleton/> : page ? <RawHTML>{page.contents}</RawHTML> : ''}
              </div>
            </div>
          </section>
          {/* /* -------------------------------------------------------------------------- */}
        </NewHelmetLayout>
      )
  } else {
    return (
      <>
        {/* Render the Builder page */}
        <NewHelmetLayout hideHighlights={true} className='bg-white' hideFooter showRegularImg={false} hideDonateNowButton={!showDonateButton}>
          <Helmet>
            <title>{seo.title || 'Muslim Charity | Islamic Charity | 100% Donation Policy | MATW Project'}</title>
            <link rel="canonical"
              href={`${window.location.protocol}//matwprojectfr.org${window.location.pathname}`} />
            <meta name="description"
              content={seo.description || 'MATW Project - Muslim charity supporting Muslims around the world❤️100% Donation Policy❤️Our Islamic Charity helps YOU make a difference to Muslims in need. We deliver appeals, aid & relief globally.'} />
          </Helmet>
          <BuilderComponent
            model={
              window.location.host === 'fr-test.matwproject.org'
                ? 'production-matwprojectfr-org'
                : window.location.host === 'localhost:3000'
                  ? 'page'
                  : 'production-matwprojectfr-org'
            }
            content={content}
          />
        </NewHelmetLayout>
      </>
    );
  }
};

export default AppealLandingPage;
