import { Builder } from '@builder.io/react';
import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import SelectCurrency from "../../../../../components/general/selectCurrency";
import ReactSelect from 'react-select';
import ConvertUSD from "../../../../../utils/convertUSD";
import useHandleAddProduct from "../../../../../utils/handleAddProduct";
import { selectProducts, selectProductsList } from "../../../../../store/products";
import getSymbolFromCurrency from 'currency-symbol-map';
import { selectSelectedCurrency } from "../../../../../store/user";
import productDropDown from '../../../../general/productDropDown';
import store from "../../../../../store"; // Adjust the import path as needed
import services from '../../../../../services';
const styles = {
    control: base => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.05)'
    }),
    menuList: base => ({
        ...base,
        '::-webkit-scrollbar': {
            width: '6px',
            height: '0px'
        },
        '::-webkit-scrollbar-track': {
            background: '#f1f1f1'
        },
        '::-webkit-scrollbar-thumb': {
            background: '#888'
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: '#555'
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
};
// const allProductsList = useSelector(state => selectProductsList(state));
// const allProductsList = selectProductsList(store.getState());
// const allProductsList = selectProductsList(state => selectProductsList(state));
const allProductsList = [];
const CrisisWidgetV2 = props => {
    /* ----------------------------- Start variables ---------------------------- */
    const {
        mode = 'one-off',
        floating = false,
        lang = 'en',
        showSuggestedPrices = true,
        ramadanMobileWidget = false,
        productsList = [],
        // defaultName = 'Sadaqah Jariyah',
        // defaultCreator = 'MKD-GEN-MSD-2023-01-121'
    } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [productAmount, setProductAmount] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedProductsList, setSelectedProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [addProduct] = useHandleAddProduct();
    const [selectedProduct, setSelectedProduct] = useState({
        creator: '',
        label: '',
        value: ''
    });
    const [currentProduct, setCurrentProduct] = useState({
        creator: '',
        label: '',
        value: ''
    });
    const products = useSelector(state => selectProducts(state));
    const _lang = lang.toLowerCase();
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleConvert = (currency, value) => {
        return Math.round(ConvertUSD(currency, value, false));
    };
    const handleSelectedProduct = product => {
        allProducts.map(item => item.creator === product.creator && setSelectedProduct(item));
        setCurrentProduct(product);
    };
    const handleSelectedItem = (currency, index) => {
        let tempSelectedPrice = {};
        tempSelectedPrice.index = index;
        tempSelectedPrice.value = suggestedPrices[index][currency.toLowerCase()];
        setSelectedPrice(tempSelectedPrice);
    };
    const handleProductAmount = (currency, value) => {
        setProductAmount(handleConvert(currency, value));
    };
    const suggestedPrices = [{
        aud: 100,
        usd: 70,
        cad: 90,
        sgd: 90,
        gbp: 60,
        eur: 60,
        myr: 300,
        aed: 240,
        idr: 1002020
    }, {
        aud: 250,
        usd: 160,
        cad: 220,
        sgd: 220,
        gbp: 130,
        eur: 150,
        myr: 760,
        aed: 580,
        idr: 2505050
    }, {
        aud: 500,
        usd: 320,
        cad: 430,
        sgd: 440,
        gbp: 260,
        eur: 300,
        myr: 1510,
        aed: 1160,
        idr: 5010090
    }];
    const [selectedPrice, setSelectedPrice] = useState({
        value: suggestedPrices[1][currentCurrency ? currentCurrency.toLowerCase() : 'aud'],
        index: 1
    });
    const suggestedRecurringModes = [{
        value: 'one-off',
        label: _lang === 'fr' ? `Don unique` : _lang === 'ar' ? `فردي` : 'Single'
    }, {
        value: 'week',
        label: _lang === 'fr' ? `Don hebdomadaire` : _lang === 'ar' ? `أسبوعي` : 'Weekly'
    }, {
        value: 'month',
        label: _lang === 'fr' ? `Don mensuel` : _lang === 'ar' ? `شهري` : 'Monthly'
    }];
    // Function to get the recurring mode based on the mode value
    const getRecurringMode = mode => {
        return suggestedRecurringModes.find(recurringMode => recurringMode.value === mode) || suggestedRecurringModes[0];
    };
    const [selectedRecurring, setSelectedRecurring] = useState(getRecurringMode(mode));
    const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice.value) ? Number(selectedPrice.value) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: selectedRecurring.value
        });
        addProduct({
            product: tempProduct,
            currency: currentCurrency
        });
    };
    const ShowCurrencies = (currency, _price, showAbbr = true) => {
        let tempCurrency = currency && currency.toUpperCase();
        let price = Number(_price);
        const options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };
        if (price && Number(price) % 1 !== 0) {
            price = Number(price).toLocaleString('en', options);
        }
        let _final = '';
        price = price ? price.toLocaleString() : 0;
        switch (tempCurrency) {
            case 'GBP':
                _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
                break;
            case 'EUR':
                _final = `${getSymbolFromCurrency(tempCurrency.toLowerCase())}${price}`;
                break;
            case 'MYR':
                _final = `${price}`;
                break;
            case 'IDR':
                _final = `Rp${price}`;
                break;
            case 'AED':
                _final = `Dh${price}`;
                break;
            default:
                _final = `$${price}`;
                break;
        }
        return _final;
    };
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (selectedCurrencyStore) {
            setCurrentCurrency(selectedCurrencyStore);
            selectedPrice ? handleSelectedItem(selectedCurrencyStore, selectedPrice.index) : handleProductAmount(selectedCurrencyStore, productAmount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore]);
    const getAllProducts = products => {
        setIsLoaded(true);
        let tempAllProducts = [];
        let tempSelectedProductsList = [];
        let tempAlternativeProductsList = [];
        let tempAlternativeProduct;
        let tempCurrentProduct;
        let alternativeProducts = [
            'EMR-LEB-24-000',
            'EMR-DIS-PSE-2023-01',
            'EMR-DIS-PSE-2023-01-01',
            'MKD-WEL-SDQ-2023-01',
            'MKD-SDQ-OAW-2023-02-119',
            'EM-SDQ-EQ1-02-2023',
            'MKD-EMR-WNT-2023-01'
        ]
        products.forEach(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    tempAllProducts.push(inner_item_product);
                    // Find the matching product in productsList
                    const matchedProduct = productsList.find(
                        (product) => product.productId === inner_item_product.creator
                    );
                    if (matchedProduct) {
                        tempSelectedProductsList.push({
                            value: inner_item_product.id,
                            label: inner_item_product.name,
                            creator: inner_item_product.creator
                        });
                        // If this product is marked as default, set tempCurrentProduct
                        if (matchedProduct.defaultProduct) {
                            tempAlternativeProduct = inner_item_product;
                        }
                    }
                    if (alternativeProducts.includes(inner_item_product.creator)) {
                        tempAlternativeProductsList.push({
                            value: inner_item_product.id,
                            label: inner_item_product.name,
                            creator: inner_item_product.creator
                        });
                    }
                    return inner_item_product;
                });
                return inner_item;
            });
            return item;
        });
        let uniqueItems;
        if (tempSelectedProductsList.length) {
            uniqueItems = tempSelectedProductsList.filter((item, index, self) => index === self.findIndex(t => t.creator === item.creator));
        } else {
            uniqueItems = tempAlternativeProductsList.filter((item, index, self) => index === self.findIndex(t => t.creator === item.creator));
        }
        if (!tempCurrentProduct) {
            tempCurrentProduct = uniqueItems[0];
        }

        setSelectedProduct(tempCurrentProduct);
        setCurrentProduct({
            value: tempCurrentProduct.id,
            label: tempCurrentProduct.name,
            creator: tempCurrentProduct.creator
        });
        setSelectedProductsList(uniqueItems);
        setAllProducts(tempAllProducts);
    };
    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && productsList.length && getAllProducts(products);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, productsList]);
    /* -------------------------------------------------------------------------- */
    return floating ? (
        <section className="hidden md:block sticky top-16 lg:top-24 z-30">
            <div className="max-w-[1160px] px-3 mx-auto">
                <div
                    dir={_lang === 'ar' ? 'rtl' : 'ltr'}
                    className={`${_lang === 'ar' ? 'font-[AlmariMedium]' : ''
                        } w-full rounded-lg py-2 md:py-4 flex flex-wrap items-center`}
                >
                    <div className="w-full grid md:grid-cols-2 xl:grid-flow-col auto-cols-max gap-2 ">
                        <div className="flex gap-2 notranslate">
                            <div className="min-w-[100px]" dir='ltr'>
                                <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" hideFlag />
                            </div>
                            <ReactSelect
                                options={selectedProductsList}
                                className="max-h-[48px] w-full min-w-[260px]"
                                value={currentProduct}
                                isSearchable={false}
                                styles={styles}
                                placeholder="Select Product"
                                onChange={e => handleSelectedProduct(e)}
                            />
                        </div>

                        <div className="flex gap-2 notranslate">
                            <ReactSelect
                                options={suggestedRecurringModes}
                                className="[&>*]:!cursor-pointer w-full"
                                value={selectedRecurring}
                                isSearchable={false}
                                styles={styles}
                                onChange={e => {
                                    setSelectedRecurring(e);
                                }}
                            />
                            {suggestedPrices.map((item, index) => {
                                return (
                                    <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                                        <div
                                            className={`h-12 transition-all duration-200 cursor-pointer flex justify-center items-center min-w-[64px] rounded-md py-2 md:py-4 ${_lang === 'ar' ? 'px-1 text-xs md:text-sm' : 'px-1 md:px-2 md:text-lg'
                                                } ${selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud']
                                                    ? 'bg-[#F60362] text-white'
                                                    : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                                                }`}
                                            onClick={() => {
                                                handleSelectedItem(currentCurrency, index);
                                                setProductAmount('');
                                            }}
                                        >
                                            {ShowCurrencies(currentCurrency, item[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], false)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <input
                            value={productAmount}
                            className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent min-w-[156px]"
                            type="number"
                            onChange={e => {
                                setProductAmount(e.target.value);
                                setSelectedPrice(null);
                            }}
                            placeholder={_lang === 'ar' ? 'كمية اخرى' : _lang === 'fr' ? 'Autre montant' : 'Other amount'}
                        />
                        <button
                            disabled={!selectedProduct.id}
                            className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
                                }`}
                            onClick={handleAddNewProduct}
                        >
                            {_lang === 'fr' ? 'Don rapide' : _lang === 'ar' ? 'تبرع سريع' : 'Quick Donate'}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    ) : ramadanMobileWidget ? (
        <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
            <div
                dir={lang === 'ar' ? 'rtl' : 'ltr'}
                className={`${lang === 'ar' ? 'font-[AlmariMedium]' : ''
                    } w-full rounded-lg py-2 md:py-4 flex flex-wrap items-center`}>
                <div className="w-full flex justify-center flex-wrap min-[625px]:flex-nowrap">
                    <div className="basis-1/2 p-1">
                        <ReactSelect
                            options={selectedProductsList}
                            className="max-h-[48px] w-full"
                            value={currentProduct}
                            isSearchable={false}
                            styles={styles}
                            placeholder="Select Product"
                            onChange={e => handleSelectedProduct(e)}
                        />
                    </div>
                    <div className="basis-1/2 p-1">
                        <div className="flex bg-white rounded-md [&>div]:sm:min-w-[100px]" dir='ltr'>
                            <SelectCurrency
                                onChangeCurrency={e => setCurrentCurrency(e.value)}
                                classNamePrefix="orphan_select"
                                hideFlag
                                className={`${lang === 'ar' ? '[&_span]:pr-1 [&_span]:justify-end [&_div]:' : ''} !text-sm`}
                            />
                            <input
                                value={productAmount}
                                className="p-1 md:p-2 rounded-lg text-sm md:text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent w-[70px]"
                                type="number"
                                onChange={e => {
                                    setProductAmount(e.target.value);
                                    setSelectedPrice(null);
                                }}
                                placeholder={lang === 'ar' ? 'المبلغ' : lang === 'fr' ? 'Montante' : 'Amount'}
                            />
                        </div>
                    </div>
                    <div className="flex basis-1/2 p-1">
                        <ReactSelect
                            options={suggestedRecurringModes}
                            className="[&>*]:!cursor-pointer w-full text-sm md:text-base"
                            value={selectedRecurring}
                            isSearchable={false}
                            styles={styles}
                            onChange={e => {
                                setSelectedRecurring(e);
                            }}
                        />
                    </div>
                    <div className="basis-1/2 p-1">
                        <button
                            disabled={!selectedProduct.id}
                            className={`hidden text-white text-sm md:text-lg transition-all duration-200 sm:flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
                                }`}
                            onClick={handleAddNewProduct}
                        >
                            {lang === 'fr' ? 'Faire un don rapide' : lang === 'ar' ? 'تبرع سريع' : 'Quick Donate'}
                        </button>
                        <button
                            disabled={!selectedProduct.id}
                            className={`w-full text-white text-sm md:text-lg transition-all duration-200 flex sm:hidden items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
                                }`}
                            onClick={handleAddNewProduct}
                        >
                            {lang === 'ar' ? 'يتبرع' : lang === 'fr' ? 'FAIRE UN DON' : 'DONATE'}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    ) : (
        <div
            dir={_lang === 'ar' ? 'rtl' : 'ltr'}
            className={`${_lang === 'ar' ? 'font-[AlmariMedium]' : ''
                } w-full rounded-lg py-2 md:py-4 flex flex-wrap items-center`}>
            <div className="basis-full grid grid-cols-12 gap-2">
                <div className="col-span-12 min-[500px]:col-span-6 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                    <ReactSelect
                        options={selectedProductsList}
                        className="max-h-[48px] w-full"
                        value={currentProduct}
                        isSearchable={false}
                        styles={styles}
                        placeholder="Select Product"
                        onChange={e => handleSelectedProduct(e)}
                    />
                </div>
                <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                    <ReactSelect
                        options={suggestedRecurringModes}
                        className="[&>*]:!cursor-pointer w-full"
                        value={selectedRecurring}
                        isSearchable={false}
                        styles={styles}
                        onChange={e => {
                            setSelectedRecurring(e);
                        }}
                    />
                </div>
                <div className="col-span-12 min-[500px]:col-span-3 lg:col-span-4 lg:mb-2 flex items-center justify-center" style={styles.shadow}>
                    <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" />
                </div>
                <div className="col-span-12 min-[500px]:col-span-12 lg:col-span-4 md:grid grid-cols-12 gap-2 mb-2 sm:mb-0">
                    {showSuggestedPrices &&
                        suggestedPrices.map((item, index) => {
                            return (
                                <div key={`prices${index}`} className="col-span-12 sm:col-span-4 xl:col-span-4 mb-1 md:mb-0">
                                    <div
                                        className={`h-12 text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 py-2 md:py-4 min-w-[80px] border border-[#F60362] ${selectedPrice && selectedPrice.value === suggestedPrices[index][currentCurrency ? currentCurrency.toLowerCase() : 'aud']
                                            ? 'bg-[#F60362] text-white'
                                            : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'
                                            }`}
                                        onClick={() => {
                                            handleSelectedItem(currentCurrency, index);
                                            setProductAmount('');
                                        }}
                                    >
                                        {ShowCurrencies(currentCurrency, item[currentCurrency ? currentCurrency.toLowerCase() : 'aud'], true)}
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div
                    className="col-span-12 min-[500px]:col-span-12 lg:col-span-4 flex justify-center items-center bg-white rounded-md h-12 px-2 mb-2 min-[400px]:mb-0"
                    style={styles.shadow}
                >
                    <span className="text-sm border-r pr-2 text-[#78716C]">{currentCurrency}</span>
                    <input
                        value={productAmount}
                        className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                        type="number"
                        onChange={e => {
                            setProductAmount(e.target.value);
                            setSelectedPrice(null);
                        }}
                        placeholder="Enter amount"
                    />
                </div>
                <div className="col-span-12 lg:col-span-4 flex justify-center items-center-">
                    <button
                        disabled={!selectedProduct.id}
                        className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'
                            }`}
                        onClick={handleAddNewProduct}
                    >
                        {_lang === 'fr' ? 'Don rapide' : _lang === 'ar' ? 'تبرع سريع' : 'Quick Donate'}
                    </button>
                </div>
            </div>
        </div>
    );
};

let isLoaded = false;
let allPrds = [];
const getProductsList = async () => {
    isLoaded = true;
    try {
        const response = await services.searchProducts();
        function compare(a, b) {
            if (a.name.trim() < b.name.trim()) {
                return -1;
            }
            if (a.name.trim() > b.name.trim()) {
                return 1;
            }
            return 0;
        }
        let temp = response.data.sort(compare);
        temp = temp.filter(n => Number(n.status) === 1);
        allPrds = temp;
        console.log("all products list separate=", temp)
        return temp.map(product => product.creator)
    } catch (error) {
        console.error('error========', error);
    }
};
!isLoaded && getProductsList();
// console.log("allPrds==",allPrds)
let allPrdList = [
    {
        "id": 682,
        "name": "10% of a Prosthetic Limb",
        "creator": "EMR-PAL-MASU-201",
        "status": 1
    },
    {
        "id": 690,
        "name": "100% of a Prosthetic Limb",
        "creator": "EMR-PAL-MASU-210",
        "status": 1
    },
    {
        "id": 683,
        "name": "20% of a Prosthetic Limb",
        "creator": "EMR-PAL-MASU-202",
        "status": 1
    },
    {
        "id": 766,
        "name": "30 days iftar for 1 orphan",
        "creator": "RM25-00-008",
        "status": 1
    },
    {
        "id": 768,
        "name": "30 days iftar for 10 orphans",
        "creator": "RM25-00-010",
        "status": 1
    },
    {
        "id": 767,
        "name": "30 days iftar for 5 orphans",
        "creator": "RM25-00-009",
        "status": 1
    },
    {
        "id": 684,
        "name": "30% Of A Prosthetic Limb",
        "creator": "EMR-PAL-MASU-203",
        "status": 1
    },
    {
        "id": 615,
        "name": "40KG Rice Bag For 2 Families",
        "creator": "EMR-BAN-RBB-2024-01",
        "status": 1
    },
    {
        "id": 685,
        "name": "50% of a Prosthetic Limb",
        "creator": "EMR-PAL-MASU-205",
        "status": 1
    },
    {
        "id": 686,
        "name": "60% of a Prosthetic Limb",
        "creator": "EMR-PAL-MASU-206",
        "status": 1
    },
    {
        "id": 687,
        "name": "70% of a Prosthetic Limb",
        "creator": "EMR-PAL-MASU-207",
        "status": 1
    },
    {
        "id": 688,
        "name": "80% of a Prosthetic Limb",
        "creator": "EMR-PAL-MASU-208",
        "status": 1
    },
    {
        "id": 689,
        "name": "90% of a Prosthetic Limb",
        "creator": "EMR-PAL-MASU-209",
        "status": 1
    },
    {
        "id": 121,
        "name": "Admin Contribution",
        "creator": "INT-ADM-OVH-2023",
        "status": 1
    },
    {
        "id": 197,
        "name": "Africa Basic Water Well",
        "creator": "WWC-CON-ABW-23-01-028",
        "status": 1
    },
    {
        "id": 317,
        "name": "Africa Large Masjid",
        "creator": "MSJ-WA-LRG-2023-02",
        "status": 1
    },
    {
        "id": 316,
        "name": "Africa Small Masjid",
        "creator": "MSJ-WA-SMJ-2023-01",
        "status": 1
    },
    {
        "id": 24,
        "name": "Aqeeqah Sheep Sacrifice",
        "creator": "SAC-DEL-ASS-2023-01-002",
        "status": 1
    },
    {
        "id": 617,
        "name": "Baby Milk 4WK supply",
        "creator": "EMR-BAN-BFB-2024-07",
        "status": 1
    },
    {
        "id": 58,
        "name": "Baby Milk For 1 Week",
        "creator": "FWD-DIS-BMW-2023-01-048",
        "status": 1
    },
    {
        "id": 59,
        "name": "Baby Milk For 4 Weeks",
        "creator": "FWD-DIS-BMM-2023-01-049",
        "status": 1
    },
    {
        "id": 60,
        "name": "Baby Milk For 52 Weeks",
        "creator": "FWD-DIS-BMY-2023-01-050",
        "status": 1
    },
    {
        "id": 237,
        "name": "Baby Milk For One Week",
        "creator": "AO04-FWD-DIS-BMW-2023-01-060",
        "status": 1
    },
    {
        "id": 611,
        "name": "Baby Milk for 12 Weeks",
        "creator": "EMR-PAL-BMP-2024-003",
        "status": 1
    },
    {
        "id": 612,
        "name": "Baby Milk for 4 Weeks in Palestine",
        "creator": "EMR-PAL-BMP-2024-001",
        "status": 1
    },
    {
        "id": 610,
        "name": "Baby Milk for 8 Weeks",
        "creator": "EMR-PAL-BMP-2024-002",
        "status": 1
    },
    {
        "id": 621,
        "name": "Back To School Support For 5 Orphans",
        "creator": "EMR-BAN-BTS-2024-04",
        "status": 1
    },
    {
        "id": 196,
        "name": "Bangladesh Basic Water Well",
        "creator": "WWC-CON-BBW-2023-01-029",
        "status": 1
    },
    {
        "id": 635,
        "name": "Bangladesh Emergency Appeal",
        "creator": "EMR-BAN-ALA-2024-00",
        "status": 1
    },
    {
        "id": 639,
        "name": "Be A Guardian",
        "creator": "EMR-PAL-MASU-005",
        "status": 1
    },
    {
        "id": 638,
        "name": "Be A Helping Hand",
        "creator": "EMR-PAL-MASU-003",
        "status": 1
    },
    {
        "id": 641,
        "name": "Be A Lifesaver",
        "creator": "EMR-PAL-MASU-009",
        "status": 1
    },
    {
        "id": 640,
        "name": "Be A Protector",
        "creator": "EMR-PAL-MASU-006",
        "status": 1
    },
    {
        "id": 622,
        "name": "Bedding For 5 Orphans",
        "creator": "EMR-BAN-ORP-2024-03",
        "status": 1
    },
    {
        "id": 61,
        "name": "Bread",
        "creator": "FWD-DIS-DBD-2023-01-051",
        "status": 1
    },
    {
        "id": 325,
        "name": "Build A Classroom",
        "creator": "EDU-FND-PCR-2023-01-127",
        "status": 1
    },
    {
        "id": 320,
        "name": "Build and Repair Water Wells",
        "creator": "MKD-WEL-SDQ-2023-01",
        "status": 1
    },
    {
        "id": 633,
        "name": "Clothing For Orphans",
        "creator": "LP-PAL-OR-1-011",
        "status": 1
    },
    {
        "id": 651,
        "name": "Clothing Suppot For Orphans",
        "creator": "MA-PAL-OR-1-011",
        "status": 1
    },
    {
        "id": 701,
        "name": "Complete Emergency Family Kit",
        "creator": "EMR-LEB-24-005",
        "status": 1
    },
    {
        "id": 625,
        "name": "Complete Orphan Care Package Gaza",
        "creator": "LP-PAL-OR-1-001",
        "status": 1
    },
    {
        "id": 607,
        "name": "Contribute Towards Medical Relief",
        "creator": "MA-PAL-GL-3-004",
        "status": 1
    },
    {
        "id": 601,
        "name": "Contribute Towards Providing A Family Monthly Allowance",
        "creator": "MA-PAL-GL-3-001",
        "status": 1
    },
    {
        "id": 636,
        "name": "Daily Meals For 10 (Ten) Orphans",
        "creator": "AO-MA-PAL-OR-1-001",
        "status": 1
    },
    {
        "id": 627,
        "name": "Daily Meals For 10 Orphans",
        "creator": "LP-PAL-OR-1-003",
        "status": 1
    },
    {
        "id": 637,
        "name": "Daily Meals For 20 (Twenty) Orphans",
        "creator": "AO-MA-PAL-OR-1-002",
        "status": 1
    },
    {
        "id": 628,
        "name": "Daily Meals For 20 Orphans",
        "creator": "LP-PAL-OR-1-004",
        "status": 1
    },
    {
        "id": 626,
        "name": "Daily Meals For One Orphan",
        "creator": "LP-PAL-OR-1-002",
        "status": 1
    },
    {
        "id": 604,
        "name": "Deliver An Essential Food Pack To A Palestinian Refugee Family",
        "creator": "MA-PAL-GL-3-005",
        "status": 1
    },
    {
        "id": 596,
        "name": "Deliver Clean Drinking Water",
        "creator": "MA-PAL-GL-1-005",
        "status": 1
    },
    {
        "id": 551,
        "name": "Deliver Clean Water For 1500 People",
        "creator": "EMR-DIS-AA-2024-405",
        "status": 1
    },
    {
        "id": 552,
        "name": "Deliver Clean Water For 2500 People",
        "creator": "EMR-DIS-AA-2024-406",
        "status": 1
    },
    {
        "id": 550,
        "name": "Deliver Clean Water For 500 People",
        "creator": "EMR-DIS-AA-2024-404",
        "status": 1
    },
    {
        "id": 549,
        "name": "Deliver Clean Water In Palestine",
        "creator": "EMR-DIS-AA-2024-403",
        "status": 1
    },
    {
        "id": 595,
        "name": "Deliver Urgent Food Packs",
        "creator": "MA-PAL-GL-1-004",
        "status": 1
    },
    {
        "id": 593,
        "name": "Deliver Urgent Medical Support",
        "creator": "MA-PAL-GL-1-002",
        "status": 1
    },
    {
        "id": 741,
        "name": "Development Support",
        "creator": "ORP-CON-LEB-24-02",
        "status": 1
    },
    {
        "id": 79,
        "name": "Disability Support",
        "creator": "HLT-DIS-DSP-2023-01-066",
        "status": 1
    },
    {
        "id": 739,
        "name": "Donate 5 Qurans",
        "creator": "GD-QR103-5",
        "status": 1
    },
    {
        "id": 107,
        "name": "Education",
        "creator": "MKD-GEN-MED-2023-01-122",
        "status": 1
    },
    {
        "id": 620,
        "name": "Educational Material For 10 Students",
        "creator": "EMR-BAN-EMB-2024-08",
        "status": 1
    },
    {
        "id": 771,
        "name": "Eid Gift",
        "creator": "TXT-RM25-00-03",
        "status": 1
    },
    {
        "id": 357,
        "name": "Emergency Education Support",
        "creator": "EMR-DIS-BFPL-2023-01-205",
        "status": 1
    },
    {
        "id": 678,
        "name": "Emergency Family Shelter Kit",
        "creator": "EMR-LEB-24-004",
        "status": 1
    },
    {
        "id": 616,
        "name": "Emergency Food Pack",
        "creator": "EMR-BAN-FPB-2024-02",
        "status": 1
    },
    {
        "id": 599,
        "name": "Emergency Fund For Where Most Needed",
        "creator": "MA-PAL-GL-1-008",
        "status": 1
    },
    {
        "id": 328,
        "name": "Emergency Home Rebuild",
        "creator": "EM-SDQ-RBL-02-2023",
        "status": 1
    },
    {
        "id": 80,
        "name": "Emergency Medical Relief",
        "creator": "HLT-DIS-EMA-2023-01-067",
        "status": 1
    },
    {
        "id": 356,
        "name": "Emergency Orphan Support",
        "creator": "EMR-DIS-BFPL-23-01-204",
        "status": 1
    },
    {
        "id": 102,
        "name": "Emergency Relief",
        "creator": "EMR-GEN-MER-2023-01-117",
        "status": 1
    },
    {
        "id": 72,
        "name": "Emergency Support For Turkiye",
        "creator": "EM-TUR-EQ-01-2023",
        "status": 1
    },
    {
        "id": 713,
        "name": "Emergency Winter Appeal",
        "creator": "EMR-WNT-GEN-24-01",
        "status": 1
    },
    {
        "id": 649,
        "name": "Emotional Care For Orphans",
        "creator": "MA-PAL-OR-1-009",
        "status": 1
    },
    {
        "id": 676,
        "name": "Essential Food Pack For A Family Of 5",
        "creator": "EMR-LEB-24-002",
        "status": 1
    },
    {
        "id": 75,
        "name": "Eye Restoration For 1 Person",
        "creator": "HLT-DIS-ERI-23-01-062",
        "status": 1
    },
    {
        "id": 77,
        "name": "Eye Restoration For 10 People",
        "creator": "HLT-DIS-ERX-2023-01-064",
        "status": 1
    },
    {
        "id": 76,
        "name": "Eye Restoration For 5 People",
        "creator": "HLT-DIS-ERv-2023-01-063",
        "status": 1
    },
    {
        "id": 677,
        "name": "Family Hygiene Essentials Kit",
        "creator": "EMR-LEB-24-003",
        "status": 1
    },
    {
        "id": 762,
        "name": "Family Iftar",
        "creator": "RM25-00-004",
        "status": 1
    },
    {
        "id": 581,
        "name": "Family Sponsorship",
        "creator": "EMR-AIA-24-26",
        "status": 1
    },
    {
        "id": 399,
        "name": "Family Support For Orphans",
        "creator": "EMR-DIS-AA-2024-310",
        "status": 1
    },
    {
        "id": 269,
        "name": "Feed 10 Hungry People",
        "creator": "FWD-DIS-AR-GN-002",
        "status": 1
    },
    {
        "id": 405,
        "name": "Feed 10 Orphans In Palestine",
        "creator": "EMR-DIS-AA-2024-316",
        "status": 1
    },
    {
        "id": 592,
        "name": "Feed 10 Palestinian Orphans",
        "creator": "MA-PAL-GL-1-001",
        "status": 1
    },
    {
        "id": 788,
        "name": "Feed 100 Orphans in Gaza",
        "creator": "RM25-00-000",
        "status": 1
    },
    {
        "id": 258,
        "name": "Feed 20 Hungry People",
        "creator": "FWD-DIS-AR-GN-003",
        "status": 1
    },
    {
        "id": 406,
        "name": "Feed 20 Orphans In Palestine",
        "creator": "EMR-DIS-AA-2024-317",
        "status": 1
    },
    {
        "id": 597,
        "name": "Feed 20 Palestinian Orphans",
        "creator": "MA-PAL-GL-1-006",
        "status": 1
    },
    {
        "id": 547,
        "name": "Feed 20 People In Palestine",
        "creator": "EMR-DIS-AA-2024-401",
        "status": 1
    },
    {
        "id": 397,
        "name": "Feed 50 Hungry People",
        "creator": "FWD-DIS-AR-GN-004",
        "status": 1
    },
    {
        "id": 548,
        "name": "Feed 50 People In Palestine",
        "creator": "EMR-DIS-AA-2024-402",
        "status": 1
    },
    {
        "id": 546,
        "name": "Feed A Hungry Person In Palestine",
        "creator": "EMR-DIS-AA-2024-400",
        "status": 1
    },
    {
        "id": 404,
        "name": "Feed An Orphan In Palestine",
        "creator": "EMR-DIS-AA-2024-315",
        "status": 1
    },
    {
        "id": 760,
        "name": "Fidya",
        "creator": "RM25-00-002",
        "status": 1
    },
    {
        "id": 103,
        "name": "Food Aid",
        "creator": "MKD-GEN-MFA-2023-01-118",
        "status": 1
    },
    {
        "id": 664,
        "name": "Food Aid Fund",
        "creator": "MD-AA-2024-10",
        "status": 1
    },
    {
        "id": 785,
        "name": "Food Aid support",
        "creator": "DD-FA-GEN-24-01",
        "status": 1
    },
    {
        "id": 644,
        "name": "Food Kit For 10 Orphans",
        "creator": "MA-PAL-OR-1-003",
        "status": 1
    },
    {
        "id": 645,
        "name": "Food Kit For 20 Orphans",
        "creator": "MA-PAL-OR-1-004",
        "status": 1
    },
    {
        "id": 746,
        "name": "Food Kit for 40 Orphans",
        "creator": "MA-PAL-OR-1-004-A",
        "status": 1
    },
    {
        "id": 229,
        "name": "Food Pack For Bangladesh",
        "creator": "FWD-DIS-FPB-2023-01-047",
        "status": 1
    },
    {
        "id": 226,
        "name": "Food Pack For Middle East",
        "creator": "FWD-DIS-FPM-2023-01-044",
        "status": 1
    },
    {
        "id": 631,
        "name": "Food Pack For One Month For 10 Orphans",
        "creator": "LP-PAL-OR-1-007",
        "status": 1
    },
    {
        "id": 630,
        "name": "Food Pack For One Month For 5 Orphans",
        "creator": "LP-PAL-OR-1-006",
        "status": 1
    },
    {
        "id": 56,
        "name": "Food Pack For Pakistan",
        "creator": "FWD-DIS-FPP-2023-01-046",
        "status": 1
    },
    {
        "id": 55,
        "name": "Food Pack For Yemen",
        "creator": "FWD-DIS-FPY-2023-01-045",
        "status": 1
    },
    {
        "id": 250,
        "name": "Food Pack for Palestine",
        "creator": "EMR-DIS-PSE-2023-02",
        "status": 1
    },
    {
        "id": 763,
        "name": "Food pack for 30 days",
        "creator": "RM25-00-005",
        "status": 1
    },
    {
        "id": 169,
        "name": "Friday Weekly Recurring Donation",
        "creator": "GEN-SUB-FRI-2023-01-01",
        "status": 1
    },
    {
        "id": 656,
        "name": "Gaza Emergency Fund",
        "creator": "MD-AA-2024-02",
        "status": 1
    },
    {
        "id": 338,
        "name": "Gaza Essential Family Food Pack",
        "creator": "EMR-DIS-BFPL-23-01-102",
        "status": 1
    },
    {
        "id": 747,
        "name": "Gaza Family Shelter Kit",
        "creator": "EMR-PAL-GEN-24-11-1",
        "status": 1
    },
    {
        "id": 769,
        "name": "Gaza Iftar Meal",
        "creator": "TXT-RM25-00-01",
        "status": 1
    },
    {
        "id": 750,
        "name": "Gaza Large Family Shelter Kit",
        "creator": "EMR-PAL-GEN-24-11-4",
        "status": 1
    },
    {
        "id": 335,
        "name": "Gaza Medical Relief",
        "creator": "EMR-DIS-PSE-2023-01-01",
        "status": 1
    },
    {
        "id": 749,
        "name": "Gaza Medium Family Shelter Kit",
        "creator": "AO-EMR-PAL-GEN-24-11-3",
        "status": 1
    },
    {
        "id": 642,
        "name": "Gaza Orphan Support Kit",
        "creator": "MA-PAL-OR-1-001",
        "status": 1
    },
    {
        "id": 748,
        "name": "Gaza Small Family Shelter Kit",
        "creator": "EMR-PAL-GEN-24-11-2",
        "status": 1
    },
    {
        "id": 168,
        "name": "General Masjid Donations",
        "creator": "GD-MP015",
        "status": 1
    },
    {
        "id": 661,
        "name": "General Masjid Donations Fund",
        "creator": "MD-AA-2024-07",
        "status": 1
    },
    {
        "id": 124,
        "name": "General Orphan Support",
        "creator": "MKD-GEN-MED-23-01-122",
        "status": 1
    },
    {
        "id": 125,
        "name": "General Sustainable Donations",
        "creator": "MKD-GD-MP001",
        "status": 1
    },
    {
        "id": 394,
        "name": "Gift Of Sound For 1 Person",
        "creator": "HLT-DIS-EAR-2024-01",
        "status": 1
    },
    {
        "id": 184,
        "name": "H2 Hand Pump Water Well",
        "creator": "WWC-CON-H2W-2023-01-025",
        "status": 1
    },
    {
        "id": 321,
        "name": "HD Africa Basic Water Well",
        "creator": "WWC-CON-ABW-2023-02-028",
        "status": 1
    },
    {
        "id": 302,
        "name": "HealthGuard Orphans",
        "creator": "OPR-FUND-HL-2023-01-01",
        "status": 1
    },
    {
        "id": 623,
        "name": "Healthcare Facility For Orphans",
        "creator": "MA-PAL-OR-1-008",
        "status": 1
    },
    {
        "id": 784,
        "name": "Healthy Futures for Orphans",
        "creator": "DD-ORP-GEN-24-12",
        "status": 1
    },
    {
        "id": 602,
        "name": "Help Cover The Rent For A Palestinian Refugee Family",
        "creator": "MA-PAL-GL-3-002",
        "status": 1
    },
    {
        "id": 605,
        "name": "Help Provide Care For Families With Orphaned Children",
        "creator": "MA-PAL-GL-3-006",
        "status": 1
    },
    {
        "id": 594,
        "name": "Help Provide Shelters For Homeless Palestinian Families",
        "creator": "MA-PAL-GL-1-003",
        "status": 1
    },
    {
        "id": 402,
        "name": "Help Shelter Palestinian Orphans",
        "creator": "EMR-DIS-AA-2024-313",
        "status": 1
    },
    {
        "id": 395,
        "name": "Help Them Hear",
        "creator": "HLT-DIS-EAR-2024-01-A",
        "status": 1
    },
    {
        "id": 738,
        "name": "Home of Hope -  Empowering Contribution",
        "creator": "SPC-PRG-24-09",
        "status": 1
    },
    {
        "id": 736,
        "name": "Home of Hope - Basic Contribution",
        "creator": "SPC-PRG-24-07",
        "status": 1
    },
    {
        "id": 737,
        "name": "Home of Hope - Support Contribution",
        "creator": "SPC-PRG-24-08",
        "status": 1
    },
    {
        "id": 733,
        "name": "Hope Line Medical Support - Basic Contribution",
        "creator": "SPC-PRG-24-04",
        "status": 1
    },
    {
        "id": 735,
        "name": "Hope Line Medical Support - Major Contribution",
        "creator": "SPC-PRG-24-06",
        "status": 1
    },
    {
        "id": 734,
        "name": "Hope Line Medical Support - Sustaining Contribution",
        "creator": "SPC-PRG-24-05",
        "status": 1
    },
    {
        "id": 723,
        "name": "Hot Meals For 100 Orphans",
        "creator": "AO-EMR-WNT-GEN-24-06",
        "status": 1
    },
    {
        "id": 718,
        "name": "Hot Meals For 20 Orphan",
        "creator": "EMR-WNT-GEN-24-06",
        "status": 1
    },
    {
        "id": 742,
        "name": "Impactful Support",
        "creator": "ORP-CON-LEB-24-03",
        "status": 1
    },
    {
        "id": 31,
        "name": "Income Generation",
        "creator": "SST-GEN-IGN-2023-01-069",
        "status": 1
    },
    {
        "id": 190,
        "name": "India Basic Water Well",
        "creator": "WWC-CON-IBW-2023-01-031",
        "status": 1
    },
    {
        "id": 92,
        "name": "Indonesia Masjid",
        "creator": "MSJ-CON-IMJ-2023-01-014",
        "status": 1
    },
    {
        "id": 233,
        "name": "Interest Riba Cleanse",
        "creator": "INT-001",
        "status": 1
    },
    {
        "id": 759,
        "name": "Kaffarah",
        "creator": "RM25-00-001",
        "status": 1
    },
    {
        "id": 96,
        "name": "L1 Africa Water Well",
        "creator": "WWC-CON-L1W-2023-01-026",
        "status": 1
    },
    {
        "id": 186,
        "name": "L2 Africa Water Well",
        "creator": "WWC-CON-L2W-2023-01-027",
        "status": 1
    },
    {
        "id": 704,
        "name": "Lebanon Emergency",
        "creator": "AO-EMR-LEB-24-000",
        "status": 1
    },
    {
        "id": 674,
        "name": "Lebanon Emergency Appeal",
        "creator": "EMR-LEB-24-000",
        "status": 1
    },
    {
        "id": 702,
        "name": "Lebanon Emergency Meal for 25",
        "creator": "EMR-LEB-24-006",
        "status": 1
    },
    {
        "id": 703,
        "name": "Lebanon Emergency Meal for 50",
        "creator": "EMR-LEB-24-007",
        "status": 1
    },
    {
        "id": 743,
        "name": "Legacy Support",
        "creator": "ORP-CON-LEB-24-04",
        "status": 1
    },
    {
        "id": 482,
        "name": "Lower Middle School",
        "creator": "EMR-PA-AIA-24-01",
        "status": 1
    },
    {
        "id": 603,
        "name": "MA-PAL-GL-3-003",
        "creator": "Contribute Towards Furnishing Houses",
        "status": 1
    },
    {
        "id": 350,
        "name": "MATW Legacy Fund",
        "creator": "SSB-SDQ-GEN-002",
        "status": 1
    },
    {
        "id": 765,
        "name": "Masjid Iftar for 100 people",
        "creator": "RM25-00-007",
        "status": 1
    },
    {
        "id": 764,
        "name": "Masjid Iftar for 50 people",
        "creator": "RM25-00-006",
        "status": 1
    },
    {
        "id": 619,
        "name": "Medical Support For 20 Patients",
        "creator": "EMR-BAN-HCB-2024-05",
        "status": 1
    },
    {
        "id": 648,
        "name": "Monthly Meal Pack For 10 Orphans",
        "creator": "MA-PAL-OR-1-007",
        "status": 1
    },
    {
        "id": 647,
        "name": "Monthly Meal Pack For 5 Orphans",
        "creator": "MA-PAL-OR-1-006",
        "status": 1
    },
    {
        "id": 646,
        "name": "Monthly Nutrition Kit For Orphans",
        "creator": "MA-PAL-OR-1-005",
        "status": 1
    },
    {
        "id": 351,
        "name": "Most Needed Fund ",
        "creator": "SSB-WMN-GEN-003",
        "status": 1
    },
    {
        "id": 26,
        "name": "Nidr Sheep Sacrifice",
        "creator": "SAC-DEL-NSS-2023-01-004",
        "status": 1
    },
    {
        "id": 301,
        "name": "Nurturing Futures",
        "creator": "OPR-FUND-SUP-2023-01-01",
        "status": 1
    },
    {
        "id": 675,
        "name": "Nutritional Hot Meal Pack For A Family Of 5",
        "creator": "EMR-LEB-24-001",
        "status": 1
    },
    {
        "id": 349,
        "name": "Orphan Care Fund",
        "creator": "SSB-ORP-GEN-001",
        "status": 1
    },
    {
        "id": 296,
        "name": "Orphan Care Where Most Needed",
        "creator": "MKD-GEN-MOR-2023-01-11",
        "status": 1
    },
    {
        "id": 745,
        "name": "Orphan Center Construction Support",
        "creator": "ORP-CON-LEB-24-00",
        "status": 1
    },
    {
        "id": 303,
        "name": "Orphan Centre Lebanon",
        "creator": "OPR-FUND-LB-2023-01-01",
        "status": 1
    },
    {
        "id": 643,
        "name": "Orphan Daily Meal",
        "creator": "MA-PAL-OR-1-002",
        "status": 1
    },
    {
        "id": 3,
        "name": "Orphan Feast For 150",
        "creator": "FST-DEL-F150-23-01-010",
        "status": 1
    },
    {
        "id": 1,
        "name": "Orphan Feast For 25",
        "creator": "FST-DEL-F25-23-01-008",
        "status": 1
    },
    {
        "id": 2,
        "name": "Orphan Feast For 50",
        "creator": "FST-DEL-F50-2023-01-009",
        "status": 1
    },
    {
        "id": 629,
        "name": "Orphan Food Pack For One Month",
        "creator": "LP-PAL-OR-1-005",
        "status": 1
    },
    {
        "id": 297,
        "name": "Orphan Support",
        "creator": "OPR-GEN-SUP-2023-01-01",
        "status": 1
    },
    {
        "id": 744,
        "name": "Orphanage Construction Lebanon",
        "creator": "AO-ORP-CON-LEB-24-1",
        "status": 1
    },
    {
        "id": 15,
        "name": "Orphanage House",
        "creator": "ORP-CON-ORH-23-01-043",
        "status": 1
    },
    {
        "id": 14,
        "name": "Orphanage Maintenance",
        "creator": "ORP-DIS-OMT-2023-01-042",
        "status": 1
    },
    {
        "id": 105,
        "name": "Orphans Around The World",
        "creator": "MKD-GEN-MOR-2023-01-119",
        "status": 1
    },
    {
        "id": 660,
        "name": "Orphans Around The World Fund",
        "creator": "MD-AA-2024-06",
        "status": 1
    },
    {
        "id": 624,
        "name": "Orphans Psychological Support",
        "creator": "LP-PAL-OR-1-009",
        "status": 1
    },
    {
        "id": 195,
        "name": "Pakistan Basic Water Well",
        "creator": "WWC-CON-PBW-2023-01-030",
        "status": 1
    },
    {
        "id": 608,
        "name": "Pakistan Masjid",
        "creator": "MSJ-CON-PAK-2024-01",
        "status": 1
    },
    {
        "id": 322,
        "name": "Pakistan Solar Powered Well",
        "creator": "WWC-CON-PSPW-2023-01-027",
        "status": 1
    },
    {
        "id": 724,
        "name": "Pakistan Winter Appeal",
        "creator": "EMR-WNT-PAK-24-01",
        "status": 1
    },
    {
        "id": 719,
        "name": "Pakistan Winter Food & Water Support",
        "creator": "EMR-WNT-PAK-24-02",
        "status": 1
    },
    {
        "id": 720,
        "name": "Pakistan Winter Orphan Clothing",
        "creator": "EMR-WNT-PAK-24-03",
        "status": 1
    },
    {
        "id": 721,
        "name": "Pakistan Winter Shelter Kit",
        "creator": "EMR-WNT-PAK-24-04",
        "status": 1
    },
    {
        "id": 344,
        "name": "Palestine",
        "creator": "EMR-DIS-PSE-2024-011",
        "status": 1
    },
    {
        "id": 339,
        "name": "Palestine Emergency Medical Kit",
        "creator": "EMR-DIS-MDPL-2023-01-102",
        "status": 1
    },
    {
        "id": 408,
        "name": "Palestine Family Settlement Fund",
        "creator": "A009-PL-EMR-2023-04-002",
        "status": 1
    },
    {
        "id": 770,
        "name": "Palestine Food pack for 30 Days",
        "creator": "TXT-RM25-00-02",
        "status": 1
    },
    {
        "id": 371,
        "name": "Palestinian Refugee Egypt Settlement",
        "creator": "EMR-DIS-BFPL-2024-01-207",
        "status": 1
    },
    {
        "id": 375,
        "name": "Palestinian Refugee Settlement Support",
        "creator": "EMR-DIS-AA-2024-01-207",
        "status": 1
    },
    {
        "id": 110,
        "name": "Project Build",
        "creator": "MKD-GEN-MPB-2023-01-125",
        "status": 1
    },
    {
        "id": 109,
        "name": "Project Noor",
        "creator": "MKD-GEN-MPN-2023-01-124",
        "status": 1
    },
    {
        "id": 653,
        "name": "Prosthetic Support",
        "creator": "AO-EMR-PAL-MASU-001",
        "status": 1
    },
    {
        "id": 658,
        "name": "Prosthetic Support Fund",
        "creator": "MD-AA-2024-04",
        "status": 1
    },
    {
        "id": 618,
        "name": "Provide A Wheelchair",
        "creator": "EMR-BAN-DSB-2024-06",
        "status": 1
    },
    {
        "id": 600,
        "name": "Provide Blankets, Mattresses And Pillows For Vulnerable Families",
        "creator": "MA-PAL-GL-1-009",
        "status": 1
    },
    {
        "id": 354,
        "name": "Provide Clean Water In Palestine",
        "creator": "EMR-DIS-BFPL-2023-01-202",
        "status": 1
    },
    {
        "id": 355,
        "name": "Provide Shelter For A Family In Palestine",
        "creator": "EMR-DIS-BFPL-2023-01-203",
        "status": 1
    },
    {
        "id": 74,
        "name": "Rebuild a House In Palestine",
        "creator": "EMR-GEN-RHP-2023-01-131",
        "status": 1
    },
    {
        "id": 62,
        "name": "Rice Bags",
        "creator": "FWD-DIS-RBG-2023-01-052",
        "status": 1
    },
    {
        "id": 234,
        "name": "Rice Bags 15 Kgs",
        "creator": "AO01-FWD-DIS-RBG-2023",
        "status": 1
    },
    {
        "id": 27,
        "name": "Sadaqah Cow Sacrifice in Africa",
        "creator": "SAC-DEL-SCS-2023-01-005",
        "status": 1
    },
    {
        "id": 29,
        "name": "Sadaqah Goat Sacrifice In Bangladesh",
        "creator": "SAC-DEL-SGB-2023-01-007",
        "status": 1
    },
    {
        "id": 106,
        "name": "Sadaqah Jariyah",
        "creator": "MKD-GEN-MSD-2023-01-121",
        "status": 1
    },
    {
        "id": 659,
        "name": "Sadaqah Jariyah Fund",
        "creator": "MD-AA-2024-05",
        "status": 1
    },
    {
        "id": 23,
        "name": "Sadaqah Sheep Sacrifice",
        "creator": "SAC-DEL-SSS-2023-01-001",
        "status": 1
    },
    {
        "id": 28,
        "name": "Sadaqah Sheep Sacrifice In Yemen",
        "creator": "SAC-DEL-SSY-2023-01-006",
        "status": 1
    },
    {
        "id": 650,
        "name": "Safe Haven For Displaced Orphans",
        "creator": "MA-PAL-OR-1-010",
        "status": 1
    },
    {
        "id": 255,
        "name": "Save Lives In Gaza",
        "creator": "EMR-DIS-PSE-2023-01",
        "status": 1
    },
    {
        "id": 757,
        "name": "Save Lives In Gaza - Bread",
        "creator": "DD-EMR-PAL-24-11-07",
        "status": 1
    },
    {
        "id": 754,
        "name": "Save Lives In Gaza - Clothing",
        "creator": "DD-EMR-PAL-24-11-04",
        "status": 1
    },
    {
        "id": 752,
        "name": "Save Lives In Gaza - Food",
        "creator": "DD-EMR-PAL-24-11-02",
        "status": 1
    },
    {
        "id": 756,
        "name": "Save Lives In Gaza - Medicine",
        "creator": "DD-EMR-PAL-24-11-06",
        "status": 1
    },
    {
        "id": 755,
        "name": "Save Lives In Gaza - Shelter",
        "creator": "DD-EMR-PAL-24-11-05",
        "status": 1
    },
    {
        "id": 753,
        "name": "Save Lives in Gaza - Water",
        "creator": "DD-EMR-PAL-24-11-03",
        "status": 1
    },
    {
        "id": 751,
        "name": "Save Lives in Gaza - Where Needed",
        "creator": "DD-EMR-PAL-24-11-01",
        "status": 1
    },
    {
        "id": 740,
        "name": "Seed Support",
        "creator": "ORP-CON-LEB-24-01",
        "status": 1
    },
    {
        "id": 480,
        "name": "Senior School",
        "creator": "EMR-PA-AIA-24-03",
        "status": 1
    },
    {
        "id": 632,
        "name": "Shelter For Displaced Orphans",
        "creator": "LP-PAL-OR-1-010",
        "status": 1
    },
    {
        "id": 403,
        "name": "Shelter Pack For Palestinian Orphans",
        "creator": "EMR-DIS-AA-2024-314",
        "status": 1
    },
    {
        "id": 761,
        "name": "Single Iftar",
        "creator": "RM25-00-003",
        "status": 1
    },
    {
        "id": 287,
        "name": "Special Cases",
        "creator": "AO08-SPC-2023-01-002",
        "status": 1
    },
    {
        "id": 730,
        "name": "Special Cases Program Fund - Basic Contribution",
        "creator": "SPC-PRG-24-01",
        "status": 1
    },
    {
        "id": 732,
        "name": "Special Cases Program Fund - Major Contribution",
        "creator": "SPC-PRG-24-03",
        "status": 1
    },
    {
        "id": 731,
        "name": "Special Cases Program Fund - Support Contribution",
        "creator": "SPC-PRG-24-02",
        "status": 1
    },
    {
        "id": 699,
        "name": "Sponsor 10 Prosthetic Limbs",
        "creator": "EMR-PAL-MASU-219",
        "status": 1
    },
    {
        "id": 691,
        "name": "Sponsor 2 Prosthetic Limbs",
        "creator": "EMR-PAL-MASU-211",
        "status": 1
    },
    {
        "id": 692,
        "name": "Sponsor 3 Prosthetic Limbs",
        "creator": "EMR-PAL-MASU-212",
        "status": 1
    },
    {
        "id": 693,
        "name": "Sponsor 4 Prosthetic Limbs",
        "creator": "EMR-PAL-MASU-213",
        "status": 1
    },
    {
        "id": 694,
        "name": "Sponsor 5 Prosthetic Limbs",
        "creator": "EMR-PAL-MASU-214",
        "status": 1
    },
    {
        "id": 695,
        "name": "Sponsor 6 Prosthetic Limbs",
        "creator": "EMR-PAL-MASU-215",
        "status": 1
    },
    {
        "id": 696,
        "name": "Sponsor 7 Prosthetic Limbs",
        "creator": "EMR-PAL-MASU-216",
        "status": 1
    },
    {
        "id": 697,
        "name": "Sponsor 8 Prosthetic Limbs",
        "creator": "EMR-PAL-MASU-217",
        "status": 1
    },
    {
        "id": 698,
        "name": "Sponsor 9 Prosthetic Limbs",
        "creator": "EMR-PAL-MASU-218",
        "status": 1
    },
    {
        "id": 352,
        "name": "Sponsor A Family In Palestine For A Week",
        "creator": "EMR-DIS-BFPL-2023-01-200",
        "status": 1
    },
    {
        "id": 376,
        "name": "Sponsor A Palestinian Family",
        "creator": "EMR-DIS-AA-2024-208",
        "status": 1
    },
    {
        "id": 374,
        "name": "Sponsor A Palestinian Family Of 12 For 1 Month",
        "creator": "EMR-DIS-BFPL-2024-12-208",
        "status": 1
    },
    {
        "id": 372,
        "name": "Sponsor A Palestinian Family Of 4 For 1 Month",
        "creator": "EMR-DIS-BFPL-24-4-208",
        "status": 1
    },
    {
        "id": 373,
        "name": "Sponsor A Palestinian Family Of 8 For 1 Month",
        "creator": "EMR-DIS-BFPL-2024-8-208",
        "status": 1
    },
    {
        "id": 353,
        "name": "Sponsor A Patient",
        "creator": "EMR-DIS-BFPL-2023-01-201",
        "status": 1
    },
    {
        "id": 580,
        "name": "Student / Individual Sponsorship",
        "creator": "EMR-AIA-24-25",
        "status": 1
    },
    {
        "id": 401,
        "name": "Support 5 Families With Orphans For 1 Month",
        "creator": "EMR-DIS-AA-2024-312",
        "status": 1
    },
    {
        "id": 400,
        "name": "Support A Family With Orphans For 1 Month",
        "creator": "EMR-DIS-AA-2024-311",
        "status": 1
    },
    {
        "id": 11,
        "name": "Support An Orphan For A Month",
        "creator": "ORP-DIS-SOM-2023-01-039",
        "status": 1
    },
    {
        "id": 13,
        "name": "Support An Orphan For One Year",
        "creator": "ORP-DIS-SOY-2023-01-041",
        "status": 1
    },
    {
        "id": 12,
        "name": "Support An Orphan For Three Months",
        "creator": "ORP-DIS-SOQ-2023-01-040",
        "status": 1
    },
    {
        "id": 9,
        "name": "Support An Orphan In Bangladesh For A Month",
        "creator": "ORP-DIS-SOB-2023-01-037",
        "status": 1
    },
    {
        "id": 6,
        "name": "Support An Orphan In Jordan For A Month",
        "creator": "ORP-DIS-SOJ-2023-01-034",
        "status": 1
    },
    {
        "id": 7,
        "name": "Support An Orphan In Lebanon For A Month",
        "creator": "ORP-DIS-SOL-2023-01-035",
        "status": 1
    },
    {
        "id": 5,
        "name": "Support An Orphan In Palestine For A Month",
        "creator": "ORP-DIS-SOP-2023-01-033",
        "status": 1
    },
    {
        "id": 10,
        "name": "Support An Orphan In Togo For A Month",
        "creator": "ORP-DIS-SOT-2023-01-038",
        "status": 1
    },
    {
        "id": 4,
        "name": "Support An Orphan In Yemen For A Month",
        "creator": "ORP-DIS-SOY-2023-01-032",
        "status": 1
    },
    {
        "id": 613,
        "name": "Support One Orphan in Palestine for 1 Month",
        "creator": "EVT-EMR-PAL-2024-01",
        "status": 1
    },
    {
        "id": 614,
        "name": "Support One Orphan in Palestine for 1 Year",
        "creator": "EVT-EMR-PAL-2024-02",
        "status": 1
    },
    {
        "id": 409,
        "name": "Support Victims of Palestine",
        "creator": "A009-PL-EMR-2023-01-001",
        "status": 1
    },
    {
        "id": 324,
        "name": "Support an Orphan Centre",
        "creator": "MKD-SDQ-OAW-2023-02-119",
        "status": 1
    },
    {
        "id": 773,
        "name": "Support an Orphan for 1 Month",
        "creator": "ORP-GEN-24-01",
        "status": 1
    },
    {
        "id": 775,
        "name": "Support an Orphan for 12 Months",
        "creator": "ORP-GEN-24-03",
        "status": 1
    },
    {
        "id": 774,
        "name": "Support an Orphan for 6 Months",
        "creator": "ORP-GEN-24-02",
        "status": 1
    },
    {
        "id": 781,
        "name": "Support an orphan in Gaza camp",
        "creator": "DD-ORP-GEN-24-09",
        "status": 1
    },
    {
        "id": 782,
        "name": "Support an orphan in the Lebanon orphanage",
        "creator": "DD-ORP-GEN-24-10",
        "status": 1
    },
    {
        "id": 783,
        "name": "Support an orphan in the Togo orphanage",
        "creator": "DD-ORP-GEN-24-11",
        "status": 1
    },
    {
        "id": 780,
        "name": "Support an orphan with education",
        "creator": "DD-ORP-GEN-24-08",
        "status": 1
    },
    {
        "id": 776,
        "name": "Support an orphan with food",
        "creator": "DD-ORP-GEN-24-04",
        "status": 1
    },
    {
        "id": 778,
        "name": "Support an orphan with shelter",
        "creator": "DD-ORP-GEN-24-06",
        "status": 1
    },
    {
        "id": 779,
        "name": "Support an orphan with warm clothes",
        "creator": "DD-ORP-GEN-24-07",
        "status": 1
    },
    {
        "id": 777,
        "name": "Support an orphan with water",
        "creator": "DD-ORP-GEN-24-05",
        "status": 1
    },
    {
        "id": 252,
        "name": "Survival Pack",
        "creator": "EMR-DIS-PSE-2023-04",
        "status": 1
    },
    {
        "id": 789,
        "name": "The Story of Ali Banat",
        "creator": "mobile-app-story",
        "status": 1
    },
    {
        "id": 78,
        "name": "Treatment For CP Child",
        "creator": "HLT-DIS-CPC-2023-01-065",
        "status": 1
    },
    {
        "id": 112,
        "name": "Turkiye Emergency",
        "creator": "MKD-EM-TUR-EQ-01-2023",
        "status": 1
    },
    {
        "id": 481,
        "name": "Upper Middle School",
        "creator": "EMR-PA-AIA-24-02",
        "status": 1
    },
    {
        "id": 25,
        "name": "Walimah Sheep Sacrifice",
        "creator": "SAC-DEL-WSS-2023-01-003",
        "status": 1
    },
    {
        "id": 193,
        "name": "Water Aid",
        "creator": "MKD-GEN-MWA-2023-01-120",
        "status": 1
    },
    {
        "id": 663,
        "name": "Water Aid Fund",
        "creator": "MD-AA-2024-09",
        "status": 1
    },
    {
        "id": 63,
        "name": "Water Distribution In Lebanon",
        "creator": "FWD-DIS-WDL-2023-01-053",
        "status": 1
    },
    {
        "id": 64,
        "name": "Water Distribution In Palestine 5K Litres",
        "creator": "FWD-DIS-WPV-2023-01-054",
        "status": 1
    },
    {
        "id": 67,
        "name": "Water Distribution In Yemen 100K Litres",
        "creator": "FWD-DIS-WYH-2023-01-057",
        "status": 1
    },
    {
        "id": 65,
        "name": "Water Distribution In Yemen 10K Litres",
        "creator": "FWD-DIS-WYX-2023-01-055",
        "status": 1
    },
    {
        "id": 66,
        "name": "Water Distribution In Yemen 50K Litres",
        "creator": "FWD-DIS-WYM-2023-01-056",
        "status": 1
    },
    {
        "id": 272,
        "name": "Water For 500 People",
        "creator": "FWD-DIS-AR-GN-006",
        "status": 1
    },
    {
        "id": 786,
        "name": "Water Support",
        "creator": "DD-FA-GEN-24-02",
        "status": 1
    },
    {
        "id": 662,
        "name": "Water Well General Fund",
        "creator": "MD-AA-2024-08",
        "status": 1
    },
    {
        "id": 288,
        "name": "Water for 250",
        "creator": "AO07-FWD-DIS-FAO-2023-01-062",
        "status": 1
    },
    {
        "id": 122,
        "name": "Where Most Needed",
        "creator": "MKD-MN-001",
        "status": 1
    },
    {
        "id": 655,
        "name": "Where Most Needed Fund",
        "creator": "MD-AA-2024-01",
        "status": 1
    },
    {
        "id": 358,
        "name": "Widow Support And Shelter",
        "creator": "EMR-DIS-BFPL-2023-01-206",
        "status": 1
    },
    {
        "id": 717,
        "name": "Winter Food Pack",
        "creator": "EMR-WNT-GEN-24-05",
        "status": 1
    },
    {
        "id": 714,
        "name": "Winter Food and Water Support",
        "creator": "EMR-WNT-GEN-24-02",
        "status": 1
    },
    {
        "id": 715,
        "name": "Winter Orphan Clothing",
        "creator": "EMR-WNT-GEN-24-03",
        "status": 1
    },
    {
        "id": 722,
        "name": "Winter Orphan Clothing For 5 Orphans",
        "creator": "AO-EMR-WNT-GEN-24-03",
        "status": 1
    },
    {
        "id": 716,
        "name": "Winter Shelter Kit",
        "creator": "EMR-WNT-GEN-24-04",
        "status": 1
    },
    {
        "id": 558,
        "name": "Year 1A",
        "creator": "EMR-AIA-24-05",
        "status": 1
    },
    {
        "id": 559,
        "name": "Year 1B",
        "creator": "EMR-AIA-24-06",
        "status": 1
    },
    {
        "id": 560,
        "name": "Year 1C",
        "creator": "EMR-AIA-24-07",
        "status": 1
    },
    {
        "id": 561,
        "name": "Year 1D",
        "creator": "EMR-AIA-24-08",
        "status": 1
    },
    {
        "id": 562,
        "name": "Year 2A",
        "creator": "EMR-AIA-24-09",
        "status": 1
    },
    {
        "id": 563,
        "name": "Year 2B",
        "creator": "EMR-AIA-24-10",
        "status": 1
    },
    {
        "id": 564,
        "name": "Year 2C",
        "creator": "EMR-AIA-24-11",
        "status": 1
    },
    {
        "id": 565,
        "name": "Year 2D",
        "creator": "EMR-AIA-24-12",
        "status": 1
    },
    {
        "id": 566,
        "name": "Year 3A",
        "creator": "EMR-AIA-24-13",
        "status": 1
    },
    {
        "id": 567,
        "name": "Year 3B",
        "creator": "EMR-AIA-24-14",
        "status": 1
    },
    {
        "id": 568,
        "name": "Year 3C",
        "creator": "EMR-AIA-24-15",
        "status": 1
    },
    {
        "id": 569,
        "name": "Year 3D",
        "creator": "EMR-AIA-24-16",
        "status": 1
    },
    {
        "id": 570,
        "name": "Year 4A",
        "creator": "EMR-AIA-24-17",
        "status": 1
    },
    {
        "id": 571,
        "name": "Year 4B",
        "creator": "EMR-AIA-24-18",
        "status": 1
    },
    {
        "id": 572,
        "name": "Year 4C",
        "creator": "EMR-AIA-24-19",
        "status": 1
    },
    {
        "id": 573,
        "name": "Year 4D",
        "creator": "EMR-AIA-24-20",
        "status": 1
    },
    {
        "id": 574,
        "name": "Year 5A",
        "creator": "EMR-AIA-24-21",
        "status": 1
    },
    {
        "id": 575,
        "name": "Year 5B",
        "creator": "EMR-AIA-24-22",
        "status": 1
    },
    {
        "id": 576,
        "name": "Year 5C",
        "creator": "EMR-AIA-24-23",
        "status": 1
    },
    {
        "id": 577,
        "name": "Year 5D",
        "creator": "EMR-AIA-24-24",
        "status": 1
    },
    {
        "id": 554,
        "name": "Year Prep A",
        "creator": "EMR-AIA-24-01",
        "status": 1
    },
    {
        "id": 555,
        "name": "Year Prep B",
        "creator": "EMR-AIA-24-02",
        "status": 1
    },
    {
        "id": 556,
        "name": "Year Prep C",
        "creator": "EMR-AIA-24-03",
        "status": 1
    },
    {
        "id": 557,
        "name": "Year Prep D",
        "creator": "EMR-AIA-24-04",
        "status": 1
    },
    {
        "id": 609,
        "name": "Yemen Masjid",
        "creator": "MSJ-CON-YEM-2024-02",
        "status": 1
    },
    {
        "id": 323,
        "name": "Yemen Solar Powered Well",
        "creator": "WWC-CON-L2W-2023-01-02",
        "status": 1
    },
    {
        "id": 314,
        "name": "Yemen Water Plant",
        "creator": "SST-FND-YM-WTP-2023-01",
        "status": 1
    },
    {
        "id": 194,
        "name": "Zakat",
        "creator": "RM23-015",
        "status": 1
    },
    {
        "id": 772,
        "name": "Zakat Al Fitr",
        "creator": "TXT-RM25-00-04",
        "status": 1
    },
    {
        "id": 231,
        "name": "Zakat Al Mal",
        "creator": "RM24-ZM-013",
        "status": 1
    },
    {
        "id": 657,
        "name": "Zakat Al Mal Fund",
        "creator": "MD-AA-2024-03",
        "status": 1
    },
    {
        "id": 787,
        "name": "Zakat Calculator",
        "creator": "zakat-calculator-card",
        "status": 1
    }
]
Builder.registerComponent(CrisisWidgetV2, {
    name: 'CrisisWidgetV2',
    inputs: [
        {
            name: 'productsList',
            friendlyName: 'Product',
            type: 'list', // Defines the input as a list (array)
            defaultValue: [], // Initializes with an empty array
            minRows: 0,       // Optional: Minimum number of items
            maxRows: 300,     // Optional: Maximum number of items
            defaultItem: { productId: '', defaultProduct: false }, // Defines the structure of each item
            subFields: [
                {
                  name: 'productId',
                  friendlyName: 'Creator Code Product',
                  type: 'enum', // Change from 'string' to 'enum'
                  enum: allPrdList.map(product => product.creator), // List of product IDs
                  enumLabels: allPrdList.map(product => product.name), // Corresponding labels
                  // options: allPrdList.map(product => ({
                  //   label: product.name,    // User-friendly label
                  //   value: product.creator, // Underlying value (product ID)
                  // })),
                  // enum: getProductsList(), // List of product IDs
                  // enumLabels: getProductsList(), // Corresponding labels
                  // enum: 'https://matwproject.org/backend/index.php/api/checkout/stripe/distinct-products', // List of product IDs
                  required: true, // Makes this field mandatory
                  helpText: 'Enter the Product Creator Code.', // Provides guidance to users
                },
                // {
                //     name: 'productId',
                //     friendlyName: 'Creator Code Product',
                //     type: 'enum', // Defines the field as a dropdown
                //     async: true,  // Indicates that options are loaded asynchronously
                //     // enumOptions: async () => {
                //     enum: async () => {
                //         try {
                //             const response = await fetch('https://matwproject.org/backend/index.php/api/checkout/stripe/distinct-products');
                //             if (!response.ok) {
                //                 throw new Error(`HTTP error! status: ${response.status}`);
                //             }
                //             // const data = await response.json();
                //             function compare(a, b) {
                //                 if (a.name.trim() < b.name.trim()) {
                //                     return -1;
                //                 }
                //                 if (a.name.trim() > b.name.trim()) {
                //                     return 1;
                //                 }
                //                 return 0;
                //             }
                //             let temp = response.data.sort(compare);
                //             // return temp.filter(n => Number(n.status) === 1);
                //             // Assuming the API returns an array of products with `id` and `name`
                //             return temp.filter(n => Number(n.status) === 1).map(item => ({
                //                 label: item.name, // The name displayed in the dropdown
                //                 value: item.creator,    // The actual value stored
                //             }));
                //         } catch (error) {
                //             console.error('Failed to fetch product options:', error);
                //             return []; // Return an empty array or a fallback option
                //         }
                //     },
                //     required: true, // Makes this field mandatory
                //     helpText: 'Select the Product Creator Code.', // Provides guidance to users
                // },
                {
                    name: 'defaultProduct',
                    friendlyName: 'Default Product',
                    type: 'boolean',
                    required: false,
                },
            ],
        },
        {
            name: 'mode',
            type: 'enum', // Use 'enum' to define a dropdown with specific options
            enum: ['one-off', 'week', 'month'], // Define the available options
            defaultValue: 'one-off', // Set a default value if desired
            enumLabels: ['Single', 'Weekly', 'Monthly'],
        },
        {
            name: 'lang',
            type: 'enum', // Use 'enum' to define a dropdown with specific options
            enum: ['EN', 'AR', 'FR'], // Define the available options
            defaultValue: 'EN', // Set a default value if desired
            enumLabels: ['EN', 'AR', 'FR'],
            friendlyName: 'Language'
        },
        {
            name: 'floating',
            type: 'boolean',
            defaultValue: false,
            friendlyName: 'Floating Widget',
        },
        {
            name: 'ramadanMobileWidget',
            type: 'boolean',
            defaultValue: false,
            friendlyName: 'Ramadan Mobile',
        },
        {
            name: 'showSuggestedPrices',
            type: 'boolean',
            defaultValue: true,
            friendlyName: 'Show Suggested Prices',
        },
    ],
});
export default CrisisWidgetV2;