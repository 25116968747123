import { useEffect } from 'react';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import Friend from '../../images/ali-banat/friend.jpg';
import signImg from '../../images/others/MATW-Signature.png';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import Policy from '../../images/landings/palestine/policy100.png';
import Mission from '../../images/others/mission.jpg';
import Binocular from '../../images/others/binocular.jpg';
import OurValues from '../../images/others/our-values.jpg';
import Islam from '../../images/others/islam.jpg';
import Confidence from '../../images/others/confidence.jpg';
import Excellence from '../../images/others/execellence.jpg';
import Compassion from '../../images/others/compassion.jpg';
import Unity from '../../images/others/unity.jpg';
import Founder from '../../images/general/founder.jpg';
import OurPromise from '../../images/others/promise.jpg';
import PolicyIcon from '../../images/others/policyIcon.png';
import AnnualIcon from '../../images/others/annualIcon.png';
import FunraiseIcon from '../../images/others/funraiseIcon.png';
import { useNavigate } from 'react-router-dom';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';

const PurposePage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const lang = i18n.language;
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    // selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
  }, [selectedCurrencyStore]);
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
  }, []);
  /* -------------------------------------------------------------------------- */

  return (
    <Layout hideFooter className="bg-white " childClasses={'pb-0'}>
      {seo && (
        <Helmet>
          <title>{`${seo['aboutUs']['purpose']['title']}`}</title>
          <meta name="title" content={`${seo['aboutUs']['purpose']['title']}`} />
          <meta name="description" content={`${seo['aboutUs']['purpose']['description']}`} />
        </Helmet>
      )}
      <WidgetHome selectedCurrency={e => {}} />

      <section className="bg-white my-16">
        <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-10 flex flex-wrap sm:flex-nowrap justify-center sm:justify-start items-center">
          <div className="basis-full sm:basis-2/5 justify-center flex">
            <img src={Policy} className="w-[160px]" alt="policy" />
          </div>
          <div className="">
            <div className="text-[#253B7E] font-gotcha text-[50px] text-center sm:text-left">Notre Identité</div>
            <div className="text-[#14A2DC] text-[24px]">Nous sommes MATW</div>
            <div className="text-[#777777] text-[18px] font-brandingMedium">
              Ce qui a commencé comme un désir de laisser un héritage durable a inspiré un mouvement qui changerait la vie de millions de personnes.
              Confiants et authentiques, nous ne sommes pas une association caritative ordinaire. Poussés par notre passion et motivés par notre foi,
              nous travaillons à soutenir et servir toute l'humanité.
            </div>
          </div>
        </div>
      </section>
      <section className={`bg-[url('../src/images/ali-banat/pattern.png')] bg-size-[450px] bg-[#d1e6eb]`}>
        <div className="md:container md:mx-auto mt-5 ">
          <div className="grid grid-cols-12 px-3 md:px-4 lg:px-10 py-8 sm:py-20">
            <div className=" col-span-12 rounded-2xl bg-white p-6 ">
              <div className="grid grid-cols-12">
                <div className=" col-span-12 ">
                  <div className="flex flex-row flex-wrap">
                    <div className="py-2 flex flex-row flex-wrap">
                      <div className="basis-full lg:basis-2/5 sm:px-2">
                        <img src={Friend} alt="Ali banat" className="w-full"/>
                      </div>
                      <div className="basis-full lg:basis-3/6 px-2 text-[#777]">
                        <h2 className="text-[#14A2DC] text-2xl my-4">Un message du PDG (et ami d'Ali)</h2>
                        <p className="font-brandingMedium text-[18px] leading-5">
                          Assalamou Alaïkoum,
                          <br/>
                          <br/>
                          Alors que nous entamons notre huitième année d'activité, je ne peux m'empêcher d’être habité
                          par un sentiment de fierté et d’humilité de voir le parcours remarquable de Muslims Around The
                          World, jalonné de succès et de progression, Alhamdulillah !
                          <br/>
                          <br/>
                          Ce qui a commencé par l’histoire d’un homme atteint d’un cancer incurable s'est transformé en
                          un voyage qui a permis d’ouvrir un chapitre entièrement nouveau. En tant que leader de cette
                          incroyable organisation, je me sens incroyablement privilégié d'écrire ce message au nom de
                          notre équipe MATW.
                          <br/>
                          <br/>
                          C'est grâce au travail acharné, au soutien indéfectible de la communauté mondiale et à la
                          générosité de nos donateurs que nous entrons maintenant dans ce que nous espérons,
                          Insha'Allah, être une autre année révolutionnaire : 2025.
                          <br/>
                          <br/>
                          Nous avons commencé notre parcours avec Ali Banat le 16 janvier 2016 et depuis la création de
                          notre organisme, notre équipe s'est engagée à étendre l'héritage d'Ali et de celui de nos
                          donateurs, tout en veillant à l’apport de nos actions.
                          <br/>
                          <br/>
                          Avec chaque année qui passe, nous devenons de plus en plus engagés et déterminés à atteindre
                          autant de personnes dans le besoin que possible.
                          <br/>
                          <br/>
                          Il est important de commencer chaque année avec des intentions renouvelées et de nouveaux
                          objectifs pour le bien. Ainsi, MATW s’est fixé les objectifs suivants :
                          <br/>
                          <br/>
                        </p>
                        <ul className="list-disc font-brandingMedium text-[18px] pl-4 ">
                          <li>Maximiser l'effet des contributions de nos donateurs grâce à des développements plus
                            durables.
                          </li>
                          <li>Améliorer l'expérience de nos donateurs pour renforcer le lien de notre communauté
                            mondiale MATW.
                          </li>
                          <li>
                            Se concentrer sur un engagement communautaire plus large, tant en Australie, en France, au
                            Royaume-Uni, aux États-Unis, au Canada qu'à l'étranger dans les communautés que nous
                            servons.
                          </li>
                          <li>
                            Promouvoir l'égalité, l'équité et tout ce que nous faisons chez MATW, inspirés par nos
                            valeurs de foi, de transparence, d'excellence, d'efficacité et de durabilité.
                          </li>
                        </ul>
                        <br/>
                        <p className="font-brandingMedium text-[18px] leading-5">
                          Un grand merci – Jazaakoum Allahou Kheyrane - à notre famille de donateurs mondiale. Nous vous
                          sommes très reconnaissants, car votre engagement nous inspire et nous encourage à travailler
                          pour un monde meilleur et l'Akhirah, insha'Allah.
                          MATW a commencé avec Ali. Cela continue avec vous.
                        </p>
                        <br/>
                        <p className="font-brandingMedium text-[18px] leading-5">
                          Sincèrement <br/>
                          Mahmoud Ismail
                          <br/>
                          PDG du Projet MATW <br/>
                        </p>
                        <img src={signImg} alt="MATW" className="max-w-[200px] mt-5"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-10 sm:py-16 relative overflow-hidden">
        <div
            className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-10 flex flex-wrap md:flex-nowrap items-center">
          <div className="bg-[#14A2DC] md:bg-white basis-full md:basis-3/12 text-right pt-10 md:pt-0">
            <div
                className="text-white md:text-[#093686] text-[45px]  text-center sm:text-[50px] font-gotcha leading-[50px] mb-2 relative">
              Notre Vision et Mission
            </div>
            <div
                className="text-white md:text-[#78716C] text-center sm:text-right font-brandingBold text-[24px] sm:text-[28px] leading-8">
              Chez MATW, nous avons redéfini un nouveau type de charité.
            </div>
          </div>
          <div className="basis-full md:basis-11/12 lg:basis-11/12 relative md:static overflow-hidden ">
            <div className=" h-[135px] bg-[#14A2DC] absolute w-full"></div>
            <div
                className="flex z-10 pb-2 sm:pb-0 relative justify-center px-5 lg:px-10 gap-x-10 gap-y-6 mt-10 flex-wrap sm:flex-nowrap">
              <div
                  className="basis-full sm:basis-1/2  text-[18px] font-brandingMedium sm:border-[3px]
                            drop-shadow-[0px_4px_5px_rgba(0,0,0,0.15)] border-[#14A2DC] sm:h-[340px] bg-white rounded-2xl p-4"
              >
                <div className="flex justify-center">
                  <img src={Binocular} alt="binocular"/>
                </div>
                <h2 className="mb-3 text-[#00A3DA] font-brandingBold text-center">Notre Vision</h2>
                <p className="text-center text-[#777777] font-brandingMedium">
                  Avec une transparence sincère, un modèle solide et une marque axée sur des héritages durables, nous
                  rassemblons une communauté
                  mondiale de donateurs engagés à construire une belle Akhirah.
                </p>
              </div>
              <div
                  className="basis-full sm:basis-1/2  text-[18px] font-brandingMedium sm:border-[3px] drop-shadow-[0px_4px_5px_rgba(0,0,0,0.15)] border-[#14A2DC] h-[340px] bg-white rounded-2xl p-4">
                <div className="mt-12 sm:mt-3 flex justify-center">
                  <img src={Mission} alt="binocular"/>
                </div>
                <h2 className="mb-3 text-[#00A3DA] font-brandingBold text-center">Notre Mission</h2>
                <p className="text-center text-[#777777] font-brandingMedium">Prendre l'héritage d'Ali Banat et changer
                  le monde.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="max-w-[1440px] mx-auto">
        <div className="flex gap-x-8 flex-wrap lg:flex-nowrap">
          <div className="basis-full lg:basis-[45%]">
            <img src={OurValues} alt="our-values"/>
          </div>
          <div className="pt-8 pl-8 basis-full lg:basis-1/2 bg-white text-[#777777] font-brandingMedium">
            <div className="text-[#093686] font-gotcha text-[50px]">Nos Valeurs</div>
            <p className="text-[18px] leading-[18px]">
              MATW s'engage à la plus grande transparence et responsabilité dans nos opérations. Ainsi, nous avons mis
              en place des politiques et des
              protocoles robustes pour protéger le bien-être de nos bénéficiaires, garantissant que les populations
              vulnérables ne soient pas mal
              informées ou mises en danger de quelque manière que ce soit
            </p>
            <div className="flex items-start text-[18px] gap-x-10 mt-6">
              <div className="basis-2/12 sm:basis-1/12 lg:basis-1/12">
                <img src={Islam} alt="islam"/>
              </div>
              <div className="basis-2/3">
                <div className="text-[#14A2DC] font-brandingBold mb-4 leading-4">Islam</div>
                <div className="leading-[18px]">Nous pratiquons notre foi et servons Dieu (Allah) à travers nos actions
                  positives.
                </div>
              </div>
            </div>
            <div className="flex items-start text-[18px] gap-x-10 mt-6">
              <div className="basis-2/12 sm:basis-1/12 lg:basis-1/12">
                <img src={Compassion} alt="Compassion"/>
              </div>
              <div className="basis-2/3">
                <div className="text-[#14A2DC] font-brandingBold mb-4 leading-4">Compassion</div>
                <div className="leading-[18px]">Nous nous soucions des populations vulnérables et nous leur offrons aide et soutien. Nos actions sont plus éloquentes que nos paroles.
                </div>
              </div>
            </div>
            <div className="flex items-start text-[18px] gap-x-10 mt-6">
              <div className="basis-2/12 sm:basis-1/12 lg:basis-1/12">
                <img src={Excellence} alt="Excellence" />
              </div>
              <div className="basis-2/3">
                <div className="text-[#14A2DC] font-brandingBold mb-4 leading-4">Excellence</div>
                <div className="leading-[18px]">Nous veillons à la plus haute qualité de nos actions, nous visons l’excellence pour faire une différence dans la vie des populations brisées par les souffrances.</div>
              </div>
            </div>
            <div className="flex items-start text-[18px] gap-x-10 mt-6">
              <div className="basis-2/12 sm:basis-1/12 lg:basis-1/12">
                <img src={Confidence} alt="Confidence" /> 
              </div>
              <div className="basis-2/3">
                <div className="text-[#14A2DC] font-brandingBold mb-4 leading-4">Confiance</div>
                <div className="leading-[18px]">
                  Nous actions témoignent de notre vision claire et affirmée, nous savons ce que nous faisons et nous le faisons bien.
                </div>
              </div>
            </div>
            <div className="flex items-start text-[18px] gap-x-10 mt-6">
              <div className="basis-2/12 sm:basis-1/12 lg:basis-1/12">
                {' '}
                <img src={Unity} alt="Unity" />
              </div>
              <div className="basis-2/3">
                <div className="text-[#14A2DC] font-brandingBold mb-4 leading-4">Unité</div>
                <div className="leading-[18px]">Nous accueillons les autres et travaillons en équipe pour changer des vies.</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white my-16">
        <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap items-center">
          <div className="order-2 sm:order-1 basis-full sm:basis-1/2 sm:px-10">
            <div className="text-[#253B7E] text-[24px] font-brandingBold mb-2">Notre Fondateur</div>
            <div className="text-[#777777] text-[18px] leading-[18px] font-brandingMedium">
              Après des décennies à profiter de tout ce que la dunya avait à offrir et à devenir un homme d'affaires et un entrepreneur prospère, tout
              a changé pour Ali Banat après un diagnostic fatal. MATW a commencé comme un acte de bonté et est maintenant un mouvement mondial.
            </div>
            <div className=" text-[16px] mt-6">
              <button
                onClick={() => navigate(generateLinkWithLang('/l-histoire-d-ali-banat', lang))}
                className="bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full"
              >
                Lire la suite
              </button>
            </div>
          </div>
          <div className="order-1 sm:order-2 basis-full sm:basis-1/2">
            <img src={Founder} className="" alt="policy" />
          </div>
        </div>
      </section>
      <section className="bg-[#DFEEF2] py-16">
        <div className="md:container  md:mx-auto px-3 ">
          <div className="flex flex-wrap md:flex-nowrap gap-x-10 gap-y-10 justify-center">
            <div
              onClick={() => navigate(generateLinkWithLang('/rapports-d-activite', lang))}
              className="bg-[#14A2DC] cursor-pointer    w-[400px] flex min-h-[340px] p-4 rounded-2xl text-center text-white"
            >
              <div className="mt-10">
                <div className="h-[100px] flex justify-center">
                  <img src={AnnualIcon} alt="" />
                </div>
                <div className="font-brandingBold my-3 text-[24px]">Rapports Annuels</div>
                <div className="font-brandingMedium">
                  Nous sommes fiers de partager nos réalisations et les changements transformateurs que nous avons apportés.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PurposePage;
