import React, { useState, useEffect } from 'react';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import { BASKET_CACHE_KEY } from '../../../utils/handleLocalStorage';
import CustomButton from '../button';
import SelectCurrency from '../selectCurrency';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';

function IftarDonationWidget(props) {
  const {
    topList = [],
    bottomList = [],
    cardTitle = '',
    topCardTitle = '',
    topCardImageBlue = '',
    topCardImageWhite = '',
    topCardProduct = '',
    buttonLable = 'Donate Now',
  } = props;
  const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)) || {};
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  // const [currentCurrency, setCurrentCurrency] = useState('AUD');
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [topListState, setTopListState] = useState([]);
  const [columns, setColumns] = useState([]);
  const [focusedCardId, setFocusedCardId] = useState(null);

  const navigate = useNavigate();
  // State for total amount
  const [totalAmount, setTotalAmount] = useState(0);

  // State for quantities (for items in topList and bottomList)
  const [quantities, setQuantities] = useState(() => {
    const initialQuantities = {};
    [...topList, ...bottomList].forEach(item => {
      initialQuantities[item.id] = { quantity: 0, selected: false };
    });
    return initialQuantities;
  });

  const saveSelectedProducts = products => {
    if (products.length === 0) {
      localStorage.removeItem('selectedProducts');
    } else {
      localStorage.setItem('selectedProducts', JSON.stringify(products));
    }
  };

  const updateSelectedProducts = quantities => {
    const selectedProducts = Object.entries(quantities)
      .filter(([_, value]) => value.selected)
      .map(([key, value]) => ({
        id: key,
        quantity: value.quantity,
      }));

    saveSelectedProducts(selectedProducts);
  };

  const handleSelectCard = itemId => {
    setFocusedCardId(itemId);
  };

  const handleBlur = e => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setFocusedCardId(null);
    }
  };
  const handleAddQty = (prd, index) => {
    let tempPrd = { ...prd, quantity: 1 };
    addProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });

    setQuantities(prev => {
      const updatedQuantities = {
        ...prev,
        [prd.id]: {
          ...prev[prd.id],
          quantity: (prev[prd.id]?.quantity || 0) + 1,
          selected: true,
        },
      };

      updateSelectedProducts(updatedQuantities);
      return updatedQuantities;
    });
  };

  const handleReduceQty = (prd, index) => {
    const currentQty = quantities[prd.id]?.quantity || 0;

    if (currentQty > 1) {
      let tempPrd = { ...prd, quantity: -1 };
      editProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });

      setQuantities(prev => {
        const updatedQuantities = {
          ...prev,
          [prd.id]: {
            ...prev[prd.id],
            quantity: currentQty - 1,
            selected: true,
          },
        };

        updateSelectedProducts(updatedQuantities);
        return updatedQuantities;
      });
    } else {
      handleRemoveProduct(prd);
      setQuantities(prev => {
        const updatedQuantities = { ...prev };
        delete updatedQuantities[prd.id];

        updateSelectedProducts(updatedQuantities);
        return updatedQuantities;
      });
    }
  };

  const handleRemoveProduct = prd => {
    removeProduct(prd);
    handleSelectedGiving();
  };

  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let temp = [];

    topListState.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });

    setTopListState(temp);
  };

  const handleCheckboxChange = prd => {
    setQuantities(prevQuantities => {
      const newQuantities = { ...prevQuantities };
      const isSelected = !newQuantities[prd.id]?.selected;

      if (isSelected) {
        let tempPrd = { ...prd, quantity: 1 };
        addProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });

        newQuantities[prd.id] = {
          quantity: 1,
          selected: true,
        };
      } else {
        handleRemoveProduct(prd);
        newQuantities[prd.id] = {
          quantity: 0,
          selected: false,
        };
      }

      updateSelectedProducts(newQuantities);
      return newQuantities;
    });
  };

  // Handle currency change
  // const handleCurrency = currency => {
  //   setCurrentCurrency(currency.value);
  // };

  const handleDonateNow = () => {
    navigate('/checkout');
  };

  const currAmmount = (currencyCode, amount) => {
    return `${getSymbolFromCurrency(currencyCode.toLowerCase())}${amount}`;
  };

  useEffect(() => {
    setTopListState(topList);
  }, [topList, selectedCurrencyStore]);

  useEffect(() => {
    const half = Math.ceil(topListState.length / 2);
    setColumns([topListState.slice(0, half), topListState.slice(half)]);
  }, [topListState]);

  useEffect(() => {
    const grandTotal = basketStatesFromLocalStorage.grandTotal;
    setTotalAmount(grandTotal ?? 0);
  }, [handleCheckboxChange, handleSelectedGiving]);

  useEffect(() => {
    const local = JSON.parse(localStorage.getItem('selectedProducts')) || [];

    if (basketStatesFromLocalStorage && basketStatesFromLocalStorage.products && basketStatesFromLocalStorage.products[0].amount === 0) {
      localStorage.removeItem('selectedProducts');
      return;
    }

    setQuantities(prevQuantities => {
      const updatedQuantities = { ...prevQuantities };
      local.forEach(item => {
        const productId = item.id;
        updatedQuantities[productId] = {
          quantity: item.quantity,
          selected: true,
        };
      });

      return updatedQuantities;
    });
  }, []);

  return (
    <div className="bg-[#253B7E] flex flex-col items-center justify-center md:p-4" tabIndex={-1} onBlur={handleBlur}>
      <div className="bg-white shadow-lg p-2 md:p-4 w-full md:w-[648px] min-h-[679px] max-w-full mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-primaryDark mb-4">{cardTitle}</h2>

          {/* TopList: Package Plus single */}
          {topCardProduct.map(item => {
            const quantityValue = quantities[item.id]?.quantity || 0;
            const price = item[selectedCurrencyStore.toLowerCase()] || item.usd || 0;

            return (
              <div
                key={item.id}
                className={`border rounded-lg h-[84px] flex items-center px-1 shadow-md 
                  
                  ${focusedCardId == item.id ? 'bg-primaryMain' : 'bg-[#CDF1FF]'}`}
                onClick={e => {
                  e.stopPropagation();
                  handleSelectCard(item.id);
                }}
                tabIndex={0}
                onBlur={handleBlur}
              >
                <div className="flex items-center w-full">
                  {/* Title Section */}
                  <div className="flex flex-auto basis-[45%] ml-1">
                    <h3
                      className={`font-brandingBold text-[16px] sm:text-[16px] ${focusedCardId == item.id ? 'text-white' : 'text-primaryDark'} leading-[12px] text-left decoration-[1px]`}
                    >
                      {topCardTitle}
                    </h3>
                  </div>

                  {/* Image and Product Name Section */}
                  <div className="flex items-center flex-col basis-[30%]">
                    <img className="w-[80px] h-[37px]" src={focusedCardId === item.id ? topCardImageWhite : topCardImageBlue} alt="Orphans_Food" />
                    <p
                      className={`font-brandingBold text-[13px] ${focusedCardId == item.id ? 'text-white' : 'text-primaryDark'} leading-[12px] text-center decoration-[1px]`}
                    >
                      {item.name}
                    </p>
                  </div>

                  {/* Price Section */}
                  <div className="flex items-center justify-center basis-[30%]">
                    <p
                      className={`font-brandingBold text-[16px] ${focusedCardId == item.id ? 'text-white' : 'text-primaryDark'}
                       leading-[12px] text-center decoration-[1px]`}
                    >
                      {'= '}
                      {currAmmount(selectedCurrencyStore, price)} {selectedCurrencyStore.toUpperCase()}
                    </p>
                  </div>

                  {/* Quantity Control Section */}
                  <div className="flex items-center justify-end ml-auto">
                    {quantityValue > 0 && focusedCardId === item.id ? (
                      <div className="flex items-center">
                        <button
                          className={`${focusedCardId == item.id ? 'text-white' : 'text-pink'} text-[20px] font-bold text-lg px-2 `}
                          onClick={() => handleReduceQty(item)}
                        >
                          -
                        </button>
                        <span
                          className={`${focusedCardId == item.id ? 'text-white' : 'bg-pink border-pink text-white'} text-[12px]  border rounded-lg px-2 py-0.5`}
                        >
                          {quantityValue}
                        </span>
                        <button
                          className={`${focusedCardId == item.id ? 'text-white' : 'text-pink'} text-[20px] font-bold text-lg px-2`}
                          onClick={() => handleAddQty(item)}
                        >
                          +
                        </button>
                      </div>
                    ) : (
                      <div>
                        {focusedCardId !== item.id && quantityValue > 0 ? (
                          <span className="text-[12px] text-white bg-primaryDark border border-primaryDark rounded-lg px-2 py-1 mr-1">
                            {quantityValue}
                          </span>
                        ) : (
                          <button
                            className={` ${focusedCardId == item.id && quantityValue <1 ? 'bg-white text-primaryMain' : 'bg-pink text-white'}   rounded-lg hover:bg-primaryDark text-xs w-[25px] h-[25px] mr-1`}
                            onClick={() => handleAddQty(item)}
                          >
                            <span className="font-brandingBold text-[20px] font-semibold">+</span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}

          {/* TopList: Two-column grid */}
          <div className="grid grid-cols-2 gap-4 mt-5">
            {topListState.length === 0 ? (
              <div className="flex justify-center items-center w-[300px] h-[200px]">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#253B7E]"></div>
              </div>
            ) : (
              columns.map((column, colIndex) => (
                <div key={colIndex} className="space-y-3">
                  {column.map(item => {
                    const quantityValue = quantities[item.id]?.quantity || 0;
                    const price = item[selectedCurrencyStore.toLowerCase()] || item.usd || 0;

                    return (
                      <div
                        key={item.id}
                        className={`border rounded-lg md:rounded-lg h-[84px] sm:h-[80px] flex items-center px-1 shadow-md ${
                          focusedCardId == item.id ? 'bg-primaryMain' : ''
                        }`}
                        onClick={e => {
                          e.stopPropagation();
                          handleSelectCard(item.id);
                        }}
                        tabIndex={0}
                        onBlur={handleBlur}
                      >
                        <div className="flex items-center w-full">
                          <div className="flex flex-col ml-1">
                            <div className="flex ">
                              <h3
                                className={`font-brandingBold text-[14px] sm:text-[16px] ${
                                  focusedCardId == item.id ? 'text-white' : 'text-primaryDark'
                                } leading-[14px] text-left decoration-[1px]`}
                              >
                                {item.name}
                              </h3>
                            </div>
                            <div className="flex mt-1.5">
                              <p
                                className={`font-brandingBold text-[12px] ${focusedCardId == item.id ? 'text-white border-white' : 'text-pink border-pink '} rounded-md leading-[12px] text-center decoration-[1px] border px-1`}
                              >
                                {currAmmount(selectedCurrencyStore, price)}
                              </p>
                              <p
                                className={`font-brandingBold text-[9px] ${focusedCardId == item.id ? 'text-white' : 'text-primaryDark'} self-end ml-1 leading-[12px] text-center decoration-[1px]`}
                              >
                                {selectedCurrencyStore.toUpperCase()}
                              </p>
                            </div>
                          </div>

                          <div className="flex items-center justify-end ml-auto">
                            {/* Show increment/decrement buttons when card is focused and quantity is more than 0 */}
                            {quantityValue > 0 && focusedCardId === item.id && (
                              <div className="flex items-center">
                                <button
                                  className={`${focusedCardId == item.id ? 'text-white' : 'text-pink'} text-[20px] font-bold text-lg px-2`}
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleReduceQty(item, colIndex);
                                  }}
                                >
                                  -
                                </button>
                                <span
                                  className={`${focusedCardId == item.id ? 'text-white border border-white ' : 'text-pink'} text-[12px] rounded-lg px-2 py-0.5`}
                                >
                                  {quantityValue}
                                </span>
                                <button
                                  className={`${focusedCardId == item.id ? 'text-white' : 'text-pink'} text-[20px] font-bold text-lg px-2`}
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleAddQty(item, colIndex);
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            )}

                            {/* Show only the quantity when the card is not focused */}
                            {focusedCardId !== item.id && quantityValue > 0 && (
                              <span className="text-[12px] text-white bg-primaryDark border border-primaryDark rounded-lg px-2 py-1 mr-1">
                                {quantityValue}
                              </span>
                            )}

                            {/* Show the "+" button if quantity is 0 and card is focused */}
                            {quantityValue === 0 && (
                              <button
                                className={` ${focusedCardId == item.id ? 'bg-white text-primaryMain' : ' bg-pink text-white'} rounded-lg hover:bg-primaryDark text-xs w-[25px] h-[25px] mr-1`}
                                onClick={e => {
                                  handleAddQty(item, colIndex);
                                }}
                              >
                                <span className="font-brandingBold text-[20px] font-semibold">+</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))
            )}
          </div>

          {/* BottomList */}

          <div className="flex py-7">
            <div className="w-[75%] sm:w-[70%]">
              {bottomList &&
                bottomList.map(item => {
                  const quantityValue = quantities[item.id]?.quantity || 0;
                  const price = item[selectedCurrencyStore] || item.usd || 0;

                  return (
                    <div
                      key={item.id}
                      className={`flex w-fit md:whitespace-nowrap items-center rounded-md mt-1 ${quantityValue > 0 ? 'border border-pink' : ''}`}
                    >
                      {quantityValue === 0 && (
                        <div className="flex items-center justify-center basis-[5%]">
                          <input
                            type="checkbox"
                            className="w-3 h-3 accent-pink"
                            checked={quantities[item.id]?.selected || false}
                            onChange={() => handleCheckboxChange(item)}
                          />
                        </div>
                      )}
                      <div className="flex ml-1">
                        <h3 className="font-branding text-[12px] font-medium text-left decoration-[1px] text-textPrimary">{item.name}</h3>
                      </div>
                      <div className="flex items-center justify-center ml-1">
                        <p className="font-branding text-[12px] text-left decoration-[1px] font-bold text-pink">
                          {currAmmount(selectedCurrencyStore, price)} {selectedCurrencyStore.toUpperCase()}
                        </p>
                      </div>
                      <div className="flex items-center justify-center ml-3">
                        {quantityValue > 0 && (
                          <div className="flex items-center">
                            <button className="text-[#797979] text-[12px] font-bold text-lg px-2" onClick={() => handleReduceQty(item)}>
                              -
                            </button>
                            <span className="text-[8px] text-[#797979] border border-[#797979] rounded-md px-1 py-0.5">{quantityValue}</span>
                            <button className="text-[#797979] text-[12px] font-bold text-lg px-2" onClick={() => handleAddQty(item)}>
                              +
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="w-[25%] sm:w-[30%] flex justify-end">
              <div className="rounded-lg bg-[#F6036212] bg-opacity-30 flex flex-col items-center justify-center h-[79px] w-[112px]">
                <p className="text-textPrimary font-bold text-[12px]">Total Amount</p>
                <p className="text-pink text-sm mt-1 text-[16px] font-bold">
                  {currAmmount(selectedCurrencyStore, totalAmount)} {selectedCurrencyStore.toUpperCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="flex py-7">
            <div className="w-[30%]">
              <SelectCurrency onChangeCurrency={e => {}} classNamePrefix="home_select" />
            </div>
            <div className="w-[70%] flex justify-end">
              <CustomButton
                title={'Donate Now'}
                onClick={handleDonateNow}
                className="bg-pink w-[90%] text-white rounded-full hover:bg-primaryDark text-xs w-[30px] h-[20px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IftarDonationWidget;
