import { Builder } from '@builder.io/react';
import Policy from "../../../../../images/landings/palestine/policy100.png";
const ourPromiseSection = () => {
  const content = {
    heading: `NOTRE PROMESSE À VOUS :`,
    description: `Fondé par Ali Banat en 2016, la Muslims Around The World Project (MATW) est une organisation caritative islamique mondiale qui œuvre dans 30 pays du monde entier. Notre politique de dons à 100 % fait en sorte que chaque don atteint davantage de personnes qui en ont le plus besoin.`
  };
  return <section className={`bg-[url('../public/images/landings/crisis/palestine/pattern.png')]`}>
      <div className="max-w-[1052px] px-4 py-8 mx-auto flex justify-center">
        <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-4 md:gap-8">
          <div className="order-2">
            <div className="border-[#14A2DC] text-center sm:text-left border-b-2 mb-3 pb-1">
              <h4 className={`text-center md:text-2xl uppercase text-[#253B7E] font-brandingBold md:text-left`}>
                {content.heading}
              </h4>
            </div>
            <div className={`font-brandingBold md:text-left text-sm md:text-base text-[#253B7E] font-bold`}>{content.description}</div>
          </div>
          <div className="order-1 flex-[0_0_88px] md:flex-[0_0_117px]">
            <img src={Policy} className="w-[100px] sm:w-[130px]" alt={'policy'} />
          </div>
        </div>
      </div>
    </section>;
};
Builder.registerComponent(ourPromiseSection, {
  name: 'ourPromiseSection',
  inputs: []
});
export default ourPromiseSection;