import { createSlice } from '@reduxjs/toolkit';

const widgetProductsLocalStorageKey = 'WIDGET_PRODUCTS';

const loadWidgetProductsFromLocalStorage = () => {
  try {
    const savedData = JSON.parse(localStorage.getItem(widgetProductsLocalStorageKey)) || {};
    return {
      products: savedData.products || [],
      total: savedData.total || 0,
      cartCounter: savedData.cartCounter || [],
    };
  } catch (error) {
    console.error('Error loading widget products from localStorage:', error);
    return { products: [], total: 0, cartCounter: [] };
  }
};


const saveWidgetProductsToLocalStorage = widgetProducts => {
  try {
    localStorage.setItem(
      widgetProductsLocalStorageKey,
      JSON.stringify(widgetProducts || { products: [], total: 0, cartCounter: [] })
    );
  } catch (error) {
    console.error('Error saving widget products to localStorage:', error);
  }
};

export const widgetCartSlice = createSlice({
  name: 'widgetCart',
  initialState: {
    cartLength: [],
    selectWidgetProducts: loadWidgetProductsFromLocalStorage(),
  },
  reducers: {
    setSelectWidgetProducts(state, action) {
      const product = action.payload;
      const { creator } = product;
      const existingProductIndex = state.selectWidgetProducts.products.findIndex(item => item.creator === product.creator);
      const existingCartProductIndex = state.selectWidgetProducts.cartCounter.findIndex(item => item.creator === creator);

      if (existingProductIndex !== -1) {
        const existingProduct = state.selectWidgetProducts.products[existingProductIndex];
        state.selectWidgetProducts.products[existingProductIndex] = {
          ...existingProduct,
          ...product,
          quantity: (existingProduct.quantity || 0) + (product.quantity || 0),
        };

          state.selectWidgetProducts.cartCounter[existingCartProductIndex].counter = (existingProduct.quantity || 0) + (product.quantity || 0);
      } else {
        state.selectWidgetProducts.products.push({
          ...product,
          quantity: product.quantity || 1,
        });

        state.selectWidgetProducts.cartCounter.push({ creator, counter: product.quantity || 1 });
      }

      saveWidgetProductsToLocalStorage(state.selectWidgetProducts);
    },
    removeWidgetProduct(state, action) {
      const creator = action.payload;
      state.selectWidgetProducts.products = state.selectWidgetProducts.products.filter(product => product.creator !== creator);
      state.selectWidgetProducts.cartCounter = state.selectWidgetProducts.cartCounter.filter(item => item.creator !== creator);

      saveWidgetProductsToLocalStorage(state.selectWidgetProducts);
    },
    replaceProductQuantity(state, action) {
      const { creator, quantity, operation } = action.payload;
      const productIndex = state.selectWidgetProducts.products.findIndex(item => item.creator === creator);
      const existingCartProductIndex = state.selectWidgetProducts.cartCounter.findIndex(item => item.creator === creator);

      if (productIndex !== -1) {
        state.selectWidgetProducts.products[productIndex] = {
          ...state.selectWidgetProducts.products[productIndex],
          quantity,
        };
          state.selectWidgetProducts.cartCounter[existingCartProductIndex].counter = quantity;
      }

      saveWidgetProductsToLocalStorage(state.selectWidgetProducts);
    },
    setWidgetProductsTotal(state, action) {
      const newTotal = action.payload;
      state.selectWidgetProducts.total = newTotal;
      saveWidgetProductsToLocalStorage(state.selectWidgetProducts);
    },
    adjustProductQuantity(state, action) {
      const { creator, quantity, operation } = action.payload;
      const productIndex = state.selectWidgetProducts.products.findIndex(item => item.creator === creator);
      const existingCartProductIndex = state.selectWidgetProducts.cartCounter.findIndex(item => item.creator === creator);

      if (productIndex !== -1) {
        const currentQuantity = state.selectWidgetProducts.products[productIndex].quantity || 0;

        let newQuantity = currentQuantity;
        if (operation === 'add') {
          newQuantity += parseInt(quantity);
          state.selectWidgetProducts.cartCounter[existingCartProductIndex].counter += parseInt(quantity);
        } else if (operation === 'subtract') {
          newQuantity -= parseInt(quantity);
          state.selectWidgetProducts.cartCounter[existingCartProductIndex].counter -= parseInt(quantity);
        }

        if (newQuantity >= 0) {
          state.selectWidgetProducts.products[productIndex] = {
            ...state.selectWidgetProducts.products[productIndex],
            quantity: parseInt(newQuantity),
          };

          state.selectWidgetProducts.cartCounter[existingCartProductIndex].counter = parseInt(newQuantity);
          saveWidgetProductsToLocalStorage(state.selectWidgetProducts);
        } else {
          console.warn('Quantity cannot be negative. Operation ignored.');
        }
      } else {
        console.warn('Product not found for adjustment:', creator);
      }
    },

  },
});

export const { setSelectWidgetProducts, removeWidgetProduct, replaceProductQuantity, setWidgetProductsTotal, adjustProductQuantity } =
  widgetCartSlice.actions;

export const selectWidgetProducts = state => state.widgetCart.selectWidgetProducts;

export default widgetCartSlice.reducer;