import React from 'react'
import imageJHCO from '../../../../images/landings/palestine/image-jhco.png';
import imageAlHilal from '../../../../images/landings/palestine/image-alhilal.png';
import imageEyc from '../../../../images/landings/palestine/image-eyc.png';

const MuslimsAroundSection = ({ arabic, lang = 'en' }) => {
  return (
    <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16" dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className="flex flex-col justify-center items-center gap-5 md:gap-8">
        <p className={`text-2xl md:text-[40px] max-w[884px] text-[#253B7E] text-center ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
            Muslims Around The World (MATW): A Lifeline for Palestine
        </p>
        <h2 className={`text-[#78716C] text-center text-sm md:text-[18px] ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
          In Partnership with
        </h2>
      </div>
      <div className="flex justify-center items-center flex-col gap-3 md:gap-6">
        <img src={imageJHCO} alt="jhco logo" className="w-[301px] md:w-[585px]" />
        <div className="flex gap-5 items-center">
          <img src={imageAlHilal} alt="alhilal logo" className="w-[120px] md:w-[201px]" />
          <img src={imageEyc} alt="eyc logo" className="w-[71px] md:w-[100px]" />
        </div>
      </div>
      <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-6 md:space-y-8 ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
        <p>
            With an unwavering presence in Palestine, MATW has been a beacon of hope, delivering life-saving aid to those who need it most. In partnership with trusted organizations
            like the Jordanian Hashemite Charity Organisation (JHCO), the Egyptian Youth Council, and the Egyptian Red Crescent, we are ensuring critical relief reaches the most vulnerable:
            orphans, widows, the elderly, and displaced families.
        </p>
        <div className='flex flex-col gap-2'>
            <span className="text-xl text-[#253B7E]">
                What Your Support Has Made Possible:
            </span>
            <ul className='list-disc text-base pl-6 mt-2'>
                <li>
                    Emergency Relief: Hot meals, food packs, fuel, and life-saving medical supplies have been delivered to thousands of displaced individuals.
                </li>
                <li>
                    Hospital Aid: Your donations provided stretchers, beds, blankets, and medical kits to overwhelmed hospitals, alongside fuel and staff support to keep them running during this crisis.
                </li>
                <li>
                    Family Sponsorship: For Palestinian families seeking refuge in Egypt, we are providing relocation support, family sponsorships, and resources for a new start.
                </li>
            </ul>
        </div>
        <p>
            Every project is driven by one mission: to ensure no one is left behind
        </p>
        <div className="flex flex-col gap-2">
            <span className={`text-xl text-[#253B7E] ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
                100% Transparency, 100% Zakat Policy
            </span>
            <p>
                We promise that every single donation to the Palestine Emergency Appeal goes directly to the field.
                With our 100% donation and Zakat policies, your contribution ensures immediate, tangible relief for those in dire need.
            </p>
        </div>
        <div className="flex flex-col gap-2">
            <span className={`text-xl text-[#253B7E] ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
                Hope Begins with You
            </span>
            <p>
                This is more than charity—it’s an act of defiance against despair. Together, we can keep hope alive for Gaza. Every life is precious.
                Every moment matters. Every donation counts.
            </p>
        </div>
        <p className={`text-2xl text-[#253B7E] ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
            You give, they live. Stand with Palestine today.
        </p>
      </div>
    </div>
  )
}

export default MuslimsAroundSection;