export default function sortProductsByCurrency(products, selectedCurrency = "aud") {
    return products.sort((a, b) => {
      const hasVideoA = !!a.video_src;
      const hasVideoB = !!b.video_src;

      // Videos should always come last
      if (hasVideoA && !hasVideoB) return 1;
      if (!hasVideoA && hasVideoB) return -1;

      const valueA = parseFloat(a[selectedCurrency]) || 0;
      const valueB = parseFloat(b[selectedCurrency]) || 0;

      const isLowValueA = valueA <= 1;
      const isLowValueB = valueB <= 1;

      if (!isLowValueA && isLowValueB) return -1;
      if (isLowValueA && !isLowValueB) return 1;

      return valueA - valueB;
    });
  }