import { createSlice } from '@reduxjs/toolkit';

const sheetSlice = createSlice({
  name: 'sheet',
  initialState: {
    isVisible: false,
  },
  reducers: {
    openSheet: state => {
      state.isVisible = true;
    },
    closeSheet: state => {
      state.isVisible = false;
    },
    toggleSheet: state => {
      state.isVisible = !state.isVisible;
    },
  },
});

export const { openSheet, closeSheet, toggleSheet } = sheetSlice.actions;

export const selectSheetVisibility = state => state.sheet.isVisible;

export default sheetSlice.reducer;