import { Builder } from '@builder.io/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateLinkWithLang } from "../../../../../../utils/linkGenerator";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from "../../../../../../store/user";
import SadaqahJariyahTabContent from "../../../../../general/sadaqahJariyahTabs/tab-content";
import { selectProducts } from '../../../../../../store/products';

const sadaqahJariyahUkRamadanStyle = {
  subTitle: '!text-left !sm:text-xl !px-0 !py-0 mb-8',
  name: `text-[#253B7E] uppercase font-bold`,
  description: '!text-[14px]- leading-4',
  body: '',
  body: 'flex-col-reverse md:flex-row-reverse',
  photoFrame: 'md:flex-none md:flex-[0_0_300px]',
  photo: 'w-full md:max-w-[300px]',
  desktopButtonGroup: 'flex !justify-start gap-3 lg:flex-nowrap',
  mobileButtonGroup: '!hidden',
  button: 'w-full uppercase !mb-0',
  content: ''
};
const SadaqahJariyahTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const {
    tabs = [],
    title = 'Sadaqah Jariyah',
    description = `Sadaqah Jariyah, le don qui continue de donner, est une pratique charitable à impact éternel. Comme Ali Banat, qui a laissé un héritage de Sadaqah Jariyah à travers MATW, vous aussi pouvez faire de même en contribuant à une mosquée ou un puits d'eau à votre nom ou pour un être cher. Cet acte durable assure un changement positif dans la vie de ceux qui sont dans le besoin, plantant les graines de récompenses éternelles.`
  } = props;
  const navigate = useNavigate();
  const [activeTabSadaqah, setActiveTabSadaqah] = useState(0);
  const [tabProducts, setTabProducts] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    i18n
  } = useTranslation();
  const lang = i18n.language;
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const _allProducts = useSelector(state => selectProducts(state));
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  const getAllProducts = _allProducts => {
    setIsLoaded(true);
    let tempSelectedProducts = [];
    // Initialize tempSelectedProducts with an empty products array for each tab
    tabs.forEach(() => {
      tempSelectedProducts.push({
        products: []
      });
    });
    _allProducts.forEach(item => {
      item.sub_categories.forEach(innerItem => {
        innerItem.products.forEach(innerProduct => {
          tabs.map((tabItem, tabIndex) => {
            if (tabItem.products && tabItem.products.includes(innerProduct.creator)) {
              tempSelectedProducts[tabIndex].products.push(innerProduct);
            }
          });
        });
      });
    });
    setTabProducts(tempSelectedProducts);
  };
  useEffect(() => {
    if (tabs.length > 0) {
      _allProducts && Object.keys(_allProducts).length && getAllProducts(_allProducts);
    }
  }, [_allProducts, tabs]);
  /* -------------------------------------------------------------------------- */
  return <section className="py-6 md:py-12">
    <div className="md:container mx-auto w-full px-4 md:px-0">
      <div className="text-[#253B7E]">
        <p onClick={() => navigate(generateLinkWithLang('/sadaqah-jariyah', lang))} className="cursor-pointer w-full text-center text-[30px] lg:text-[40px] sm:text-7xl font-['Gotcha']">
          {title}
        </p>
        <p className="mt-4 md:mt-6 md:leading-7 md:text-[18px] text-center font-BrandingMediumItalic">
          {description}
        </p>
      </div>
      <div className="flex flex-wrap gap-3 items-center justify-center my-8">
        {tabProducts.map((item, index) => <button onClick={() => setActiveTabSadaqah(index)} key={`sadaqah_${index}`} className={`${index === activeTabSadaqah ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'} rounded-full p-2 w-[150px] uppercase text-[14px] font-['Montserrat'] font-bold`}>
          {tabs[index] ? tabs[index].title : ''}
        </button>)}
      </div>
      {tabProducts.length > 0 && tabProducts.map((item, index) => {
        return index === activeTabSadaqah && <div key={`sadaqah_pr_${index}`} index={index} className="py-5 px-4 md:px-8 bg-[#14A2DC] rounded-lg">
          <SadaqahJariyahTabContent products={item.products || []} subTitle={tabs[index] ? tabs[index].subTitle : ''} appealsLink={tabs[index] ? tabs[index].appealsLink : ''} currency={selectedCurrencyStore} customStyle={sadaqahJariyahUkRamadanStyle} anyAmount isLoaded={isLoaded} />
        </div>;
      })}
    </div>
  </section>;
};
// export default SadaqahJariyahTabs;
Builder.registerComponent(SadaqahJariyahTabs, {
  name: 'SadaqahJariyahTabs',
  inputs: [
    {
      name: 'tabs',
      type: 'list', // Defines the input as a list (array)
      defaultValue: [], // Initializes with an empty array
      minRows: 0,       // Optional: Minimum number of items
      maxRows: 6,     // Optional: Maximum number of items
      defaultItem: { title: '', subTitle: '', appealsLink: '', products: [] }, // Defines the structure of each item
      subFields: [
        {
          name: 'title',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Tab Title.', // Provides guidance to users
        },
        {
          name: 'subTitle',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the SubTitle.', // Provides guidance to users
        },
        {
          name: 'appealsLink',
          type: 'string', // Each item is a string
          required: false, // Makes this field mandatory
          helpText: 'Enter the Appeals Link.', // Provides guidance to users
        },
        {
          name: 'products',
          type: 'string', // Each item is a string
          required: true, // Makes this field mandatory
          helpText: 'Enter the Products.', // Provides guidance to users
        },
      ],
    },
    {
      name: "title",
      type: "string",
      defaultValue: `Sadaqah Jariyah`,
      friendlyName: 'Title'
    }, {
      name: "description",
      type: "string",
      defaultValue: `Sadaqah Jariyah, le don qui continue de donner, est une pratique charitable à impact éternel. Comme Ali Banat, qui a laissé un héritage de Sadaqah Jariyah à travers MATW, vous aussi pouvez faire de même en contribuant à une mosquée ou un puits d'eau à votre nom ou pour un être cher. Cet acte durable assure un changement positif dans la vie de ceux qui sont dans le besoin, plantant les graines de récompenses éternelles.`,
      friendlyName: 'Description'
    }]
});
export default SadaqahJariyahTabs;