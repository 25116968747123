import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(
        JSON.stringify(value)
    )};expires=${expires.toUTCString()};path=/`;
};

const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
        campaign_source: params.get('campaign_source'),
        campaign_medium: params.get('campaign_medium'),
        campaign_name: params.get('campaign_name'),
    };
};

const CampaignTracker = () => {
    const location = useLocation();

    useEffect(() => {
        const { campaign_source, campaign_medium, campaign_name } =
            getQueryParams(location.search);

        if (campaign_source || campaign_medium || campaign_name) {
            const campaignData = {
                campaign_source,
                campaign_medium,
                campaign_name,
            };
            setCookie('campaign_data', campaignData, 5);

            // Prepare data for POST request
            const data = [
                {
                    campaignName: campaign_name || '',
                    campaignSource: campaign_source || '',
                    campaignMedium: campaign_medium || '',
                },
            ];

            // Send POST request
            fetch(
                'https://matw.finbix.io/254fd2a6-5977-40be-8fad-95b5edfa0c98/api/campaign/logs',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.text(); // Use text() instead of json()
                })
                .then((responseText) => {
                    console.log('Success:', responseText);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [location.search]);

    return null;
};

export default CampaignTracker;