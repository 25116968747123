import { v4 as uuidv4 } from 'uuid';
import staticImages from '../constants/staticImages';

export const donateCardsData = [
  {
    id: uuidv4(),
    mainPhoto: staticImages.imageRewards,
    title: 'rewards',
    description: 'By donating today, you can earn amazing rewards for supporting the oppressed and needy who urgently require help.',
  },
  {
    id: uuidv4(),
    mainPhoto: staticImages.imageRelief,
    title: 'relief',
    description: 'Palestinians are in urgent need of this support. Your generosity can relieve their distress and save lives.',
  },
  {
    id: uuidv4(),
    mainPhoto: staticImages.imageDuas,
    title: 'duas',
    description: 'By supporting the needy and destitute, they will be so grateful and will remember you in their duas every time they receive aid.',
  },
];

