// dates.js

import dayjs from 'dayjs'; // Ensure Day.js is installed

const STORAGE_KEY = 'campaignDates';

// Initialize campaigns from localStorage or use default
let campaigns = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [
  {
    name: 'palestine',
    startDate: '2024-09-05',
    endDate: '2025-01-25',
    lastExtended: null,
  },
  {
    name: 'egyptian',
    startDate: '2024-09-05',
    endDate: '2025-01-25',
    lastExtended: null,
  },
  {
    name: 'north-lebanon-orphan',
    startDate: '2024-09-05',
    endDate: '2025-01-27',
    lastExtended: null,
  },
  {
    name: 'gaza-camp',
    startDate: '2024-09-10',
    endDate: '2025-02-05',
    lastExtended: null,
  },
  {
    name: 'limbs-of-hope',
    startDate: '2024-09-15',
    endDate: '2025-02-15',
    lastExtended: null,
  },
  {
    name: 'water',
    startDate: '2024-09-05',
    endDate: '2025-01-27',
    lastExtended: null,
  },
  {
    name: 'food',
    startDate: '2024-09-05',
    endDate: '2025-01-27',
    lastExtended: null,
  },
  {
    name: 'orphan',
    startDate: '2024-09-05',
    endDate: '2025-01-27',
    lastExtended: null,
  },
];

// Getter to retrieve campaigns
export const getCampaignDates = () => campaigns;

// Setter to update a specific campaign's end date and lastExtended
export const updateCampaignEndDate = (campaignName, newEndDate) => {
  const today = dayjs().format('YYYY-MM-DD');
  campaigns = campaigns.map((campaign) =>
    campaign.name === campaignName
      ? { ...campaign, endDate: newEndDate, lastExtended: today }
      : campaign
  );
  // Persist the updated campaigns to localStorage
  localStorage.setItem(STORAGE_KEY, JSON.stringify(campaigns));
};
