import React, { useState, useEffect } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { Tooltip } from 'react-tooltip';

const PersistentTimer = () => {
  const TIMER_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = getTimeRemaining();
      setTimeRemaining(remaining);

      if (remaining.total <= 0) {
        resetTimer();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function getTimeRemaining() {
    const storedEndTime = localStorage.getItem('timerEndTime');
    const now = new Date().getTime();

    if (storedEndTime) {
      const endTime = parseInt(storedEndTime, 10);
      const diff = endTime - now;

      if (diff > 0) {
        return {
          total: diff,
          minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((diff % (1000 * 60)) / 1000),
        };
      }
    }

    resetTimer();
    return getTimeRemaining();
  }

  function resetTimer() {
    const newEndTime = new Date().getTime() + TIMER_DURATION;
    localStorage.setItem('timerEndTime', newEndTime);
  }

  return (
    <div className="font-inter font-bold text-sm text-white">
      <span className="flex items-center">
        <span id="clickable">
          <IoMdInformationCircle className="text-xl hidden sm:block" />
        </span>
        <Tooltip anchorSelect="#clickable" clickable className="max-w-xs z-[1200]">
        Nous livrons de la nourriture, de l'eau et des produits essentiels quotidiennement, chaque minute compte. 100 % de votre don va à ceux qui sont le plus dans le besoin. S'il vous plaît, ne partez pas sans faire même un petit don. Chaque montant compte. Vous êtes leur bouée de sauvetage.
        </Tooltip>
        <span className="min-w-[160px] ml-0.5">
          {/* {' '}Donate in <span className='min-w-[65px] text-center mx-0.5'>{timeRemaining.minutes}m {timeRemaining.seconds}s</span> to help Gaza */}
          <IoMdInformationCircle className="text-xl align-middle inline sm:hidden" id="clickable" /> Faire un don dans {timeRemaining.minutes}m{' '}
          {timeRemaining.seconds}s pour aider Gaza
        </span>
      </span>
    </div>
  );
};

export default PersistentTimer;