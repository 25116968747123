import React, { useState, useEffect } from 'react';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import { BASKET_CACHE_KEY } from '../../../utils/handleLocalStorage';
import CustomButton from '../button';
import SelectCurrency from '../selectCurrency';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';

function IftarDonationWidget(props) {
  const {topList = [], bottomList = [], cardTitle = '', buttonLable = 'Donate Now'} = props;
  const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY)) || {};
  const [addProduct, editProduct, removeProduct] = useHandleAddProduct();
  // const [currentCurrency, setCurrentCurrency] = useState('AUD');
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [topListState, setTopListState] = useState([]);
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();
  // State for total amount
  const [totalAmount, setTotalAmount] = useState(0);

  // State for quantities (for items in topList and bottomList)
  const [quantities, setQuantities] = useState(() => {
    const initialQuantities = {};
    [...topList, ...bottomList].forEach(item => {
      initialQuantities[item.id] = { quantity: 0, selected: false };
    });
    return initialQuantities;
  });

  const saveSelectedProducts = products => {
    if (products.length === 0) {
      localStorage.removeItem('selectedProducts');
    } else {
      localStorage.setItem('selectedProducts', JSON.stringify(products));
    }
  };

  const updateSelectedProducts = quantities => {
    const selectedProducts = Object.entries(quantities)
      .filter(([_, value]) => value.selected)
      .map(([key, value]) => ({
        id: key,
        quantity: value.quantity,
      }));

    saveSelectedProducts(selectedProducts);
  };

  const handleAddQty = (prd, index) => {
    let tempPrd = { ...prd, quantity: 1 };
    addProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });

    setQuantities(prev => {
      const updatedQuantities = {
        ...prev,
        [prd.id]: {
          ...prev[prd.id],
          quantity: (prev[prd.id]?.quantity || 0) + 1,
          selected: true,
        },
      };

      updateSelectedProducts(updatedQuantities);
      return updatedQuantities;
    });
  };

  const handleReduceQty = (prd, index) => {
    const currentQty = quantities[prd.id]?.quantity || 0;

    if (currentQty > 1) {
      let tempPrd = { ...prd, quantity: -1 };
      editProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });

      setQuantities(prev => {
        const updatedQuantities = {
          ...prev,
          [prd.id]: {
            ...prev[prd.id],
            quantity: currentQty - 1,
            selected: true,
          },
        };

        updateSelectedProducts(updatedQuantities);
        return updatedQuantities;
      });
    } else {
      handleRemoveProduct(prd);
      setQuantities(prev => {
        const updatedQuantities = { ...prev };
        delete updatedQuantities[prd.id];

        updateSelectedProducts(updatedQuantities);
        return updatedQuantities;
      });
    }
  };

  const handleRemoveProduct = prd => {
    removeProduct(prd);
    handleSelectedGiving();
  };

  const handleSelectedGiving = () => {
    let basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let temp = [];

    topListState.map((item, index) => {
      basketStatesFromLocalStorage &&
        basketStatesFromLocalStorage.products.map(innerItem => {
          if (innerItem.product && innerItem.product.creator === item.creator) {
            item = Object.assign({}, item, {
              isAdded: true,
              quantity: innerItem.quantity,
            });
          }
          return innerItem;
        });
      temp[index] = item;
      return item;
    });

    setTopListState(temp);
  };

  const handleCheckboxChange = (prd) => {
    setQuantities(prevQuantities => {
      const newQuantities = { ...prevQuantities };
      const isSelected = !newQuantities[prd.id]?.selected;

      if (isSelected) {
        let tempPrd = { ...prd, quantity: 1 };
        addProduct({ product: tempPrd, currency: selectedCurrencyStore, justAddToLocalStorage: true });

        newQuantities[prd.id] = {
          quantity: 1,
          selected: true,
        };
      } else {
        handleRemoveProduct(prd);
        newQuantities[prd.id] = {
          quantity: 0,
          selected: false,
        };
      }

      updateSelectedProducts(newQuantities);
      return newQuantities;
    });
  };

  // Handle currency change
  // const handleCurrency = currency => {
  //   setCurrentCurrency(currency.value);
  // };

  const handleDonateNow = () => {
    navigate('/checkout');
  };

  const currAmmount = (currencyCode, amount) => {
    return `${getSymbolFromCurrency(currencyCode.toLowerCase())}${amount}`;
  };

  useEffect(() => {
    setTopListState(topList);
  }, [topList, selectedCurrencyStore]);

  useEffect(() => {
    const half = Math.ceil(topListState.length / 2);
    setColumns([topListState.slice(0, half), topListState.slice(half)]);
  }, [topListState]);

  useEffect(() => {
    setTotalAmount(basketStatesFromLocalStorage.grandTotal);
  }, [handleCheckboxChange, handleSelectedGiving]);

  useEffect(() => {
    const local = JSON.parse(localStorage.getItem('selectedProducts')) || [];

    if (basketStatesFromLocalStorage && basketStatesFromLocalStorage.products && basketStatesFromLocalStorage.products[0].amount === 0) {
      localStorage.removeItem('selectedProducts');
      return;
    }

    setQuantities(prevQuantities => {
      const updatedQuantities = { ...prevQuantities };
      local.forEach(item => {
        const productId = item.id;
        updatedQuantities[productId] = {
          quantity: item.quantity,
          selected: true,
        };
      });

      return updatedQuantities;
    });
  }, []);

  return (
    <div className="bg-[#253B7E] flex flex-col items-center justify-center p-0 md:p-4">
      <div className="bg-white shadow-lg p-1 md:p-4 w-full md:w-[480px] min-h-[449px] max-w-full mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-primaryDark mb-4">{cardTitle}</h2>

          {/* TopList: Two-column grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {topListState.length === 0 ? (
              <div className="flex justify-center items-center w-[300px] h-[200px]">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#253B7E]"></div>
            </div>
            ) : (
              columns.map((column, colIndex) => (
                <div key={colIndex} className="space-y-3">
                  {column.map(item => {
                    const quantityValue = quantities[item.id]?.quantity || 0;
                    const price = item[selectedCurrencyStore.toLowerCase()] || item.usd || 0;

                    return (
                      <div
                        key={item.id}
                        className={`border rounded-lg md:rounded-full h-[45px] sm:h-[30px] flex items-center px-1 shadow-md ${
                          quantityValue > 0 ? 'bg-primaryMain' : ''
                        }`}
                      >
                        <div className="flex items-center w-full">
                          <div className="flex items-center justify-center basis-[10%]">
                            <input
                              type="checkbox"
                              className="w-3 h-3 border rounded-sm appearance-none
                              checked:border-white checked:bg-primaryMain
                              checked:after:content-['✓']
                              checked:after:text-white checked:after:flex
                              checked:after:justify-center checked:after:items-center
                              checked:after:absolute checked:after:inset-0
                              checked:after:text-[0.65rem]
                              border-black bg-white relative"
                              checked={quantities[item.id]?.selected || false}
                              onChange={() => handleCheckboxChange(item)}
                            />
                          </div>

                          <div className="flex flex-auto basis-[45%] ml-1">
                            <h3
                              className={`font-brandingBold text-[12px] sm:text-[10px] ${
                                quantityValue > 0 ? 'text-white' : 'text-primaryDark'
                              } leading-[12px] text-left decoration-[1px]`}
                            >
                              {item.name}
                            </h3>
                          </div>
                          <div className="flex items-center justify-center basis-[30%]">
                            <p
                              className={`font-brandingBold text-[10px] ${
                                quantityValue > 0 ? 'text-white' : 'text-primaryDark'
                              } leading-[12px] text-center decoration-[1px]`}
                            >
                              {currAmmount(selectedCurrencyStore, price)} {selectedCurrencyStore.toUpperCase()}
                            </p>
                          </div>
                          <div className="flex items-center justify-center basis-[15%]">
                            {quantityValue > 0 && (
                              <div className="flex items-center">
                                <button
                                  className="text-white text-[12px] font-bold text-lg px-2"
                                  onClick={() => handleReduceQty(item, colIndex)}
                                >
                                  -
                                </button>
                                <span className="text-[8px] text-white border border-white rounded-full px-1 py-0.5">{quantityValue}</span>
                                <button
                                  className="text-white text-[12px] font-bold text-lg px-2"
                                  onClick={() => handleAddQty(item, colIndex)}
                                >
                                  +
                                </button>
                              </div>
                            )}
                            {quantityValue === 0 && (
                              <button
                                className="bg-pink text-white rounded-full hover:bg-primaryDark text-xs w-[30px] h-[20px]"
                                onClick={() => handleAddQty(item, colIndex)}
                              >
                                <span className="font-brandingBold text-[8px] font-semibold">Ajouter</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))
            )}
          </div>

          {/* BottomList */}

          <div className="flex py-7">
            <div className="w-[75%] sm:w-[70%]">

                {bottomList && bottomList.map(item => {
                  const price = item[selectedCurrencyStore] || item.usd || 0;

                  return (
                    <div key={item.id} className="flex items-center">
                      <div className="flex items-center justify-center basis-[5%]">
                        <input
                          type="checkbox"
                          className="w-3 h-3 accent-pink"
                          checked={quantities[item.id]?.selected || false}
                          onChange={() => handleCheckboxChange(item)}
                        />
                      </div>
                      <div className="flex ml-1">
                        <h3 className="font-branding text-[12px] font-medium text-left decoration-[1px] text-textPrimary">{item.name}</h3>
                      </div>
                      <div className="flex items-center justify-center ml-1">
                        <p className="font-branding text-[12px] text-left decoration-[1px] font-bold text-pink">
                          {currAmmount(selectedCurrencyStore, price)} {selectedCurrencyStore.toUpperCase()}
                        </p>
                      </div>
                    </div>
                  );
                  })}

            </div>
            <div className="w-[25%] sm:w-[30%] flex justify-end">
              <div className="rounded-lg bg-[#F6036212] bg-opacity-30 flex flex-col items-center justify-center h-[79px] w-[112px] mt-4">
                <p className="text-textPrimary font-bold text-[12px]">Montant total</p>
                <p className="text-pink text-sm mt-1 text-[16px] font-bold">
                  {currAmmount(selectedCurrencyStore, totalAmount)} {selectedCurrencyStore.toUpperCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="flex py-7">
            <div className="w-[30%]">
              <SelectCurrency onChangeCurrency={e => {}} classNamePrefix="home_select" />
            </div>
            <div className="w-[70%] flex justify-end">
              <CustomButton
                title={
                   'Faire un don maintenant'
                }
                onClick={handleDonateNow}
                className="bg-pink w-[90%] text-white rounded-full hover:bg-primaryDark text-xs w-[30px] h-[20px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IftarDonationWidget;
